import React, { useEffect, useState } from "react";
import gospelLogo from "../../assets/gospel_logo.png";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import { Tab } from "@mui/base/Tab";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearSearchData } from "../../redux/slices/discoverSlice";
import { useUserback } from "@userback/react";

interface HeaderProps {
  isTabBarVisible?: boolean;
}

const tabs = [
  { key: 1, name: "Dashboard", path: "/dashboard" },
  { key: 2, name: "Charts", path: "/charts" },
  { key: 3, name: "Discover", path: "/discover" },
  { key: 4, name: "Compare", path: "/compare" },
  { key: 5, name: "Search", path: "/search" },
  { key: 6, name: "Account", path: "/account" },
  { key: 7, name: "Help", path: "/help" },
];

const accountOptions = [
  { key: 1, name: "Settings", path: "settings" },
  { key: 2, name: "Watchlists", path: "watchlist" },
  { key: 3, name: "Reports", path: "reports" },
  { key: 4, name: "Logout", path: "logout" },
];

function Header({ isTabBarVisible = true }: HeaderProps) {
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const userback = useUserback();
  const handleMouseEnter = () => {
    setIsAccountMenuOpen(true);
  };

  const handleHelpClick = () => {
    if (userback) {
      setTimeout(() => {
        userback.open("feature_request", "form");
      }, 500);
    }
  };

  const handleMouseLeave = () => {
    setIsAccountMenuOpen(false);
  };
  return (
    <div className="fixed top-0 left-0 right-0 bg-black z-[10000]">
      <div className="mx-auto flex justify-end px-8 h-[70px] md:h-[70px] items-center">
        <div className="flex items-center mr-auto">
          <a href="/dashboard">
            <img
              src={gospelLogo}
              alt="gospel-logo"
              className="h-[35px] w-auto md:h-[35px] sm:h-[25px] transition-opacity duration-500 ease transform hover:opacity-60"
            />
          </a>
        </div>
        {isTabBarVisible && (
          <div className="hidden md:flex space-x-4">
            <Tabs defaultValue={1}>
              <TabsList className="flex space-x-10">
                {tabs.map((item) =>
                  item.name === "Account" ? (
                    <div key={item.key} className="relative">
                      <button onMouseEnter={() => handleMouseEnter()}>
                        <Tab
                          value={item.key}
                          className="text-gospel-purple uppercase text-sm hover:text-white"
                        >
                          <a href={`/account/settings`}>{item.name}</a>
                        </Tab>
                      </button>
                      {isAccountMenuOpen && (
                        <div
                          className={`absolute right-0  mt-5 bg-black max-w-[250px] text-right transition-opacity duration-500 ease ${
                            isAccountMenuOpen
                              ? "opacity-100 right-0"
                              : "opacity-0 right-[9999px]"
                          }`}
                        >
                          <button
                            onMouseEnter={() => handleMouseEnter()}
                            onMouseLeave={() => handleMouseLeave()}
                          >
                            {accountOptions.map((option) => (
                              <div
                                key={option.key}
                                className="text-gospel-purple uppercase text-sm hover:text-white px-4 py-2 cursor-pointer"
                              >
                                <a href={`/account/${option.path}`}>
                                  {option.name}
                                </a>
                              </div>
                            ))}
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div key={item.key}>
                      {item.name === "Help" ? (
                        <button
                          onClick={() => {
                            handleHelpClick();
                          }}
                        >
                          <Tab
                            value={item.key}
                            className="text-gospel-purple uppercase text-sm hover:text-white"
                          >
                            {item.name}
                          </Tab>
                        </button>
                      ) : (
                        <a
                          href={item.path}
                          onClick={() => dispatch(clearSearchData())}
                        >
                          <Tab
                            value={item.key}
                            className="text-gospel-purple uppercase text-sm hover:text-white"
                          >
                            {item.name}
                          </Tab>
                        </a>
                      )}
                    </div>
                  )
                )}
              </TabsList>
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import GospelButton from "../../common/GospelButton/GospelButton";
import GospelInput from "../../common/GospelInput/GospelInput";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import { get } from "../../utility/api";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const images = [
  "https://img.gospelstats.com/static/home_image.jpg",
  "https://img.gospelstats.com/static/home_image2.jpg",
  "https://img.gospelstats.com/static/home_image3.jpg",
  "https://img.gospelstats.com/static/home_image4.jpg",
  "https://img.gospelstats.com/static/home_image5.jpg",
  "https://img.gospelstats.com/static/home_image6.jpg",
  "https://img.gospelstats.com/static/home_image7.jpg",
];

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [forgotSuccess, setForgotSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [backgroundImage, setBackgroundImage] = useState("");

  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleForgotPassword = async () => {
    if (!validateEmail(email)) {
      setError("Please enter valid email");
      return;
    }
    setError("");
    try {
      setLoading(true);
      const response: any = await get(`/gospel/user/forgotpw?email=${email}`);
      if (response) {
        setLoading(false);
        setForgotSuccess(true);
        setEmail("");
      } else {
        setError("Login Failed");
        setLoading(false);
        setEmail("");
      }
    } catch (error) {
      setError("Login Failed");
      setLoading(false);
      setEmail("");
    }
  };

  useEffect(() => {
    // Select a random image on component mount
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setBackgroundImage(randomImage);
  }, []);

  return (
    <>
      <Header isTabBarVisible={false} />
      <div
        className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="w-[calc(100%-40px)] max-w-[350px] text-center z-2">
          <div className="absolute inset-0 bg-black opacity-65"></div>
          <div className="relative z-10 w-full max-w-md text-center bg-white p-6 rounded-lg shadow-lg">
            <img
              src="https://img.gospelstats.com/static/icon_large.png"
              alt="Logo"
              className="w-24 mx-auto -mt-16 mb-4"
            />
            <GospelInput
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="block w-full my-6 max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />

            {error && (
              <div className="text-gospel-error-red mb-6 text-sm bg-gospel-red-100 px-3 py-2 rounded">
                {error}
              </div>
            )}
            {!forgotSuccess ? (
              <GospelButton
                className="self-center text-white h-[40px] w-full p-2 rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple"
                label={
                  loading ? (
                    <CircularProgress
                      className="self-center justify-center text-gospel-gray-100 font-bold"
                      size={16}
                    />
                  ) : (
                    "Reset Password"
                  )
                }
                onClick={handleForgotPassword}
                disabled={!email}
              />
            ) : (
              <>
                <div className="text-gospel-green text-sm bg-gospel-success-green px-6 my-2 py-4 rounded w-full text-center">
                  {
                    "You will receive an email shortly with instructions to reset your password."
                  }
                </div>
                <GospelButton
                  className="self-center my-2 text-white h-[40px] w-full p-2 rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple"
                  label={"Go Back to Login"}
                  onClick={() => navigate("/dashboard")}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer isPolicyVisible={false} />
    </>
  );
};

export default ForgotPassword;

import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import { get, post, postWithData } from "../../utility/api";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import GospelButton from "../../common/GospelButton/GospelButton";
import { useParams } from "react-router-dom";
import {
  BrandVideoInfo,
  ChannelData,
  ChannelDemographics,
  PageInfo,
  SimilarChannels,
  TrendingVideoInfo,
  TrendingVideoKey,
} from "../../types/channel";
import {
  ArrowDropDown,
  ArrowDropUp,
  Cancel,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PlayArrow,
  RemoveRedEyeSharp,
} from "@mui/icons-material";
import moment from "moment";
import {
  Avatar,
  Collapse,
  CollapseProps,
  ConfigProvider,
  DatePicker,
  Modal,
  Tooltip,
} from "antd";
import { Tabs } from "antd";
import dayjs, { Dayjs } from "dayjs";
import Highcharts, { SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import { CircularProgress } from "@mui/material";
import {
  Platform,
  determineLabelRotation,
  getPlatform,
  platformIcons,
  quickDateRanges,
} from "../../common/utils";
import {
  channelChartTabs,
  generateCommentsData,
  generateSubscriberData,
  generateUploadsData,
  generateViewData,
  demographicData,
  VideoData,
} from "./ChannelUtils";
import ReactPlayer from "react-player";
import { formatNumberWithCommas } from "../../utility/common";
import GospelInput from "../../common/GospelInput/GospelInput";
import { WatchlistItem } from "../../types/watchlist";
import "./Channel.css";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import OfflineExporting from "highcharts/modules/offline-exporting";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import axios from "axios";
import { useUserback } from "@userback/react";

const htmlUnescape = (text: string) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = text;
  return textarea.value;
};

Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);

HighchartsMore(Highcharts);

const { RangePicker } = DatePicker;

const TrendingVideoTable = ({
  data,
  showTrendingVideos,
}: {
  data: TrendingVideoInfo[];
  showTrendingVideos: boolean;
}) => {
  const [isYoutubeOpen, setIsYoutubeOpen] = useState<boolean>(false);

  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
  

  const openModal = (videoId: string) => {
    setSelectedVideoId(videoId);
    setIsYoutubeOpen(true);
  };

  const closeModal = () => {
    setIsYoutubeOpen(false);
    setSelectedVideoId(null);
  };

  return (
    <table className="divide-y divide-gray-200 w-full mx-8">
      <thead>
        <tr className="text-center text-base text-gospel-gray-300">
          <th className="px-4 py-2 w-2/4"></th>
          <th className="px-4 py-2 font-medium w-1/5">Views</th>
          <th className="px-4 py-2 font-medium w-1/5">Comments</th>
          <th className="px-4 py-2 font-medium w-1/5">Likes</th>
        </tr>
      </thead>
      <tbody>
        {data
          .slice(0, showTrendingVideos ? data.length : 5)
          .map((item, index) => (
            <tr
              key={item.videoId}
              className={`text-center text-sm text-gray-800 ${
                index % 2 === 0 ? "bg-gospel-white-100" : "bg-transparent"
              }`}
            >
              <td className="px-4 py-2 flex flex-row gap-4 items-center">
                <div className="bg-gray-200 flex items-center justify-center">
                  <img
                    src={item.thumbnailURL}
                    alt="Video Thumbnail"
                    className="max-w-24 h-14 cursor-pointer"
                    onClick={() => openModal(item.videoId)}
                  />
                  <PlayArrow
                    htmlColor="#fff"
                    className="absolute cursor-pointer"
                    onClick={() => openModal(item.videoId)}
                  />
                </div>
                <div className="flex flex-col text-left">
                  <h3 className="font-medium text-gospel-gray-300">
                    {htmlUnescape(item.title)}
                  </h3>
                  <div className="flex flex-row">
                    <p className="text-sm text-gospel-gray-200 ">
                      {item.uploadDate}
                    </p>
                    <p className="mx-1 text-sm text-gospel-gray-200 ">| </p>
                    <p className="text-sm text-gospel-gray-200 ">
                      {moment(item.uploadTimestamp).format("MM/DD/YYYY, HH:mm")}
                    </p>
                  </div>
                </div>
              </td>
              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.viewCount)}
              </td>
              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.commentCount)}
              </td>
              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                {formatNumberWithCommas(item.likeCount)}
              </td>
            </tr>
          ))}
        {selectedVideoId && (
          <ConfigProvider
            theme={{
              components: {
                Modal: {
                  titleFontSize: 18,
                  headerBg: "transparent",
                  contentBg: "",
                  titleColor: "#353b3d",
                  boxShadow: "none",
                  fontFamily: "Montserrat, sans-serif",
                },
              },
            }}
          >
            <Modal
              title=""
              open={isYoutubeOpen}
              width={"fit-content"}
              height={"fit-content"}
              footer={null}
              closeIcon={
                <Cancel
                  className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                  fontSize="large"
                  onClick={closeModal}
                />
              }
              className="text-center"
            >
              <ReactPlayer
                controls
                width="750px"
                height={"450px"}
                url={`https://youtube.com/watch?v=${selectedVideoId}`}
              />
            </Modal>
          </ConfigProvider>
        )}

        <ConfigProvider
          theme={{
            components: {
              Modal: {
                titleFontSize: 22,
                titleColor: "#353b3d",
                fontFamily: "Montserrat, sans-serif",
              },
            },
          }}
        ></ConfigProvider>
      </tbody>
    </table>
  );
};

declare module "highcharts" {
  interface Point {
    fullDate?: string; // Add the custom property
  }
}

const options = quickDateRanges.map((item) => ({
  value: item.value,
  label: item.label,
}));

const chartLineWidth: number = 5;

const chartInterval: string[] = ["daily", "weekly", "monthly", "all-Time"];

interface ChannelStats {
  viewData: any[];
  subscriberData: any[];
  commentData: any[];
  uploadData: any[];
}

function Channel() {
  const { slug } = useParams();
  const userAccess = useSelector(
    (state: RootState) => state.user.user?.userAccess
  );
  const userToken = useSelector((state: RootState) => state.user.user?.token);
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const userback = useUserback();

  const [channelStats, setChannelStats] = useState<ChannelStats>({
    viewData: [],
    subscriberData: [],
    commentData: [],
    uploadData: [],
  });

  const [pageInfo, setPageInfo] = useState<PageInfo>();

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [isUploadedYoutubeOpen, setIsUploadedYoutubeOpen] =
    useState<boolean>(false);
  const [uploadedVideoId, setUploadedVideoId] = useState<string | null>(null);
  const [uploadedVideos, setUploadedVideos] = useState<VideoData[]>([]);
  const [channelInfo, setChannelInfo] = useState<ChannelData>();
  const [brandVideoInfo, setBrandVideoInfo] = useState<BrandVideoInfo[]>([]);
  const [selectedTabs, setSelectedTabs] = useState<number[]>([
    channelChartTabs[0].key,
  ]);
  const [selectedInterval, setSelectedInterval] = useState<string>(
    chartInterval[0]
  );

  const [isYoutubeOpen, setIsYoutubeOpen] = useState<boolean>(false);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);

  const [similarChannels, setSimilarChannels] = useState<SimilarChannels>(
    {} as SimilarChannels
  );
  const [demographicData, setDemographicData] = useState<ChannelDemographics>(
    {} as ChannelDemographics
  );
  const [loadSimilarChannels, setLoadSimilarChannels] =
    useState<boolean>(false);
  const [loadDemographicData, setLoadDemographicData] =
    useState<boolean>(false);

  const [showBrandedVideos, setShowBrandedVideos] = useState<boolean>(false);
  const [showSimilarChannels, setShowSimilarChannels] =
    useState<boolean>(false);
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const [loadChannelData, setLoadChannelData] = useState<boolean>(false);
  const [loadChannelInfo, setLoadChannelInfo] = useState<boolean>(false);
  const [loadPageInfo, setLoadPageInfo] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().subtract(30, "days").startOf("day"), // Subtract 29 days
    endDate: dayjs().startOf("day"), // End at today
  });
  const [trendingVideoInfo, setTrendingVideoInfo] = useState<{
    mostRecent: TrendingVideoInfo[];
    mostViewed: TrendingVideoInfo[];
  }>({ mostRecent: [], mostViewed: [] });
  const [watchlistModalOpen, setWatchlistModalOpen] = useState<boolean>(false);
  const [watchlistListModalOpen, setWatchlistListModalOpen] =
    useState<boolean>(false);
  const [watchList, setWatchList] = useState<WatchlistItem[]>([]);
  const [newWatchlistName, setNewWatchlistName] = useState<string>("");
  const [watchlistNameError, setWatchlistNameError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [addWatchlistloading, setAddWatchlistLoading] =
    useState<boolean>(false);
  const [showTrendingVideos, setShowTrendingVideos] = useState(false);
  const [activeTab, setActiveTab] = useState<TrendingVideoKey>("mostViewed");
  const [chartData, setChartData] = useState<Highcharts.Options>({
    chart: {
      type: "line",
      style: {
        fontSize: "16px",
        color: "#666666",
        fontFamily: "Montserrat, sans-serif",
      },
    },
    title: {
      text: undefined,
    },
    legend: {
      enabled: false,
    },

    series: [
      {
        type: "line",
        name: channelChartTabs[0].label,
        // data: [9, 4, 7, 1, 6],
        color: channelChartTabs[0].dataColor,
        lineWidth: chartLineWidth,
      },
    ],
    tooltip: {
      useHTML: true,
      backgroundColor: "transparent", // Remove background color
      borderWidth: 0, // Remove border
      shadow: false, // Remove shadow
    },
    xAxis: {
      type: "datetime",
      crosshair: {
        color: "black",
        width: 4,
      },

      categories: [],
      labels: {
        format: "{value:%b %e}",
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        title: {
          text: "Views",
        },
        min: 0,
      },
    ],
    plotOptions: {
      series: {
        zIndex: 100,
        clip: true,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 700, // Breakpoint for small screens
          },
          chartOptions: {
            chart: {
              margin: [10, 10, 50, 50], // Adjust margins for mobile
            },
            xAxis: {
              labels: {
                rotation: -45, // Rotate labels for better fit
                style: {
                  fontSize: "10px",
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  fontSize: "10px",
                },
              },
            },
            legend: {
              enabled: true,
              itemStyle: {
                fontSize: "10px",
              },
            },
          },
        },
      ],
    },
  });
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [demographicActiveKey, setDemographicActiveKey] = useState<
    string | string[]
  >("");
  const [similarChannelsActiveKey, setSimilarChannelsActiveKey] = useState<
    string | string[]
  >("");
  const [similarChannelYoutubeOpen, setSimilarChannelYoutubeOpen] =
    useState<boolean>(false);
  const [selectedSimilarChannelVideoId, setSelectedSimilarChannelVideoId] =
    useState<string | null>(null);

  const formatNumberWithCommas = (number: number | string) => {
    if (typeof number === "string") {
      number = parseFloat(number.replace(/,/g, "")); // Convert string to a floating-point number
    }

    const [integerPart, decimalPart] = number.toString().split(".");

    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    // Combine the formatted integer part with the decimal part if it exists
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  };

  const toggleShowSimilarChannels = () => {
    setShowSimilarChannels((prev) => !prev);
  };

  const demographicItems: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex items-center justify-between mx-8">
          <span className="text-xl font-semibold text-center">
            Demographic Data
          </span>
          {demographicActiveKey === "1" ? (
            <button onClick={() => handleDemographicToggle("1")}>
              <KeyboardArrowUp fontSize="large" />
            </button>
          ) : (
            <button onClick={() => handleDemographicToggle("1")}>
              <KeyboardArrowDown fontSize="large" />
            </button>
          )}
        </div>
      ),
      children: (
        <div className="bg-white shadow-md p-6 rounded-md">
          <div className="overflow-x-auto flex justify-center w-full">
            <table className="w-full border border-gray-300 text-center">
              <thead>
                <tr className="bg-gospel-purple text-white font-semibold text-base">
                  <th className="py-2 px-4 border border-gray-300" colSpan={2}>
                    Audience Gender Distribution
                  </th>
                  <th className="py-2 px-4 border border-gray-300" colSpan={2}>
                    Audience Location
                  </th>
                  <th className="py-2 px-4 border border-gray-300" colSpan={2}>
                    Audience Age Distribution
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Column Headers for Each Section */}
                <tr className="border-b border-gray-300 bg-gray-100">
                  <td
                    className="py-2 px-4 font-semibold border border-gray-300"
                    colSpan={2}
                  >
                    Gender
                  </td>
                  <td
                    className="py-2 px-4 font-semibold border border-gray-300"
                    colSpan={2}
                  >
                    Country
                  </td>

                  <td
                    className="py-2 px-4 font-semibold border border-gray-300"
                    colSpan={2}
                  >
                    Age Group
                  </td>
                </tr>

                {/* First Data Row */}
                <tr className="border-b border-gray-300">
                  {/* Gender (Merged Cell for Header) */}
                  <td className="py-3 px-4 border border-gray-300">Male</td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.totalMale?.toFixed(1)}%
                  </td>
                  {/* Location */}
                  <td className="py-3 px-4 border border-gray-300">
                    {demographicData?.geoCountry1 || "N/A"}
                  </td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.geoPercent1?.toFixed(1)}%
                  </td>
                  {/* Age */}
                  <td className="py-3 px-4 border border-gray-300">13-17</td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.agePercent1317?.toFixed(1)}%
                  </td>
                </tr>

                {/* Second Data Row */}
                <tr className="border-b border-gray-300">
                  <td className="py-3 px-4 border border-gray-300">Female</td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.totalFemale?.toFixed(1)}%
                  </td>
                  <td className="py-3 px-4 border border-gray-300">
                    {demographicData?.geoCountry2 || "N/A"}
                  </td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.geoPercent2?.toFixed(1)}%
                  </td>
                  <td className="py-3 px-4 border border-gray-300">18-24</td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.agePercent1824?.toFixed(1)}%
                  </td>
                </tr>

                {/* Third Data Row */}
                <tr className="border-b border-gray-300">
                  <td className="py-3 px-4 border border-gray-300"></td>
                  <td className="py-3 px-4 border border-gray-300"></td>
                  <td className="py-3 px-4 border border-gray-300">
                    {demographicData?.geoCountry3 || "N/A"}
                  </td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.geoPercent3?.toFixed(1)}%
                  </td>
                  <td className="py-3 px-4 border border-gray-300">25-34</td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.agePercent2534?.toFixed(1)}%
                  </td>
                </tr>

                {/* Fourth Data Row */}
                <tr className="border-b border-gray-300">
                  <td className="py-3 px-4 border border-gray-300"></td>
                  <td className="py-3 px-4 border border-gray-300"></td>
                  <td className="py-3 px-4 border border-gray-300">
                    {demographicData?.geoCountry4 || "N/A"}
                  </td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.geoPercent4?.toFixed(1)}%
                  </td>
                  <td className="py-3 px-4 border border-gray-300">35-44</td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.agePercent3544?.toFixed(1)}%
                  </td>
                </tr>

                {/* Fifth Data Row */}
                <tr className="border-b border-gray-300">
                  <td className="py-3 px-4 border border-gray-300"></td>
                  <td className="py-3 px-4 border border-gray-300"></td>
                  <td className="py-3 px-4 border border-gray-300">
                    {demographicData?.geoCountry5 || "N/A"}
                  </td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.geoPercent5?.toFixed(1)}%
                  </td>
                  <td className="py-3 px-4 border border-gray-300">45-64</td>
                  <td className="py-3 px-4 border border-gray-300 text-green-500 font-bold">
                    {demographicData?.agePercent4564?.toFixed(1)}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
  ];

  const similarChannelsItems: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex items-center justify-between mx-8">
          <span className="text-xl font-semibold text-center">
            Similar Channels
          </span>
          {similarChannelsActiveKey === "1" ? (
            <button onClick={() => handleSimilarChannelsToggle("1")}>
              <KeyboardArrowUp fontSize="large" />
            </button>
          ) : (
            <button onClick={() => handleSimilarChannelsToggle("1")}>
              <KeyboardArrowDown fontSize="large" />
            </button>
          )}
        </div>
      ),
      children:
        similarChannels.similarChannels?.length === 0 ? (
          <div className="text-gospel-error-red  text-sm bg-gospel-red-100 px-3 py-2 rounded flex items-center justify-center m-8">
            {"No Similar Channels available."}
          </div>
        ) : (
          <>
            <div className="flex justify-center w-full">
              <table className="divide-y divide-gray-200 w-full mx-4">
                <thead>
                  <tr className="text-center text-base text-gospel-gray-300">
                    <th className="px-4 py-2"></th>
                    <th className="px-4 py-2 font-medium">Channel</th>

                    <th className="px-4 py-2 font-medium">Subscribers</th>
                    <th className="px-4 py-2 font-medium">Last Upload</th>
                    <th className="px-4 py-2 font-medium">Views</th>
                    <th className="px-4 py-2 font-medium">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {similarChannels.similarChannels &&
                    similarChannels.similarChannels
                      .slice(
                        0,
                        showSimilarChannels
                          ? similarChannels.similarChannels?.length
                          : 5
                      )
                      .map((item, index) => {
                        return (
                          <tr
                            key={item.lastUploadId}
                            className={`text-center text-sm text-gray-800 ${
                              index % 2 === 0
                                ? "bg-gospel-white-100"
                                : "bg-transparent"
                            }`}
                          >
                            <td className="px-4 py-2 w-24">
                              <img
                                src={item.channelImage}
                                className="w-16 h-16 rounded-full"
                                alt="title"
                              />
                            </td>
                            <td className="px-4 py-2 text-gospel-purple text-base cursor-pointer">
                              <a href={`/channel/${item.gospelPageSlug}`}>
                                {item.channelTitle}
                              </a>
                            </td>

                            <td className="px-4 py-2 text-gospel-gray-200 text-base">
                              {formatNumberWithCommas(item.subs)}
                            </td>

                            <td className="px-4 py-2 flex flex-row gap-4 items-center">
                              <div
                                className=" bg-gray-200 flex items-center justify-center cursor-pointer"
                                onClick={() => {
                                  setSelectedSimilarChannelVideoId(
                                    item.lastUploadId
                                  );
                                  setSimilarChannelYoutubeOpen(true);
                                }}
                              >
                                <img
                                  src={item.lastUploadThumbnail}
                                  alt="Video Thumbnail"
                                  className="max-w-32 h-16"
                                />
                                <PlayArrow
                                  htmlColor="#fff"
                                  className="absolute"
                                />
                              </div>
                              <div className="flex flex-col text-left">
                                <h3
                                  className="font-medium text-gospel-gray-300 cursor-pointer"
                                  onClick={() => {
                                    setSelectedSimilarChannelVideoId(
                                      item.lastUploadId
                                    );
                                    setSimilarChannelYoutubeOpen(true);
                                  }}
                                >
                                  {item.lastUploadTitle}
                                </h3>
                                <div className="flex flex-row">
                                  <p className="text-sm text-gospel-gray-200 ">
                                    {item.lastUploadDate}
                                  </p>
                                  <p className="mx-1 text-sm text-gospel-gray-200 ">
                                    |{" "}
                                  </p>
                                  <p className="text-sm text-gospel-gray-200 ">
                                    {moment(item.lastUploadTimestamp).format(
                                      "MM/DD/YYYY, HH:mm"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td className="px-4 py-2 text-gospel-gray-200 text-base">
                              {formatNumberWithCommas(item.lastUploadViewCount)}
                            </td>
                            <td className="px-4 py-2 text-gospel-gray-200 text-base">
                              {formatNumberWithCommas(
                                item.lastUploadCommentCount
                              )}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
            {/* Pagination Buttons */}
            {similarChannels.similarChannels?.length > 2 && (
              <div className="flex items-start justify-center">
                <GospelButton
                  className="bg-white px-2  my-2 py-2 w-36 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                  label={showSimilarChannels ? "Show Less" : "View More"}
                  onClick={toggleShowSimilarChannels}
                />
              </div>
            )}

            {selectedSimilarChannelVideoId && (
              <ConfigProvider
                theme={{
                  components: {
                    Modal: {
                      titleFontSize: 18,
                      headerBg: "transparent",
                      contentBg: "",
                      titleColor: "#353b3d",
                      boxShadow: "none",
                      fontFamily: "Montserrat, sans-serif",
                    },
                  },
                }}
              >
                <Modal
                  title=""
                  open={similarChannelYoutubeOpen}
                  width={"fit-content"}
                  height={"fit-content"}
                  footer={null}
                  closeIcon={
                    <Cancel
                      className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                      fontSize="large"
                      onClick={() => {
                        setSimilarChannelYoutubeOpen(false);
                        setSelectedSimilarChannelVideoId(null);
                      }}
                    />
                  }
                  className="text-center"
                >
                  <ReactPlayer
                    controls
                    width="750px"
                    height={"450px"}
                    url={`https://youtube.com/watch?v=${selectedSimilarChannelVideoId}`}
                  />
                </Modal>
              </ConfigProvider>
            )}
          </>
        ),
    },
  ];

  const fetchWatchlist = async () => {
    try {
      const result: any = await get("/gospel/watchlist/list");
      if (result) {
        setWatchList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const createNewWatchlist = async () => {
    try {
      setLoading(true);
      if (newWatchlistName.trim() === "") {
        setWatchlistNameError("Please enter a watchlist name.");
        return;
      }
      await get(`/gospel/watchlist/create?title=${newWatchlistName}`);
      fetchWatchlist();
      setNewWatchlistName("");
      setWatchlistNameError("");
      setLoading(false);
    } catch (error) {
      console.error("Error creating new watchlist:", error);
      setLoading(false);
    }
  };

  const addToWatchlist = async (watchlistId: number, channelId: string) => {
    try {
      setAddWatchlistLoading(true);
      const data = new URLSearchParams();
      data.append("list_id", watchlistId.toString());
      data.append("channel_id", channelId);

      const response = await post("/gospel/watchlist/add", data);
      if (response) {
        setAddWatchlistLoading(false);
      }
      setWatchlistModalOpen(false);
      fetchWatchlist();
      fetchPageData();
    } catch (error) {
      console.error("Error adding ̦to watchlist:", error);
      setAddWatchlistLoading(false);
    }
  };

  const handleIntervalChange = (interval: string) => {
    setSelectedInterval(interval);
    updateDateRange(interval);
  };

  const updateDateRange = (interval: string) => {
    const now = dayjs();
    let startDate;

    switch (interval) {
      case "daily":
        startDate = now.subtract(30, "days");
        break;
      case "weekly":
        startDate = now.subtract(90, "days");
        break;
      case "monthly":
        startDate = now.subtract(365, "days");
        break;
      case "all-Time":
        startDate = now.subtract(365, "days");
        break;
      default:
        startDate = now.subtract(30, "days");
    }

    setDateRange({ startDate, endDate: now });
  };

  const handleSelectChange = (value: string) => {
    const tempStartDate =
      quickDateRanges.find((e) => e.value === value)?.startDate ?? dayjs();
    const tempEndDate =
      quickDateRanges.find((e) => e.value === value)?.endDate ?? dayjs();
    setSelectedValue(value);
    setDateRange((prev) => {
      return {
        ...prev,
        startDate: tempStartDate,
        endDate: tempEndDate,
      };
    });

    getGraphData(
      channelChartTabs
        .filter((e) => selectedTabs.includes(e.key))
        .map((e) => e),
      pageInfo?.socialNetworks[0].identifier ?? "",
      tempStartDate.valueOf(),
      tempEndDate.valueOf(),
      selectedInterval
    );
  };

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (!dates || !dates[0] || !dates[1]) {
      console.warn("Invalid dates selected:", dates);
      return;
    }

    let startDate = dayjs(dates[0]).startOf("day");
    let endDate = dayjs(dates[1]).endOf("day");

    // Enforce maximum range of 3 years (1095 days)
    if (endDate.diff(startDate, "days") > 1095) {
      startDate = dayjs().subtract(3, "years").startOf("day");
      endDate = dayjs().endOf("day");
      console.warn("Adjusted Range due to max limit:", { startDate, endDate });
    }

    setSelectedValue(""); // Reset quick range
    setDateRange({ startDate, endDate });

    // Fetch graph data
    getGraphData(
      channelChartTabs
        .filter((e) => selectedTabs.includes(e.key))
        .map((e) => e),
      pageInfo?.socialNetworks[0]?.identifier ?? "",
      startDate.valueOf(),
      endDate.valueOf(),
      selectedInterval
    );
  };

  const openBrandedVideoModal = (videoId: string) => {
    setSelectedVideoId(videoId);
    setIsYoutubeOpen(true);
  };

  const closeBrandedVideoModal = () => {
    setIsYoutubeOpen(false);
    setSelectedVideoId(null);
  };

  const closeChartModal = () => {
    setIsUploadedYoutubeOpen(false);
    setUploadedVideoId(null);
  };

  const fetchPageInfo = async () => {
    setLoadPageInfo(true);
    try {
      const result = await get<PageInfo>(`/gospel/page/info?slug=${slug}`);
      if (result) {
        setPageInfo(result);
        return result;
      }
    } catch (error) {
      console.error("Error fetching page info:", error);
    } finally {
      setLoadPageInfo(false);
    }
    return null;
  };

  const handleFeatureRequest = () => {
    if (userback) {
      setTimeout(() => {
        userback.open('feature_request', 'form');
      }, 500);
    }
  };

  const fetchChannelOverview = async (channelId: string) => {
    try {
      const result = await get<ChannelData>(
        `/youtube/channel/overview?channel_id=${channelId}`
      );
      if (result) {
        setChannelInfo(result);

        setChannelStats({
          viewData: generateViewData(result),
          subscriberData: generateSubscriberData(result),
          commentData: generateCommentsData(result),
          uploadData: generateUploadsData(result),
        });
      }
    } catch (error) {
      console.error("Error fetching channel data:", error);
    } finally {
      setLoadChannelInfo(false);
      setLoadChannelData(false);
    }
  };

  const fetchDemographicData = async (channelId: string) => {
    setLoadDemographicData(true);
    try {
      const result = await get<ChannelDemographics>(
        `/youtube/channel/demographics?channel_id=${channelId}`
      );

      if (result) {
        setDemographicData(result);
      }
    } catch (error) {
      console.error("Error fetching demographic data:", error);
    } finally {
      setLoadDemographicData(false);
    }
  };

  const fetchBrandedVideos = async (channelId: string) => {
    try {
      const result = await get<BrandVideoInfo[]>(
        `/youtube/channel/videos/branded?channel_id=${channelId}`
      );
      if (result.length > 0) {
        setBrandVideoInfo(result);
      }
    } catch (error) {
      console.error("Error fetching branded videos:", error);
    }
  };

  const fetchTrendingVideos = async (channelId: string) => {
    try {
      const [mostRecentVideos, mostViewedVideos] = await Promise.all([
        get<TrendingVideoInfo[]>(
          `/youtube/channel/videos/mostrecent?channel_id=${channelId}`
        ),
        get<TrendingVideoInfo[]>(
          `/youtube/channel/videos/mostviewed?channel_id=${channelId}`
        ),
      ]);
      setTrendingVideoInfo({
        mostRecent: mostRecentVideos || [],
        mostViewed: mostViewedVideos || [],
      });
    } catch (error) {
      console.error("Error fetching trending videos:", error);
    }
  };

  const fetchSimilarChannels = async (channelId: string) => {
    try {
      const result = await get<SimilarChannels>(
        `/youtube/channel/lookalikes?channel_id=${channelId}`
      );
      if (result.similarChannels.length > 0) {
        setSimilarChannels(result);
        setLoadSimilarChannels(false);
      }
    } catch (error) {
      console.error("Error fetching similar channels:", error);
    } finally {
    }
  };

  const fetchGraphData = async (channelId: string) => {
    setLoadingChart(true);
    try {
      await getGraphData(
        channelChartTabs
          .filter((e) => selectedTabs.includes(e.key))
          .map((e) => e),
        channelId,
        dateRange.startDate.valueOf(),
        dateRange.endDate.valueOf(),
        selectedInterval
      );
    } catch (error) {
      console.error("Error fetching graph data:", error);
    } finally {
      setLoadingChart(false);
    }
  };

  const fetchPageData = async () => {
    try {
      setLoadPageInfo(true);
      setLoadChannelData(true);
      setLoadingChart(true);
      setLoadSimilarChannels(true);
      setLoadDemographicData(true);
      setLoadChannelInfo(true);

      const pageInfoResult = await fetchPageInfo();

      if (!pageInfoResult) return;

      const channelId =
        pageInfoResult?.socialNetworks?.find(
          (network) => network.name === "youtube"
        )?.identifier || "";

      if (!channelId) {
        console.error("Channel ID is missing");
        return;
      }
      fetchDemographicData(channelId);
      fetchChannelOverview(channelId);
      fetchBrandedVideos(channelId);
      fetchTrendingVideos(channelId);
      fetchSimilarChannels(channelId);
      fetchGraphData(channelId);

      // Delay fetching similar channels again
      setTimeout(() => fetchSimilarChannels(channelId), 3000);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Effects
  useEffect(() => {
    fetchPageData();
    fetchWatchlist();
  }, [slug]);

  useEffect(() => {
    if (pageInfo?.socialNetworks[0]?.identifier) {
      fetchGraphData(pageInfo.socialNetworks[0].identifier);
    }
  }, [selectedInterval, dateRange]);

  const getGraphData = async (
    sTabs: {
      dataGraph: string;
      label: string;
      dataColor: string;
      keyName: string;
    }[],

    channel_id: string,
    startDate: number,
    endDate: number,
    interval: string
  ) => {
    let series: Highcharts.SeriesOptionsType[] = [];
    let xAxisCategories: string[] = [];
    let graphData: any;
    let uniqueDates: string[] = []; // Collect scatterplot-specific data
    let allGraphDates: string[] = []; // Collect all dates across graphs
    const pageInformation = await get<PageInfo>(
      `/gospel/page/info?slug=${slug}`
    );
    const dayDifference: number = moment(endDate).diff(
      moment(startDate),
      "days"
    );
    let dateFormat: string;
    if (
      dayDifference > 365 &&
      (interval === "daily" ||
        interval === "weekly" ||
        interval === "all-Time" ||
        interval === "monthly")
    ) {
      dateFormat = "DD MMM YYYY";
    } else if (interval === "daily" || interval === "weekly") {
      dateFormat = "MMM DD"; // Example: Nov 4
    } else if (interval === "all-Time" || interval === "monthly") {
      dateFormat = "MMM YYYY"; // Example: Nov 2023
    } else {
      dateFormat = "MMM DD";
    }
    if (interval === "all-Time") {
      sTabs.forEach((tab) => {
        tab.keyName = "aggregate";
      });
    } else {
      sTabs.forEach((tab) => {
        tab.keyName = "count";
      });
    }
    let uploadGraphData: any = null;
    try {
      for (let i = 0; i < sTabs.length; i++) {
        try {
          if (interval === "all-Time") {
            graphData = await get<BrandVideoInfo>(
              `/youtube/stats/channel/${sTabs[i].dataGraph}?channel_id=${channel_id}&start=${startDate}&end=${endDate}&interval=${"all"}`
            );
          } else {
            graphData = await get<BrandVideoInfo>(
              `/youtube/stats/channel/${sTabs[i].dataGraph}?channel_id=${channel_id}&start=${startDate}&end=${endDate}&interval=${interval}`
            );
          }

          if (graphData) {
            const graphDates = graphData.map((e: any) => ({
              raw: dayjs.utc(e.date).format(dateFormat), // Use raw ISO format for comparison
              value: e[sTabs[i].keyName],
            }));

            allGraphDates = [...allGraphDates, ...graphDates]; // Collect all dates

            let current = dayjs.utc(startDate);
            const end = dayjs.utc(endDate);
            const completeDates: string[] = [];

            // Generate all dates based on the interval
            while (current.isBefore(end) || current.isSame(end, "day")) {
              completeDates.push(current.format(dateFormat));
              current = current.add(1, "day"); // Add a week or a day
            }

            uniqueDates = Array.from(new Set(completeDates)); // Ensure no duplicates

            xAxisCategories = uniqueDates; // Use uniqueDates as x-axis categories

            if (sTabs[i].dataGraph === "uploads") {
              const bubbleData = uniqueDates.map((date) => {
                const match = graphData.find(
                  (g: any) => dayjs.utc(g.date).format(dateFormat) === date
                );
                return {
                  x: uniqueDates.indexOf(date),
                  y: -9999, // Keep uploads at a fixed Y-axis value
                  uploadCount: match ? match.uploadCount : null, // Show upload count if available
                };
              });

              if (sTabs[i].dataGraph === "uploads") {
                uploadGraphData = graphData;
              }

              series.push({
                type: "bubble",
                name: sTabs[i].label,
                data: bubbleData.filter((d) => d.uploadCount !== null),

                marker: {
                  fillColor: sTabs[i].dataColor, // Color for the bubble
                  lineWidth: 0, // No border for bubbles
                  fillOpacity: 1,
                  radius: 10, // Keep radius the same for consistent bubble size
                  symbol: "circle", // Ensure the bubble is fully round
                },
                connectNulls: true,
                dataLabels: {
                  enabled: true,
                  format: "{point.uploadCount}", // Display the upload count in the bubble
                  align: "center",
                  verticalAlign: "middle",
                  style: {
                    color: "white", // Text color inside the bubble
                    textOutline: "none", // No text outline
                    fontSize: "12px", // Text size
                  },
                  inside: true, // Ensure the text stays inside the bubble
                  overflow: "allow",
                  crop: false,
                  zIndex: 6,
                },
                yAxis: i,
                lineWidth: 0,
                zIndex: 6, // Ensure the bubbles render above axis and grid lines
                clip: false, // Prevent bubbles from being clipped by chart boundaries
                maxSize: "14%", // Ensure proportional bubble sizing
                minSize: "7%", // Ensure proportional bubble sizing
              });
            } else {
              const graphDates = graphData.map((e: any) => ({
                raw: dayjs.utc(e.date).format(dateFormat), // Use raw ISO format for comparison
                value: e[sTabs[i].keyName],
              }));

              const filteredGraphData = uniqueDates.map((date) => {
                const match = graphDates.find((g: any) => g.raw === date); // Match raw dates

                // Find full date match by normalizing formats
                const fullDateMatch = graphData.reverse().find((g: any) => {
                  const formattedGraphDate = dayjs(g.date).format(dateFormat);
                  return formattedGraphDate === date;
                });

                return {
                  value: match ? match.value : null, // Y-value for the graph
                  fullDate: fullDateMatch
                    ? dayjs(fullDateMatch.date).format("YYYY-MM-DD HH:mm:ss") // Format full date
                    : "", // Handle missing dates
                };
              });
              series.push({
                type: "line",
                name: sTabs[i].label,
                yAxis: sTabs[i].dataGraph === "uploads" ? 0 : i,
                data: filteredGraphData.map((item, index) => ({
                  x: index, // X-axis index based on uniqueDates
                  y: item.value !== null ? item.value : null, // Fill missing values with null
                  fullDate: graphData[index]?.date || null,
                })),

                color: sTabs[i].dataColor,
                lineWidth: 4,
                marker: {
                  fillColor: sTabs[i].dataColor, // Color for the bubble
                  lineWidth: 0, // No border for bubbles
                  fillOpacity: 1,
                  radius: 4, // Keep radius the same for consistent bubble size
                  symbol: "circle", // Ensure the bubble is fully round
                },
                connectNulls: true,
                zones: [
                  {
                    value: 0,
                    color: "rgb(189, 57, 73)",
                  },
                  {
                    color: sTabs[i].dataColor,
                  },
                ],
              } as Highcharts.SeriesLineOptions);
            }
          }
          setLoadingChart(false);
        } catch (error) {
          setLoadingChart(false);
          console.error(
            `Error fetching data for ${sTabs[i].dataGraph}:`,
            error
          );
        }
      }

      const labelRotation = determineLabelRotation(
        dayDifference,
        selectedInterval
      );
      // Update state only if series and xAxisCategories have been updated
      if (series.length > 0 && xAxisCategories.length > 0) {
        setChartData((prev: any) => ({
          ...prev,

          series: series,
          tooltip: {
            useHTML: true,
            backgroundColor: "transparent", // Remove background color
            borderWidth: 0, // Remove border
            shadow: false, // Remove shadow
            formatter: function () {
              if (this.series.type === "bubble") {
                return false; // Disable tooltip for bubble series
              }
              const xIndex = this.point.x as number;
              const dateFormat = {
                daily: "MMM DD",
                weekly: "MMM DD",
                monthly: "MMM YYYY",
                "all-Time": "MMM YYYY",
              }[interval];
              let formattedDate = uniqueDates[xIndex];
              if (interval === "weekly") {
                const startOfWeek = dayjs(
                  uniqueDates[xIndex],
                  dateFormat
                ).format("MMM DD");
                const endOfWeek = dayjs(uniqueDates[xIndex], dateFormat)
                  .add(6, "days")
                  .format("MMM DD");
                formattedDate = `${startOfWeek} - ${endOfWeek}`;
              }

              // Construct the tooltip content
              let tooltipContent = sTabs
                .map((item, index) => {
                  if (item.dataGraph !== "uploads") {
                    const yValue = this.series.chart.series[index].data.find(
                      (point) => point.x === xIndex
                    )?.y;

                    const formattedValue = Highcharts.numberFormat(
                      yValue as number,
                      0,
                      ".",
                      ","
                    );

                    return `
      <span class="tooltipNumber">
        <span class="tooltipColor" style="background:${item.dataColor}"></span>
        <span class="tooltipKey" style="color:${item.dataColor}"> ${
          item.label
        }: </span>
        ${formattedValue || "N/A"}
      </span>
    `;
                  }
                })
                .join("");

              return `
<div class="tooltip">
  <span class="tooltipDate">${formattedDate}</span>
  ${tooltipContent}
</div>
`;
            },
          },
          xAxis: {
            ...prev.xAxis,
            categories: uniqueDates,
            type: "datetime",
            minPadding: 0.05,
            maxPadding: 0.05,
            labels: {
              rotation: labelRotation,
              style: {
                fontSize: "12px",
                color: "#666666",
                fontFamily: "Montserrat, sans-serif",
                textOverflow: "none",
                whiteSpace: "nowrap",
              },
              y: 35,
            },

            tickWidth: 0.5,

            tickmarkPlacement: "on", // Ensure ticks are aligned with labels
          },

          yAxis: series.map((e: Highcharts.SeriesOptionsType, i: number) => {
            if (sTabs[i].dataGraph === "uploads") {
              return {
                visible: false, // Hide Y-axis for bubbles
                title: {
                  text: null,
                },
                min: -9999, // Ensure bubbles stay at the bottom
                max: 0, // Limit the range
                labels: {
                  enabled: false,
                },
              };
            }
            const seriesData = (e as Highcharts.SeriesLineOptions).data || [];

            const yValues = seriesData.map((d: any) => d.y).filter((v) => v !== null);
            const yMin = yValues.length > 0 ? Math.min(...yValues) : 0;
            const yMax = yValues.length > 0 ? Math.max(...yValues) : 0;

            const padding = (yMax - yMin) * 0.1 || 1; // default padding if yMax == yMin

            // const yMin =
            //   seriesData.length > 0
            //     ? Math.min(...seriesData.map((d: any) => d.y))
            //     : 0;
            // const yMax =
            //   seriesData.length > 0
            //     ? Math.max(...seriesData.map((d: any) => d.y))
            //     : 0;

            return {
              title: {
                text: e.name,
              },
              min: yMin < 0 ? yMin : 0,
              max: Math.ceil(yMax + padding),
              visible: true,
              labels: {
                enabled: true,
                formatter: function () {
                  const value = this.value as number;

                  if (Math.abs(value) >= 1000000000) {
                    return value / 1000000000 + "B"; // Include sign for billions
                  } else if (Math.abs(value) >= 1000000) {
                    return value / 1000000 + "M"; // Include sign for millions
                  } else if (Math.abs(value) >= 1000) {
                    return value / 1000 + "K"; // Include sign for thousands
                  } else {
                    return value.toString(); // Show integer values for less than 1000
                  }
                },
                style: {
                  fontSize: "12px",
                  color: "#666666",
                  fontFamily: "Montserrat, sans-serif",
                  textOverflow: "none",
                  whiteSpace: "nowrap",
                },
              },

              opposite: i % 2 === 0 ? false : true,
            };
          }),
          exporting: {
            enabled: userAccess?.includes("graph_csv_download"),
            csv: {
              dateFormat: "%Y-%m-%d", // Ensure the correct date format for CSV
            },
            buttons: {
              contextButton: {
                align: "right",
                verticalAlign: "top",
                menuItems: [
                  {
                    text: "Download JPEG image",
                    onclick: () => {
                      if (chartRef.current?.chart) {
                        chartRef.current.chart.exportChartLocal(
                          {
                            type: "image/jpeg",
                            filename: `${pageInformation?.title}-YouTube-Graph`,
                          },
                          {
                            title: {
                              text: `${pageInformation?.title}-YouTube-Graph`,
                              align: "center",
                              verticalAlign: "top",
                              style: {
                                fontSize: "18px",
                                fontFamily: "Montserrat, sans-serif",
                                color: "#333",
                              },
                              y: 20, // Add space above the chart area for the title
                            },

                            chart: {
                              spacingTop: 40, // Adjust spacing to fit the title
                            },
                            plotOptions: {
                              series: {
                                dataLabels: {
                                  enabled: true, // Ensure data labels are shown in export
                                  formatter: function () {
                                    const value = this.y; // Access the y value of the data point
                                    if (value) {
                                      if (Math.abs(value) >= 1000000000) {
                                        return (
                                          (value / 1000000000).toFixed(1) + "B"
                                        ); // Format for billions
                                      } else if (Math.abs(value) >= 1000000) {
                                        return (
                                          (value / 1000000).toFixed(1) + "M"
                                        ); // Format for millions
                                      } else if (Math.abs(value) >= 1000) {
                                        return (value / 1000).toFixed(1) + "K"; // Format for thousands
                                      } else {
                                        return value.toFixed(1); // Show value with 1 decimal for less than 1000
                                      }
                                    }
                                  },
                                },
                              },
                            },
                          }
                        );
                      }
                    },
                  },
                  {
                    text: "Download CSV",
                    onclick: function () {
                      const chart = chartRef?.current?.chart;
                      if (chart) {
                        const csvRows = [
                          ["DateTime", ...chart.series.map((s) => s.name)],
                        ];
                        const seriesDataLength =
                          chart.series[0]?.data.length || 0;

                        for (let i = 0; i < seriesDataLength; i++) {
                          const row = [];
                          const fullDate =
                            chart.series[0].data[i]?.fullDate || ""; // Use fullDate from the first series

                          if (fullDate === "") {
                            // Skip rows with empty fullDate
                            continue;
                          }

                          row.push(moment(fullDate).format("YYYY-MM-DD"));
                          chart.series.forEach((series) => {
                            const point = series.data[i];
                            row.push(point?.y?.toString() || ""); // Add the y-value or an empty string
                          });

                          csvRows.push(row);
                        }
                        const csvContent =
                          "data:text/csv;charset=utf-8," +
                          csvRows.map((row) => row.join(",")).join("\n");

                        const encodedUri = encodeURI(csvContent);
                        const link = document.createElement("a");
                        link.setAttribute("href", encodedUri);
                        link.setAttribute("download", `${"chart"}.csv`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }
                    },
                  },
                ],

                symbol: "menu",
                theme: {
                  fill: "#ffffff",
                  stroke: "none",
                  states: {
                    hover: {
                      fill: "#f0f0f0",
                    },
                    select: {
                      fill: "#e0e0e0",
                    },
                  },
                },
              },
            },
          },
          plotOptions: {
            series: {
              zIndex: 100,
            },

            bubble: {
              zIndex: 5,
              sizeBy: "width", // Scale bubbles based on chart width
              minSize: 10, // Minimum bubble size
              maxSize: 20, // Maximum bubble size
              point: {
                events: {
                  click: async function () {
                    const { x } = this.options; // Access the x-coordinate (index)
                    if (x === undefined) {
                      return;
                    }
                    // Convert x index to a date using uniqueDates array
                    const clickedDate = uniqueDates[x];
                    // Find the matching entry in the graphData based on the clicked date
                    const bubbleData = uploadGraphData.find(
                      (data: any) =>
                        dayjs.utc(data.date).format(dateFormat) === clickedDate
                    );
                    try {
                      // Prepare payload for multiple videos
                      const videoPayload = bubbleData?.videos?.map(
                        (video: any) => ({
                          datePublished: bubbleData.date,
                          videoId: video.videoId,
                        })
                      );

                      const result = await postWithData<VideoData[]>(
                        "/youtube/video/expand",
                        videoPayload
                      );

                      const videoId = result[0]?.videoId;
                      setUploadedVideoId(videoId);
                      setUploadedVideos(result);
                      setIsUploadedYoutubeOpen((prev) => !prev);
                      setTimeout(() => setIsUploadedYoutubeOpen(true), 0);
                    } catch (error) {
                      console.error("Error fetching video data:", error);
                    }

                    // Get the first video's videoId (assuming only one video per date)
                  },
                },
              },
            },
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 1300, // Breakpoint for small screens
                },
                chartOptions: {
                  xAxis: {
                    labels: {
                      rotation: -45, // Rotate labels for better fit
                      style: {
                        fontSize: "10px",
                      },
                    },
                  },
                  yAxis: {
                    labels: {
                      style: {
                        fontSize: "10px",
                      },
                    },
                  },
                  legend: {
                    enabled: true,
                    itemStyle: {
                      fontSize: "10px",
                    },
                  },
                },
              },
            ],
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingChart(false);
    }
  };

  const toggleShowBrandedVideos = () => {
    setShowBrandedVideos(!showBrandedVideos);
  };

  const handleTabClick = (key: number) => {
    setSelectedTabs((prevSelectedTabs) => {
      const sTabs = prevSelectedTabs.includes(key)
        ? prevSelectedTabs.filter((tabKey) => tabKey !== key)
        : [...prevSelectedTabs, key];

      getGraphData(
        channelChartTabs.filter((e) => sTabs.includes(e.key)).map((e) => e),
        pageInfo?.socialNetworks[0].identifier ?? "",
        dateRange.startDate.valueOf(),
        dateRange.endDate.valueOf(),
        selectedInterval
      );

      return sTabs;
    });
  };

  const platforms: Platform[] = (pageInfo?.links ?? [])
    .map((url: string) => getPlatform(url))
    .filter((value, index, self) => self.indexOf(value) === index);

  const handleToggle = (key: string) => {
    setActiveKeys((prevKeys) => {
      const currentSet = new Set(prevKeys);
      if (currentSet.has(key)) {
        currentSet.delete(key);
      } else {
        currentSet.add(key);
      }
      return Array.from(currentSet);
    });
  };
  const handleDemographicToggle = (key: string | string[]) => {
    setDemographicActiveKey(key);
  };

  const handleSimilarChannelsToggle = (key: string | string[]) => {
    setSimilarChannelsActiveKey(key);
  };

  const collapseViewItems = (channelStats.viewData || []).map(
    (data: any, index: number) => ({
      key: index.toString(),
      label: (
        <div
          className="w-full py-6 border-b-2 border-gray-300 relative  flex items-center justify-between cursor-pointer"
          onClick={() => handleToggle(index.toString())}
          key={index.toString()}
        >
          <div>
            <span className="text-lg font-semibold inline-block relative">
              {data.title}
              <Tooltip placement="top" title={data.info}>
                {data.key !== "last24Hours" && (
                  <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                )}
              </Tooltip>
            </span>
            <span className="text-xs opacity-30 relative">
              {data.description}
            </span>
          </div>
          {data.children && data.children.length > 0 && (
            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-gray-300 text-white px-3 text-xs rounded-t-lg cursor-pointer hover:bg-gray-800">
              {activeKeys.includes(index.toString()) ? (
                <ArrowDropUp style={{ fontSize: 20 }} />
              ) : (
                <ArrowDropDown style={{ fontSize: 20 }} />
              )}
            </span>
          )}
          <div className="flex items-center gap-2">
            <span className="float-right text-lg font-semibold text-gray-800">
              {formatNumberWithCommas(data.value)}
            </span>
            {data.percentChange ? (
              <span
                className={`w-[66px] float-right text-base ${
                  data.percentChange?.startsWith("-")
                    ? "text-gospel-error-red"
                    : "text-green-500"
                }`}
              >
                {data.percentChange?.startsWith("-")
                  ? `${data.percentChange}`
                  : `+${data.percentChange}`}
              </span>
            ) : (
              <span className="w-[66px]"></span>
            )}
          </div>
        </div>
      ),
      children:
        data.children &&
        data.children.length > 0 &&
        data.children.map((child: any, childIndex: number) => (
          <div
            key={childIndex}
            className="w-full py-6 border-b-2 border-gray-300 relative flex items-center justify-between cursor-pointer"
          >
            <div>
              <span className="text-lg font-semibold inline-block relative text-gospel-gray-400">
                {child.title}
                <Tooltip placement="top" title={child.info}>
                  <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                </Tooltip>
              </span>
              <span className="text-xs opacity-30 relative text-gospel-gray-400">
                {child.description}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="float-right text-lg font-semibold text-gospel-gray-400">
                {formatNumberWithCommas(child.value)}
              </span>
              {child.percentChange ? (
                <span
                  className={`w-[66px] float-right text-base ${
                    child.percentChange?.startsWith("-")
                      ? "text-gospel-error-red"
                      : "text-green-500"
                  }`}
                >
                  {child.percentChange?.startsWith("-")
                    ? `${child.percentChange}`
                    : `+${child.percentChange}`}
                </span>
              ) : (
                <span className="w-[66px]"></span>
              )}
            </div>
          </div>
        )),
    })
  );

  const collapseCommentsItems = (channelStats.commentData || []).map(
    (data: any, index: number) => ({
      key: index.toString(),
      label: (
        <div
          className="w-full py-6 border-b-2 border-gray-300 relative  flex items-center justify-between cursor-pointer"
          onClick={() => handleToggle(index.toString())}
          key={index.toString()}
        >
          <div>
            <span className="text-lg font-semibold inline-block relative">
              {data.title}
              <Tooltip placement="top" title={data.info}>
                {data.key !== "last24Hours" && (
                  <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                )}
              </Tooltip>
            </span>
            <span className="text-xs opacity-30 relative">
              {data.description}
            </span>
          </div>
          {data.children && data.children.length > 0 && (
            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-gray-300 text-white px-3 text-xs rounded-t-lg cursor-pointer hover:bg-gray-800">
              {activeKeys.includes(index.toString()) ? (
                <ArrowDropUp style={{ fontSize: 20 }} />
              ) : (
                <ArrowDropDown style={{ fontSize: 20 }} />
              )}
            </span>
          )}
          <div className="flex items-center gap-2">
            <span className="float-right text-lg font-semibold text-gray-800">
              {formatNumberWithCommas(data.value)}
            </span>
            {data.percentChange ? (
              <span
                className={`w-[66px] float-right text-base ${
                  data.percentChange?.startsWith("-")
                    ? "text-gospel-error-red"
                    : "text-green-500"
                }`}
              >
                {data.percentChange?.startsWith("-")
                  ? `${data.percentChange}`
                  : `+${data.percentChange}`}
              </span>
            ) : (
              <span className="w-[66px]"></span>
            )}
          </div>
        </div>
      ),
      children:
        data.children &&
        data.children.length > 0 &&
        data.children.map((child: any, childIndex: number) => (
          <div
            key={childIndex}
            className="w-full py-6 border-b-2 border-gray-300 relative flex items-center justify-between cursor-pointer"
          >
            <div>
              <span className="text-lg font-semibold inline-block relative text-gospel-gray-400">
                {child.title}
                <Tooltip placement="top" title={child.info}>
                  <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                </Tooltip>
              </span>
              <span className="text-xs opacity-30 relative text-gospel-gray-400">
                {child.description}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="float-right text-lg font-semibold text-gospel-gray-400">
                {formatNumberWithCommas(child.value)}
              </span>
              {child.percentChange ? (
                <span
                  className={`w-[66px] float-right text-base ${
                    child.percentChange?.startsWith("-")
                      ? "text-gospel-error-red"
                      : "text-green-500"
                  }`}
                >
                  {child.percentChange?.startsWith("-")
                    ? `${child.percentChange}`
                    : `+${child.percentChange}`}
                </span>
              ) : (
                <span className="w-[66px]"></span>
              )}
            </div>
          </div>
        )),
    })
  );

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="w-full px-8 overflow-hidden block bg-black relative mt-[70px]">
        {loadPageInfo ? (
          <div className="p-28 flex items-center justify-center">
            <CircularProgress
              className="text-gospel-gray-300 font-bold z-10"
              size={30}
            />
          </div>
        ) : (
          <>
            <div className="relative mt-8 flex items-center z-1 z-10">
              <div className="absolute top-[30px] right-4 h-[40px] flex flex-row gap-2 items-center">
                {pageInfo?.watchlists && pageInfo.watchlists.length > 0 && (
                  <div className="flex flex-col">
                    <span className="text-white text-sm">{`Added to`}</span>
                    <span
                      className="text-gospel-purple text-sm cursor-pointer"
                      onClick={() => setWatchlistListModalOpen(true)}
                    >
                      {` ${pageInfo.watchlists.length} ${pageInfo.watchlists.length === 1 ? "Watchlist" : "Watchlists"}`}
                    </span>
                  </div>
                )}
                <GospelButton
                  className="text-white rounded-md text-sm font-medium transition duration-500 cursor-pointer bg-white py-2"
                  label={
                    <span className="flex flex-row px-4 items-center gap-2">
                      <RemoveRedEyeSharp className="text-gospel-gray-300" />{" "}
                      <h4 className="text-gospel-purple">Add to Watchlist</h4>
                    </span>
                  }
                  onClick={() => setWatchlistModalOpen(true)}
                />
              </div>

              <img
                src={pageInfo?.channelImage}
                className="w-52 h-52 rounded-full overflow-hidden mr-5 md:w-100 md:h-auto"
                alt="title"
              />

              <div className="flex flex-col items-start justify-start gap-3">
                <h1 className="text-2xl leading-tight font-semibold text-white">
                  {pageInfo?.title}
                </h1>
                {pageInfo?.country !== "Unknown" && (
                  <div className="flex flex-row items-center">
                    <Avatar
                      src={
                        <img
                          alt={pageInfo?.countryName}
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                            pageInfo?.countryCode?.toUpperCase() || ""
                          }.svg`}
                          className="rounded-full"
                        />
                      }
                    />

                    <span className="text-15 text-white font-medium leading-none ml-2">
                      {pageInfo?.countryName}
                    </span>
                  </div>
                )}

                <div className="gap-3 flex justify-center items-center">
                  {platforms.map((platform) => (
                    <a
                      key={platform}
                      href={
                        pageInfo?.links?.find(
                          (url) => getPlatform(url) === platform
                        ) || "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gospel-gray-400 opacity-50 hover:opacity-100"
                    >
                      {platformIcons[platform]}
                    </a>
                  ))}
                  <a
                    href={`https://www.youtube.com/channel/${pageInfo?.socialNetworks[0]?.identifier}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gospel-gray-400 opacity-50 hover:opacity-100"
                  >
                    {platformIcons["youtube"]}
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center pb-2">
              <span
                className="text-white pointer-events-none cursor-default border-b-gospel-purple pt-6 pb-1.5 mb-4 px-[20px]"
                style={{ borderBottom: "5px solid" }}
              >
                YouTube
              </span>
            </div>
          </>
        )}
      </div>
      <div className="py-10 px-4">
        {loadChannelInfo ? (
          <div className="p-8 flex items-center justify-center">
            <CircularProgress
              className="text-gospel-gray-300 font-bold z-10"
              size={30}
            />
          </div>
        ) : (
          <>
            <div className="flex flex-row justify-between">
              <div className="w-full sm:w-1/2 md:w-1/4 text-center mb-4">
                <span className="text-3xl font-bold block mb-1">
                  {formatNumberWithCommas(channelInfo?.views ?? 0)}
                </span>
                <span className="text-xs md:text-sm font-semibold text-gospel-gray-400">
                  Views
                </span>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 text-center mb-4">
                <span className="text-3xl  font-bold block mb-1">
                  {channelInfo?.subs ?? 0}
                </span>
                <span className="text-xs md:text-sm font-semibold text-gospel-gray-400">
                  Subscribers
                </span>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 text-center mb-4">
                <span className="text-3xl font-bold block mb-1">
                  {channelInfo?.videos}
                </span>
                <span className="text-xs md:text-sm font-semibold text-gospel-gray-400">
                  Uploads
                </span>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 text-center mb-4">
                <span className="text-3xl font-bold block mb-1">
                  {moment(channelInfo?.createdDate).format("MM/DD/YYYY")}
                </span>
                <span className="text-xs md:text-sm font-semibold text-gospel-gray-400">
                  Created
                </span>
              </div>
            </div>
          </>
        )}

        <div className="bg-white z-50 min-h-400px py-10 px-3 shadow-md my-4">
          <div className="flex flex-wrap justify-center items-center gap-3">
            {channelChartTabs.map((tab) => (
              <div
                className="w-full sm:w-auto md:w-auto text-center mb-4"
                key={tab.key}
              >
                <GospelButton
                  className={`h-[40px] rounded-md text-sm font-medium transition duration-500 cursor-pointer px-6 ${
                    selectedTabs.includes(tab.key)
                      ? "text-white"
                      : "text-gospel-gray-400 bg-gospel-white-100 hover:text-gospel-gray-300"
                  }`}
                  style={{
                    backgroundColor: selectedTabs.includes(tab.key)
                      ? tab.dataColor
                      : "",
                  }}
                  label={tab.label}
                  onClick={() => handleTabClick(tab.key)}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-wrap md:flex-nowrap">
              {chartInterval.map((interval) => (
                <button
                  key={interval}
                  className={`bg-white flex items-center cursor-pointer text-sm px-4 py-3 transition-colors duration-500 ease-in-out hover:text-gospel-gray-300  font-bold${
                    selectedInterval === interval
                      ? "bg-gospel-gray-300  text-gospel-gray-300"
                      : "bg-white text-gospel-gray-400"
                  }`}
                  onClick={() => handleIntervalChange(interval)}
                  onKeyUp={() => {}}
                >
                  <span
                    className={`${
                      selectedInterval === interval
                        ? "bg-gospel-purple border-gospel-purple"
                        : "bg-white border-gospel-gray-500"
                    } rounded-full inline-block w-4 h-4 mr-1 border-2  hover:text-gray-500 transition-border duration-500 ease-in-out relative`}
                  ></span>
                  {interval.charAt(0)?.toUpperCase() + interval.slice(1)}
                </button>
              ))}
            </div>

            <div className="mt-4 md:mt-0 flex flex-col md:flex-row items-center gap-4">
              <RangePicker
                className="appearance-none w-60 h-10 px-3 text-sm text-gospel-purple font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                defaultValue={[dateRange.startDate, dateRange.endDate]}
                value={[dateRange.startDate, dateRange.endDate]}
                disabledDate={(current) => {
                  // Disable dates after today
                  return current && current > dayjs().endOf("day");
                }}
                onChange={handleRangeChange}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     handleRangeChange([dateRange.startDate, dateRange.endDate]);
                //   }
                // }}
                format="MM/DD/YYYY"
              />

              <div className="relative inline-block text-left">
                <GospelSelectBox
                  options={options}
                  value={selectedValue}
                  onChange={handleSelectChange}
                  containerClass="relative max-w-[450px]"
                  className="w-60 h-10"
                  placeholder="Quick Date Ranges"
                />
              </div>
            </div>
          </div>
          <div
            className={`bg-white z-50 min-h-400 pt-6 pb-10 px-3 shadow-md relative  ${
              loadingChart ? "opacity-20" : ""
            }`}
          >
            {loadingChart && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                <CircularProgress
                  className="text-gospel-gray-300 font-bold z-10"
                  size={30}
                />
              </div>
            )}
            <HighchartsReact
              highcharts={Highcharts}
              options={chartData}
              ref={chartRef}
            />
          </div>
        </div>
        <div>
          {loadChannelData ? (
            <div className="p-8 flex items-center justify-center bg-white">
              <CircularProgress
                className="text-gospel-gray-300 font-bold z-10"
                size={30}
              />
            </div>
          ) : (
            <>
              <div className="flex bg-white shadow-md p-8 justify-between flex-row gap-8">
                {/* {Views} */}
                <div className="w-full">
                  <div className="py-5 border-b-2 border-gray-300 w-full text-center">
                    <span className="text-xl font-semibold text-center">
                      Views
                    </span>
                  </div>

                  <ConfigProvider
                    theme={{
                      components: {
                        Collapse: {
                          headerPadding: 0,
                          fontFamily: "Montserrat, sans-serif",
                        },
                      },
                    }}
                  >
                    <Collapse
                      accordion={false}
                      activeKey={activeKeys}
                      ghost={true}
                      onChange={(keys: string | string[]) => {
                        // Ensure we're dealing with an array
                        const newKeys = Array.isArray(keys) ? keys : [keys];
                        setActiveKeys(newKeys);
                      }}
                    >
                      {collapseViewItems.map((item: any) => (
                        <Collapse.Panel
                          key={item.key}
                          header={item.label}
                          showArrow={false}
                        >
                          {item.children}
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                  </ConfigProvider>
                </div>
                {/* {Subscribers} */}
                <div className="w-full">
                  <div className="py-5 border-b-2 border-gray-300 w-full text-center">
                    <span className="text-xl font-semibold text-center">
                      Subscribers
                    </span>
                  </div>

                  {channelStats.subscriberData &&
                    channelStats.subscriberData.map((data: any) => (
                      <div
                        className="w-full pt-8 border-b-2 border-gray-300 relative pb-7 flex items-center justify-between"
                        key={data.key}
                      >
                        <div>
                          <span className="text-lg font-semibold inline-block relative">
                            {data.title}
                            <Tooltip placement="top" title={data.info}>
                              {data.key !== "last24Hours" && (
                                <InfoOutlined
                                  htmlColor="#e2e3e3"
                                  className="mx-1"
                                />
                              )}
                            </Tooltip>
                          </span>
                          <span className="text-sm opacity-30 relative ">
                            {data.description}
                          </span>
                        </div>

                        <div className="flex items-center gap-2">
                          <span className="float-right text-lg font-semibold text-gray-800">
                            {formatNumberWithCommas(data.value)}
                          </span>
                          {data.percentChange ? (
                            <span
                              className={`w-[66px] float-right text-base ${
                                data.percentChange?.startsWith("-")
                                  ? "text-gospel-error-red"
                                  : "text-green-500"
                              }`}
                            >
                              {data.percentChange?.startsWith("-")
                                ? `${data.percentChange}`
                                : `+${data.percentChange}`}
                            </span>
                          ) : (
                            <span className="w-[66px]"></span>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="flex bg-white shadow-md p-8 justify-between flex-row gap-8">
                {/* {Comments and Likes} */}
                <div className="w-full">
                  <div className="py-5 border-b-2 border-gray-300 w-full text-center">
                    <span className="text-xl font-semibold text-center">
                      Comments and Likes
                    </span>
                  </div>

                  <ConfigProvider
                    theme={{
                      components: {
                        Collapse: {
                          headerPadding: 0,
                          fontFamily: "Montserrat, sans-serif",
                        },
                      },
                    }}
                  >
                    <Collapse
                      accordion={false}
                      activeKey={activeKeys}
                      ghost={true}
                      onChange={(keys: string | string[]) => {
                        // Ensure we're dealing with an array
                        const newKeys = Array.isArray(keys) ? keys : [keys];
                        setActiveKeys(newKeys);
                      }}
                    >
                      {collapseCommentsItems.map((item: any) => (
                        <Collapse.Panel
                          key={item.key}
                          header={item.label}
                          showArrow={false}
                        >
                          {item.children}
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                  </ConfigProvider>
                </div>

                {/* {Uploads} */}
                <div className="w-full">
                  <div className="py-5 border-b-2 border-gray-300 w-full text-center">
                    <span className="text-xl font-semibold text-center">
                      Uploads
                    </span>
                  </div>

                  {channelStats.uploadData &&
                    channelStats.uploadData.map((data: any) => (
                      <div
                        className="w-full pt-8 border-b-2 border-gray-300 relative pb-7 flex items-center justify-between"
                        key={data.key}
                      >
                        <div>
                          <span className="text-lg font-semibold inline-block relative">
                            {data.title}
                          </span>
                          <span className="text-sm opacity-30 relative ">
                            {data.description}
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="float-right text-lg font-semibold text-gray-800">
                            {formatNumberWithCommas(data.value)}
                          </span>
                          {data.percentChange ? (
                            <span
                              className={`w-[66px] float-right text-base ${
                                data.percentChange?.startsWith("-")
                                  ? "text-gospel-error-red"
                                  : "text-green-500"
                              }`}
                            >
                              {data.percentChange?.startsWith("-")
                                ? `${data.percentChange}`
                                : `+${data.percentChange}`}
                            </span>
                          ) : (
                            <span className="w-[66px]"></span>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* {Branded Videos} */}
      {brandVideoInfo && brandVideoInfo.length > 0 && (
        <div className="px-4 mb-12">
          <div className="flex flex-col bg-white shadow-md py-8">
            {loadChannelData ? (
              <div className="p-8 flex items-center justify-center bg-white">
                <CircularProgress
                  className="text-gospel-gray-300 font-bold z-10"
                  size={30}
                />
              </div>
            ) : (
              <>
                {/* Main Title */}
                <h1 className="text-2xl font-bold mb-4 text-center">
                  Branded Videos
                </h1>

                {/* Table Container */}
                <div className="ooverflow-x-auto flex justify-center">
                  <table className="divide-y divide-gray-200 mx-8 w-full">
                    {/* Table Head */}
                    <thead>
                      <tr className="text-left text-base text-gospel-gray-300">
                        <th className="px-4 py-2"></th>
                        <th className="px-4 py-2 font-medium">Brand</th>
                        <th className="px-4 py-2 font-medium">Views</th>
                        <th className="px-4 py-2 font-medium">Comments</th>
                        <th className="px-4 py-2 font-medium">Likes</th>
                      </tr>
                    </thead>
                    {/* Table Body */}
                    <tbody>
                      {brandVideoInfo
                        .slice(0, showBrandedVideos ? brandVideoInfo.length : 5)
                        .map((item, index) => {
                          return (
                            <tr
                              className={`text-left text-sm text-gray-800  ${
                                index % 2 === 0
                                  ? "bg-gospel-white-100"
                                  : "bg-transparent"
                              }`}
                              key={item.videoId}
                            >
                              <td className="px-4 py-2 flex flex-row gap-4 items-center">
                                <button
                                  className="bg-gray-200 flex items-center justify-center"
                                  onClick={() =>
                                    openBrandedVideoModal(item.videoId)
                                  }
                                >
                                  <img
                                    src={item.thumbnailURL}
                                    alt="Video Thumbnail"
                                    className="max-w-24 h-14 cursor-pointer"
                                  />
                                  <PlayArrow
                                    htmlColor="#fff"
                                    className="absolute cursor-pointer"
                                  />
                                </button>
                                <div className="flex flex-col">
                                  <h3 className="font-medium text-gospel-gray-300">
                                    {item.title}
                                  </h3>
                                  <div className="flex flex-row">
                                    <p className="text-sm text-gospel-gray-200 ">
                                      {item.uploadDate}
                                    </p>
                                    <p className="mx-1 text-sm text-gospel-gray-200 ">
                                      |{" "}
                                    </p>
                                    <p className="text-sm text-gospel-gray-200 ">
                                      {moment(item.uploadTimestamp).format(
                                        "MM/DD/YYY, HH:MM"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </td>

                              {/* Brand */}
                              <td className="px-4 py-2">
                                <a
                                  href={`/brand/${item.brandSlug}`}
                                  className="text-gospel-purple hover:underline text-base"
                                >
                                  {item.sponsor?.length
                                    ? item.sponsor.join(", ")
                                    : ""}
                                </a>
                              </td>
                              {/* Views */}
                              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                                {formatNumberWithCommas(item.viewCount)}
                              </td>
                              {/* Comments */}
                              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                                {formatNumberWithCommas(item.commentCount)}
                              </td>
                              {/* Likes */}
                              <td className="px-4 py-2 text-gospel-gray-200 text-base">
                                {formatNumberWithCommas(item.likeCount)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {brandVideoInfo?.length > 2 && (
                  <GospelButton
                    className="bg-white px-2 self-center my-2 py-2 w-36 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                    label={showBrandedVideos ? "Show Less" : "View More"}
                    onClick={toggleShowBrandedVideos}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 18,
              headerBg: "transparent",
              contentBg: "",
              titleColor: "#353b3d",
              boxShadow: "none",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title=""
          open={isUploadedYoutubeOpen}
          width={"fit-content"}
          footer={null}
          closeIcon={null}
        >
          <div className="relative">
            {/* Close Button */}
            <Cancel
              className="overlayCloseButton"
              fontSize="large"
              onClick={closeChartModal}
            />

            {/* Video and List Wrapper */}
            <div className="videoListWrapper">
              {/* Video Player */}
              <div className="videoHolder">
                <div className="fluid-width-video-wrapper">
                  <ReactPlayer
                    url={`https://www.youtube.com/embed/${uploadedVideoId}`}
                    controls
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>

              {/* Video List */}
              <div className="videoListScroll">
                {uploadedVideos.map((video: VideoData) => (
                  <button
                    key={video.videoId}
                    className={`videoWrapper items-start flex flex-row w-full ${
                      video.videoId === uploadedVideoId ? "selected" : ""
                    }`}
                    onClick={() => setUploadedVideoId(video.videoId)}
                  >
                    <div className="left flex items-center justify-center cursor-pointer">
                      <img
                        src={video.thumbnailURL}
                        alt="Video Thumbnail"
                        className="max-w-24 h-14"
                      />
                      <PlayArrow htmlColor="#fff" className="absolute" />
                    </div>
                    <div className="right flex flex-col items-start gap-1">
                      <span className="videoTitle">{video.title}</span>
                      <span className="videoInfo ">
                        {formatNumberWithCommas(video.viewCount)} Views
                      </span>
                      <span className="videoInfo ">
                        {moment
                          .utc(video.datePublished)
                          .format("MM/DD/YYYY, h:mm:ss a")}
                      </span>
                      <span className="videoInfo">
                        {video.duration} Duration
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      </ConfigProvider>

      {/* { Demographic Data} */}
      <div className="px-4 mb-12">
        <div className="flex flex-col bg-white shadow-md py-8">
          {loadDemographicData ? (
            <div className="p-8 flex items-center justify-center bg-white">
              <CircularProgress
                className="text-gospel-gray-300 font-bold z-10"
                size={30}
              />
            </div>
          ) : demographicData && (demographicData as any).message  ? (
            <div className="text-black text-lg px-3 py-2 rounded flex items-center justify-center">
              We aren't tracking demographic data for this channel right now, if
              you think we should please
              <button
        className="text-gospel-purple font-semibold underline ml-1"
        onClick={handleFeatureRequest}
      >
        contact us
      </button>
              {"."}
            </div>
          ) : (
            <ConfigProvider
              theme={{
                components: {
                  Collapse: {
                    headerPadding: 0,
                    fontFamily: "Montserrat, sans-serif",
                  },
                },
              }}
            >
              <Collapse
                items={demographicItems}
                ghost
                expandIcon={() => null}
                defaultActiveKey={["1"]}
                activeKey={demographicActiveKey}
                onChange={handleDemographicToggle}
              />
            </ConfigProvider>
          )}
        </div>
      </div>

      {/* {Similar Channels Table} */}
      <div className="px-4 mb-12">
        <div className="flex flex-col bg-white shadow-md py-8">
          {loadSimilarChannels ? (
            <div className="p-8 flex items-center justify-center bg-white text-lg text-black">
              <p>
                We're currently pulling Similar Channels. Come back here in a
                couple minutes and we'll have some for you.
              </p>
            </div>
          ) : (
            <ConfigProvider
              theme={{
                components: {
                  Collapse: {
                    headerPadding: 0,
                    fontFamily: "Montserrat, sans-serif",
                  },
                },
              }}
            >
              <Collapse
                items={similarChannelsItems}
                ghost
                expandIcon={() => null}
                defaultActiveKey={["1"]}
                activeKey={similarChannelsActiveKey}
                onChange={handleSimilarChannelsToggle}
              />
            </ConfigProvider>
          )}
        </div>
      </div>

      {/* {Most recent Videos} */}
      <div className="px-4 mb-12">
        <div className="flex flex-col bg-white shadow-md py-8 items-center">
          {loadChannelData ? (
            <div className="p-8 flex items-center justify-center bg-white">
              <CircularProgress
                className="text-gospel-gray-300 font-bold z-10"
                size={30}
              />
            </div>
          ) : (
            <>
              {/* Tabs */}
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      inkBarColor: "#9D63B0",
                      itemSelectedColor: "#353b3d",
                      itemHoverColor: "#353b3d",
                      itemColor: "#adafaf",
                      titleFontSize: 16,
                      fontFamily: "Montserrat",
                    },
                  },
                }}
              >
                <Tabs
                  defaultActiveKey="mostViewed"
                  onChange={(key) => setActiveTab(key as TrendingVideoKey)}
                  items={[
                    { key: "mostViewed", label: "Top 50 Most Viewed Videos" },
                    { key: "mostRecent", label: "Top 50 Most Recent Videos" },
                  ]}
                />
              </ConfigProvider>
              <div className="overflow-x-auto flex justify-center w-full">
                <TrendingVideoTable
                  data={trendingVideoInfo[activeTab]}
                  showTrendingVideos={showTrendingVideos}
                />
              </div>
              {trendingVideoInfo[activeTab]?.length > 2 && (
                <GospelButton
                  className="bg-white px-2 self-center mt-6 py-2 w-28 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                  label={showTrendingVideos ? "Show Less" : "View More"}
                  onClick={() => setShowTrendingVideos(!showTrendingVideos)}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
      {selectedVideoId && (
        <ConfigProvider
          theme={{
            components: {
              Modal: {
                titleFontSize: 18,
                headerBg: "transparent",
                contentBg: "",
                titleColor: "#353b3d",
                boxShadow: "none",
                fontFamily: "Montserrat, sans-serif",
              },
            },
          }}
        >
          <Modal
            title=""
            open={isYoutubeOpen}
            width={"fit-content"}
            height={"fit-content"}
            footer={null}
            closeIcon={
              <Cancel
                className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                fontSize="large"
                onClick={closeBrandedVideoModal}
              />
            }
            className="text-center"
          >
            <ReactPlayer
              controls
              width="750px"
              height={"450px"}
              url={`https://youtube.com/watch?v=${selectedVideoId}`}
            />
          </Modal>
        </ConfigProvider>
      )}

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 22,
              titleColor: "#353b3d",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title="Watchlists"
          open={watchlistModalOpen}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => setWatchlistModalOpen(false)}
            />
          }
          className="text-center"
        >
          <div className="flex flex-col items-start">
            <p className=" text-gospel-gray-300 text-base mb-6">
              Select a list below
            </p>
            <div className="flex flex-col w-full gap-4 h-52 overflow-y-scroll">
              {watchList.map((item) => {
                return (
                  <div
                    className="bg-gospel-white-100 hover:bg-gospel-gray-100 text-gospel-purple hover:text-black py-3 rounded-md cursor-pointer"
                    key={item.id}
                    onClick={() =>
                      addToWatchlist(item.id, channelInfo?.channelId ?? "")
                    }
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            <div className="mt-7 pt-7 w-full flex flex-col border-t border-gospel-gray-400 ">
              <GospelInput
                type="text"
                onChange={(e) => {
                  setNewWatchlistName(e.target.value);
                  setWatchlistNameError(""); // Clear error when input changes
                }}
                value={newWatchlistName}
                placeholder="Create new list"
                className="w-full h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
              />
              {watchlistNameError && (
                <div className="text-gospel-error-red mb-2 text-sm bg-gospel-red-100 px-3 mt-2 py-2 rounded">
                  {watchlistNameError}
                </div>
              )}
              <div className="flex flex-row items-baseline gap-4">
                <GospelButton
                  onClick={createNewWatchlist}
                  label={
                    loading ? (
                      <CircularProgress
                        className="text-gospel-gray-300 font-bold"
                        size={14}
                      />
                    ) : (
                      "Create"
                    )
                  }
                  disabled={!newWatchlistName.trim()}
                  className="bg-white w-28 py-2.5 mt-2 mb-8 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                />
                <span>
                  {addWatchlistloading && (
                    <CircularProgress
                      className="text-gospel-gray-300 font-bold"
                      size={14}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </ConfigProvider>

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 22,
              titleColor: "#353b3d",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title="Watchlists"
          open={watchlistListModalOpen}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => setWatchlistListModalOpen(false)}
            />
          }
          className="text-center"
        >
          <div className="flex flex-col items-start">
            <div className="py-4 flex flex-col w-full gap-4 ">
              {pageInfo?.watchlists?.map((item) => {
                return (
                  <a
                    className="cursor-pointer"
                    href={`/account/watchlists/list/${item.watchlistId}`}
                    key={item.watchlistId}
                  >
                    <div
                      className="bg-gospel-white-100 hover:bg-gospel-gray-100 text-gospel-purple hover:text-black py-3 rounded-md cursor-pointer"
                      key={item.watchlistId}
                    >
                      {item.title}
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  );
}

export default Channel;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DiscoverStats } from "../../components/Discover/DiscoverUtils";

interface DiscoverState {
  criteria: any;
  results: DiscoverStats | null;
}

const initialState: DiscoverState = {
  criteria: null,
  results: null,
};

const discoverSlice = createSlice({
  name: "discover",
  initialState,
  reducers: {
    setSearchCriteria: (state, action: PayloadAction<any>) => {
      state.criteria = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<DiscoverStats | null>) => {
      state.results = action.payload;
    },
    clearSearchData: (state) => {
      state.criteria = null;
      state.results = null;
    },
  },
});

export const { setSearchCriteria, setSearchResults, clearSearchData } = discoverSlice.actions;
export default discoverSlice.reducer;

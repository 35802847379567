import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GospelButton from "../../common/GospelButton/GospelButton";
import GospelInput from "../../common/GospelInput/GospelInput";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import { CircularProgress } from "@mui/material";
import axios from "axios";

const images = [
  "https://img.gospelstats.com/static/home_image.jpg",
  "https://img.gospelstats.com/static/home_image2.jpg",
  "https://img.gospelstats.com/static/home_image3.jpg",
  "https://img.gospelstats.com/static/home_image4.jpg",
  "https://img.gospelstats.com/static/home_image5.jpg",
  "https://img.gospelstats.com/static/home_image6.jpg",
  "https://img.gospelstats.com/static/home_image7.jpg",
];

interface TokenData {
  token: string;
  email: string;
  level: number;
  expires: number;
}

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [tokenData, setTokenData] = useState<TokenData>({} as TokenData);
  const [backgroundImage, setBackgroundImage] = useState("");

  const location = useLocation();

  // Extract token from URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("t");

  useEffect(() => {
    // Select a random background image
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setBackgroundImage(randomImage);

    // If token exists, confirm it and get JWT
    if (token) {
      confirmToken(token);
    } else {
      setError("Invalid or missing password reset token.");
    }
  }, []);

  const confirmToken = async (resetToken: string) => {
    try {
      setLoading(true);

      // Use URLSearchParams to send form-encoded data
      const formData = new URLSearchParams();
      formData.append("token", resetToken);

      const response = await axios.post(
        "https://api.gospelstats.com/v1/gospel/user/pwconfirm",
        formData,
        {
          headers: {
            "x-api-key": "3Rv6mxgKrK7LB9wzhdUaV28YpKbjY4363WPUr3pg",
            "Content-Type": "application/x-www-form-urlencoded", // Required for form data
          },
        }
      );
      if (response.data) {
        setTokenData(response.data);
      } else {
        setError("Invalid or expired reset token.");
      }
    } catch (err) {
      setError("Failed to validate the token. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to handle password change
  const handlePasswordChange = async () => {
    if (!newPassword || !confirmPassword) {
      setError("Please enter both password fields.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!tokenData?.token) {
      setError(
        "Authorization token missing. Try resetting your password again."
      );
      return;
    }
    const formData = new URLSearchParams();
    formData.append("email", tokenData.email);
    formData.append("pass", newPassword);

    try {
      setLoading(true);
      const response: any = await axios.post(
        "https://api.gospelstats.com/v1/gospel/user/changepw",
        formData,
        {
          headers: {
            "x-api-key": "3Rv6mxgKrK7LB9wzhdUaV28YpKbjY4363WPUr3pg",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: tokenData.token,
          },
        }
      );

      if (response.data) {
        setSuccessMessage(true);
      } else {
        setError("Password reset failed. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header isTabBarVisible={false} />
      <div
        className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="w-[calc(100%-40px)] max-w-[350px] text-center z-2">
          <div className="absolute inset-0 bg-black opacity-65"></div>
          <div className="relative z-10 w-full max-w-md text-center bg-white p-6 rounded-lg shadow-lg">
            <img
              src="https://img.gospelstats.com/static/icon_large.png"
              alt="Logo"
              className="w-24 mx-auto -mt-16 mb-4"
            />

            {error && (
              <div className="text-gospel-error-red mb-6 text-sm bg-gospel-red-100 px-3 py-2 rounded">
                {error}
              </div>
            )}

            <GospelInput
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="block w-full my-6 max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />

            <GospelInput
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="block w-full my-6 max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />

            {successMessage ? (
              <div className="text-gospel-green text-sm bg-gospel-success-green px-6 my-2 py-4 rounded w-full text-center">
                Your password has been changed.{" "}
                <a href="/login" className=" underline">
                  Click here{" "}
                </a>
                to login.
              </div>
            ) : (
              <GospelButton
                className="self-center text-white h-[40px] w-full p-2 rounded-md text-sm font-medium transition duration-500 cursor-pointer bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple hover:bg-gospel-purple"
                label={
                  loading ? (
                    <CircularProgress
                      className="self-center justify-center text-gospel-gray-100 font-bold"
                      size={16}
                    />
                  ) : (
                    "Save Password"
                  )
                }
                onClick={handlePasswordChange}
                disabled={!newPassword || !confirmPassword || loading}
              />
            )}
          </div>
        </div>
      </div>
      <Footer isPolicyVisible={false} />
    </>
  );
};

export default ResetPassword;

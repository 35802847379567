import React, { useEffect, useState } from "react";
import GospelButton from "../../common/GospelButton/GospelButton";
import { get, post } from "../../utility/api";
import { ReportData, ReportItem } from "../../types/report";
import { CircularProgress, Snackbar } from "@mui/material";
import { ConfigProvider, Modal } from "antd";
import { Cancel } from "@mui/icons-material";
import GospelInput from "../../common/GospelInput/GospelInput";
import CreateReport from "./CreateReport";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import * as animationData from "../../assets/animations/sucess.json";

const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Reports = () => {
  const [reportDetails, setReportDetails] = useState<ReportData>(
    {} as ReportData
  );
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [showCreateReport, setShowCreateReport] = useState<boolean>(false);

  const [reportName, setReportName] = useState("");
  const [updateReportLoading, setUpdateReportLoading] =
    useState<boolean>(false);
  const [reportModal, setReportModal] = useState(false);
  const [reportModalCategory, setReportModalCategory] = useState("");
  const [selectedReport, setSelectedReport] = useState<ReportItem | null>(null);

  const [editReportData, setEditReportData] = useState<ReportItem | null>(null);

  const fetchReports = async () => {
    try {
      setReportLoading(true);
      const result = await get<ReportData>("/gospel/report/list");
      if (result) {
        const sortedReports = result.reports.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setReportDetails({ ...result, reports: sortedReports });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setReportLoading(false);
    }
  };

  const handleUpdateReport = async (
    data: URLSearchParams,
    successCallback: () => void
  ) => {
    try {
      setUpdateReportLoading(true);
      const result = await post("/gospel/report/update", data);
      if (result) successCallback();
    } catch (error) {
      console.error("Error updating report:", error);
    } finally {
      setUpdateReportLoading(false);
    }
  };

  const updateFrequency = (id: number, frequency: number) => {
    const data = new URLSearchParams();
    data.append("id", id.toString());
    data.append("frequency", frequency.toString());

    handleUpdateReport(data, () => {
      setReportDetails((prevState) => ({
        ...prevState,
        reports: prevState.reports.map((report) =>
          report.id === id ? { ...report, frequency } : report
        ),
      }));
    });
  };

  const renameReport = () => {
    if (!selectedReport) return;

    const data = new URLSearchParams();
    data.append("id", selectedReport.id.toString());
    data.append("name", reportName);

    handleUpdateReport(data, () => {
      setReportDetails((prevState) => ({
        ...prevState,
        reports: prevState.reports.map((report) =>
          report.id === selectedReport.id
            ? { ...report, name: reportName }
            : report
        ),
      }));
      closeModal();
    });
  };

  const duplicateReport = async () => {
    if (!selectedReport) return;

    try {
      setUpdateReportLoading(true);
      const result = await get(
        `/gospel/report/clone?id=${selectedReport.id}&name=${reportName}`
      );

      if (result) fetchReports();
    } catch (error) {
      console.error("Error duplicating report:", error);
      setUpdateReportLoading(false);
    } finally {
      closeModal();
      setUpdateReportLoading(false);
    }
  };

  const deleteReport = async () => {
    if (!selectedReport) return;

    try {
      setUpdateReportLoading(true);
      const result = await get(`/gospel/report/delete?id=${selectedReport.id}`);
      if (result) {
        setReportDetails((prevState) => ({
          ...prevState,
          reports: prevState.reports.filter(
            (report) => report.id !== selectedReport.id
          ),
        }));
      }
    } catch (error) {
      console.error(
        `Error deleting report with ID ${selectedReport.id}:`,
        error
      );
      setUpdateReportLoading(false);
    } finally {
      closeModal();
      setUpdateReportLoading(false);
    }
  };

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const regenerateReport = async (reportId: number) => {
    try {
      setUpdateReportLoading(true);
      const result = await get(`/gospel/report/rerun?id=${reportId}`);
  
      if (result) {
        await delay(5000);
        fetchReports();
      }
    } catch (error) {
      console.error(`Error re-generating report with ID ${reportId}:`, error);
    } finally {
      setUpdateReportLoading(false);
    }
  };

  const downloadReport = (reportId: number, shortURL: string) => {
    try {
      // Create an invisible anchor element
      const link = document.createElement("a");
      link.href = shortURL; // Use the short URL that triggers the download
      link.download = ""; // Let the server provide the file name
      document.body.appendChild(link); // Append to the DOM
      link.click(); // Trigger the click event
      document.body.removeChild(link); // Remove the element after triggering
    } catch (error) {
      console.error(`Error downloading report with ID ${reportId}:`, error);
    }
  };

  const openModal = (category: string, report: ReportItem | null = null) => {
    setReportModalCategory(category);
    setSelectedReport(report);
    setReportModal(true);
  };

  const closeModal = () => {
    setReportModal(false);
    setReportName("");
    setSelectedReport(null);
  };

  useEffect(() => {
    fetchReports();
  }, [showCreateReport]);

  return (
    <>
      {!showCreateReport ? (
        <div className="p-5">
          {reportLoading ? (
            <div className="flex items-center justify-center">
              <CircularProgress
                className="text-gospel-purple-200 font-bold"
                size={30}
              />
            </div>
          ) : (
            <>
              <QuotaInfo reportDetails={reportDetails} />
              <GospelButton
                className="bg-white p-3 my-6 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer inline-block font-medium hover:text-gray-700 hover:border-gray-400"
                label="Create New Report"
                onClick={() => {
                  setShowCreateReport(true);
                  setEditReportData(null);
                }}
              />
              {reportDetails?.reports?.length > 0 ? (
                <div className="pb-5">
                  <ReportSection
                    title="Channels"
                    reports={reportDetails.reports.filter(
                      (report) => report.sourceType === "channel"
                    )}
                    openModal={openModal}
                    updateFrequency={updateFrequency}
                    regenerateReport={regenerateReport}
                    downloadReport={downloadReport}
                    setShowCreateReport={setShowCreateReport}
                    setEditReportData={setEditReportData}
                  />

                  <ReportSection
                    title="Charts"
                    reports={reportDetails.reports.filter(
                      (report) => report.sourceType === "chart"
                    )}
                    openModal={openModal}
                    updateFrequency={updateFrequency}
                    regenerateReport={regenerateReport}
                    downloadReport={downloadReport}
                    setShowCreateReport={setShowCreateReport}
                    setEditReportData={setEditReportData}
                  />

                  <ReportSection
                    title="Channel Milestone"
                    reports={reportDetails.reports.filter(
                      (report) => report.sourceType === "channelMilestone"
                    )}
                    openModal={openModal}
                    updateFrequency={updateFrequency}
                    regenerateReport={regenerateReport}
                    downloadReport={downloadReport}
                    setShowCreateReport={setShowCreateReport}
                    setEditReportData={setEditReportData}
                  />

                  <ReportSection
                    title="Deep Video Searches"
                    reports={reportDetails.reports.filter(
                      (report) => report.sourceType === "video"
                    )}
                    openModal={openModal}
                    updateFrequency={updateFrequency}
                    regenerateReport={regenerateReport}
                    downloadReport={downloadReport}
                    setShowCreateReport={setShowCreateReport}
                    setEditReportData={setEditReportData}
                  />
                  <ReportSection
                    title="Video Milestone"
                    reports={reportDetails.reports.filter(
                      (report) => report.sourceType === "videoMilestone"
                    )}
                    openModal={openModal}
                    updateFrequency={updateFrequency}
                    regenerateReport={regenerateReport}
                    downloadReport={downloadReport}
                    setShowCreateReport={setShowCreateReport}
                    setEditReportData={setEditReportData}
                  />

                  <ReportSection
                    title="Watchlists"
                    reports={reportDetails.reports.filter(
                      (report) => report.sourceType === "watchlist"
                    )}
                    openModal={openModal}
                    updateFrequency={updateFrequency}
                    regenerateReport={regenerateReport}
                    downloadReport={downloadReport}
                    setShowCreateReport={setShowCreateReport}
                    setEditReportData={setEditReportData}
                  />
                </div>
              ) : (
                <p className="block text-sm font-normal mt-6">
                  You currently have no reports set up.
                </p>
              )}
            </>
          )}
        </div>
      ) : (
        <CreateReport
          setShowCreateReport={setShowCreateReport}
          reportMaxRowsAllowed={reportDetails?.reportMaxRowsAllowed}
          editReportData={editReportData}
          setEditReportData={setEditReportData}
        />
      )}
      <ReportModal
        isOpen={reportModal}
        closeModal={closeModal}
        reportModalCategory={reportModalCategory}
        reportName={reportName}
        setReportName={setReportName}
        renameReport={renameReport}
        duplicateReport={duplicateReport}
        deleteReport={deleteReport}
        updateReportLoading={updateReportLoading}
      />
    </>
  );
};

const QuotaInfo = ({ reportDetails }: { reportDetails: ReportData }) => (
  <div className="mt-2 pb-6 border-b border-gray-300">
    <span className="block text-lg font-normal mb-3">
      <strong>Reports:</strong>{" "}
      {`${reportDetails?.reportQuotaLeft} out of ${reportDetails?.reportQuotaTotal} available.`}
    </span>
    <span className="block text-lg font-normal mb-4">
      <strong>Rows:</strong>{" "}
      {`${reportDetails?.rowQuotaLeft} out of ${reportDetails?.rowQuotaTotal} available.`}
    </span>
    <span className="block text-xs font-normal">
      {`Your quotas will reset on ${reportDetails?.reportQuotaReset}`}
    </span>
  </div>
);

const ReportSection = ({
  title,
  reports,
  openModal,
  updateFrequency,
  regenerateReport,
  downloadReport,
  setShowCreateReport,
  setEditReportData,
}: {
  title: string;
  reports: ReportItem[];
  openModal: (category: string, report: ReportItem) => void;
  updateFrequency: (id: number, frequency: number) => void;
  regenerateReport: (reportId: number) => void;
  downloadReport: (reportId: number, shortURL: string) => void;
  setShowCreateReport: React.Dispatch<React.SetStateAction<boolean>>;
  setEditReportData: React.Dispatch<React.SetStateAction<ReportItem | null>>;
}) => (
  <>
    {reports.length > 0 && (
      <>
        <h4 className="mb-2 text-xl">{title}</h4>
        {reports.map((item) => (
          <ReportItemRow
            key={item.id}
            report={item}
            openModal={openModal}
            updateFrequency={updateFrequency}
            regenerateReport={regenerateReport}
            downloadReport={downloadReport}
            setShowCreateReport={setShowCreateReport}
            setEditReportData={setEditReportData}
          />
        ))}
      </>
    )}
  </>
);

const ReportItemRow = ({
  report,
  openModal,
  updateFrequency,
  regenerateReport,
  downloadReport,
  setShowCreateReport,
  setEditReportData,
}: {
  report: ReportItem;
  openModal: (category: string, report: ReportItem) => void;
  updateFrequency: (id: number, frequency: number) => void;
  regenerateReport: (reportId: number) => void;
  downloadReport: (reportId: number, shortURL: string) => void;
  setShowCreateReport: React.Dispatch<React.SetStateAction<boolean>>;
  setEditReportData: React.Dispatch<React.SetStateAction<ReportItem | null>>;
}) => {
  const navigate = useNavigate();
  const handleReportClick = (report: ReportItem) => {
    if (report.sourceType === "watchlist") {
      navigate(`/account/watchlists/list/${report.sourceId}`);
    } else if (report.sourceType === "chart") {
      navigate("/charts");
    } else {
      setEditReportData(report);
      setShowCreateReport(true);
    }
  };

  return (
    <div className="flex flex-row justify-between py-5 border-t border-gospel-gray-100">
      <div className="flex flex-col">
        <span className="block mb-1 w-full">
          <button
            className="text-gospel-purple cursor-pointer text-base transition-colors duration-500 ease-in-out hover:text-black"
            onClick={() => handleReportClick(report)}
          >
            {report.name}
          </button>
          <span className="flex flex-row gap-1 my-2 text-sm font-normal">
            <strong className="font-medium">Next report:</strong>
            <span>{report.dateNextRun}</span>
          </span>
          <span className="flex flex-row gap-1 mb-2 text-sm font-normal">
            <strong className="font-medium">Report last downloaded:</strong>
            <span>{report.results.dateDownloaded}</span>
          </span>
          <span className="flex flex-row gap-1 mb-2 text-sm font-normal">
            <strong className="font-medium">Report last generated:</strong>
            <span>{report.results.dateCreated}</span>
          </span>
          <span className="flex flex-row gap-1 text-sm font-normal">
            <strong className="font-medium">Row count:</strong>
            <span>{report.results.rowCount}</span>
          </span>
        </span>
        <ActionButtons
          openModal={openModal}
          report={report}
          regenerateReport={() => regenerateReport(report.id)}
          downloadReport={() =>
            downloadReport(report.id, report.results.shortURL)
          }
        />
      </div>
      <FrequencySelector report={report} updateFrequency={updateFrequency} />
    </div>
  );
};

const ActionButtons = ({
  openModal,
  report,
  regenerateReport,
  downloadReport,
}: {
  openModal: (category: string, report: ReportItem) => void;
  report: ReportItem;
  regenerateReport: () => void;
  downloadReport: () => void;
}) => {
  const [createReportSuccessModal, setCreateReportSuccessModal] =
    useState<boolean>(false);
  return (
    <div className="flex flex-row">
      {report.dateNextRun !== "Currently processing" && (
        <button
          className="text-xs cursor-pointer text-gray-400 mr-2 transition-colors duration-500 ease-in-out hover:text-black"
          onClick={() => {
            regenerateReport();
            setCreateReportSuccessModal(true);
          }}
        >
          Re-generate
        </button>
      )}
      {report.results.shortURL && (
        <button
          className="text-xs cursor-pointer text-gray-400 mr-2 transition-colors duration-500 ease-in-out hover:text-black"
          onClick={() => downloadReport()}
        >
          Download
        </button>
      )}
      <button
        className="text-xs cursor-pointer text-gray-400 mr-2 transition-colors duration-500 ease-in-out hover:text-black"
        onClick={() => openModal("duplicate", report)}
      >
        Duplicate
      </button>
      <button
        className="text-xs cursor-pointer text-gray-400 mr-2 transition-colors duration-500 ease-in-out hover:text-black"
        onClick={() => openModal("rename", report)}
      >
        Re-name
      </button>
      <button
        className="text-xs cursor-pointer text-gray-400 transition-colors duration-500 ease-in-out hover:text-black"
        onClick={() => openModal("delete", report)}
      >
        Delete
      </button>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 18,
              titleColor: "#353b3d",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title="Your Report is on the Way! 📬"
          open={createReportSuccessModal}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => setCreateReportSuccessModal(false)}
            />
          }
          className="text-center"
        >
          <Lottie options={defaultAnimationOptions} height={250} width={250} />
          <div className="mt-4 mb-6">
            <p className="text-gospel-purple">
              Your report is being re-generated and will be sent to your email
              shortly!!
            </p>
            <p className="text-gospel-purple ">
              Please check your inbox in a few minutes.
            </p>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  );
};

const FrequencySelector = ({
  report,
  updateFrequency,
}: {
  report: ReportItem;
  updateFrequency: (id: number, frequency: number) => void;
}) => (
  <div className="flex flex-col justify-center">
    <div className="flex items-center bg-gospel-gray-100 rounded-md overflow-hidden shadow-inner">
      {[
        { label: "One-Time", value: 0 },
        { label: "Weekly", value: 7 },
        { label: "Monthly", value: 30 },
      ].map(({ label, value }) => (
        <button
          key={value}
          className={`text-xs p-2 w-[80px] text-center cursor-pointer transition-colors ease-in-out duration-500 ${
            report.frequency === value
              ? "bg-gospel-purple text-white"
              : "text-gray-400 hover:text-gray-900"
          }`}
          onClick={() => updateFrequency(report.id, value)}
        >
          {label}
        </button>
      ))}
    </div>
  </div>
);

const ReportModal = ({
  isOpen,
  closeModal,
  reportModalCategory,
  reportName,
  setReportName,
  renameReport,
  duplicateReport,
  deleteReport,
  updateReportLoading,
}: {
  isOpen: boolean;
  closeModal: () => void;
  reportModalCategory: string;
  reportName: string;
  setReportName: React.Dispatch<React.SetStateAction<string>>;
  renameReport: () => void;
  duplicateReport: () => void;
  deleteReport: () => void;
  updateReportLoading: boolean;
}) => (
  <ConfigProvider
    theme={{
      components: {
        Modal: {
          titleFontSize: 22,
          titleColor: "#353b3d",
          fontFamily: "Montserrat, sans-serif",
        },
      },
    }}
  >
    <Modal
      title=""
      open={isOpen}
      footer={null}
      closeIcon={
        <Cancel htmlColor="#000" fontSize="medium" onClick={closeModal} />
      }
      className="text-center"
    >
      <div className="flex flex-col items-center">
        {reportModalCategory === "delete" && (
          <ConfirmationModalContent
            onCancel={closeModal}
            onConfirm={deleteReport}
            updateReportLoading={updateReportLoading}
          />
        )}
        {["rename", "duplicate"].includes(reportModalCategory) && (
          <TextInputModalContent
            onCancel={closeModal}
            onSave={
              reportModalCategory === "rename" ? renameReport : duplicateReport
            }
            reportName={reportName}
            setReportName={setReportName}
            updateReportLoading={updateReportLoading}
            label={reportModalCategory === "rename" ? "Save" : "Create Report"}
            reportModalCategory={reportModalCategory}
          />
        )}
      </div>
    </Modal>
  </ConfigProvider>
);

const ConfirmationModalContent = ({
  onCancel,
  onConfirm,
  updateReportLoading,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  updateReportLoading: boolean;
}) => (
  <>
    <p className="text-gospel-gray-300 text-base mt-8">
      Are you sure you want to delete this report?
    </p>
    <div className="my-4 w-full flex flex-row gap-2 items-center justify-center">
      <GospelButton
        onClick={onCancel}
        label="No"
        className="bg-white w-16 py-2 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple"
      />
      <GospelButton
        className="text-white w-16 py-2 rounded-md text-sm font-medium transition duration-500 cursor-pointer bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple"
        label={
          updateReportLoading ? (
            <CircularProgress
              className="text-gospel-white-100 font-bold"
              size={14}
            />
          ) : (
            "Yes"
          )
        }
        onClick={onConfirm}
      />
    </div>
  </>
);

const TextInputModalContent = ({
  onCancel,
  onSave,
  reportName,
  setReportName,
  updateReportLoading,
  label,
  reportModalCategory,
}: {
  onCancel: () => void;
  onSave: () => void;
  reportName: string;
  setReportName: React.Dispatch<React.SetStateAction<string>>;
  updateReportLoading: boolean;
  label: string;
  reportModalCategory: string;
}) => (
  <>
    <p className="text-gospel-gray-300 text-base mt-8">
      Please provide a new name for your report.
    </p>
    <GospelInput
      type="text"
      onChange={(e) => setReportName(e.target.value)}
      value={reportName}
      placeholder="Enter Name"
      className="w-full my-4 h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400"
    />
    <div className="my-4 w-full flex flex-row gap-2 items-center justify-center">
      <GospelButton
        onClick={onCancel}
        label="Cancel"
        className="bg-white w-20 py-2 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple"
      />
      <GospelButton
        className={`text-white py-2 rounded-md text-sm font-medium transition duration-500 cursor-pointer bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple`}
        label={
          updateReportLoading ? (
            <CircularProgress
              className="text-gospel-white-100 font-bold"
              size={14}
            />
          ) : (
            label
          )
        }
        onClick={onSave}
        style={{
          width: reportModalCategory === "duplicate" ? 120 : 80,
        }}
      />
    </div>
  </>
);

export default Reports;

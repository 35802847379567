import React, { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor, RootState } from "./redux/store";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import ProtectedRoute from "./route/ProtectedRoute";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import Charts from "./components/Charts/Charts";
import Discover from "./components/Discover/Discover";
import Compare from "./components/Compare/Compare";
import Search from "./components/Search/Search";
import Account from "./components/Account/Account";
import Channel from "./components/Channel/Channel";
import Brand from "./components/Brand/Brand";
import ForgotPassword from "./components/Login/ForgotPassword";
import Category from "./components/Brand/Category";
import WatchlistDetails from "./components/Account/WatchlistDetails";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ResetPassword from "./components/Login/ResetPassword";
import { UserbackProvider, useUserback } from "@userback/react";
import withErrorBoundary from "./withErrorBoundary";


function MetaRedirect() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state: RootState) => state.user.user?.token);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const resetToken = queryParams.get("t");

    if (resetToken) {
      navigate(`/reset-password?t=${resetToken}`);
      return;
    }

    if (token) {
      navigate("/dashboard");
    } else {
      window.location.href = "https://get.gospelstats.com";
    }
  }, [token, location, navigate]);

  return null;
}

function FeedbackWidget() {
  const userback = useUserback();

  useEffect(() => {
    if (userback) {
      setTimeout(() => {
        userback.show();
      }, 500);
    }
  }, [userback]);

  return null;
}

function App() {
  const userDetails = useSelector((state: RootState) => state.user.userDetails);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <Helmet>
            <meta
              name="description"
              content="Gospel Stats: Seamlessly discover and analyze thousands of brand sponsorships across YouTube"
            />
          </Helmet>
          <UserbackProvider
            token="A-NtWWEn6yT3oVwQRp8ZzQRangQ"
            options={{ email: userDetails?.email, name: userDetails?.fname }}
          >
            <FeedbackWidget />
           
              <Routes>
                <Route path="/" element={<MetaRedirect />} />
                <Route path="/forgot" element={<MetaRedirect />} />
                <Route path="/login" element={<Login />} />
                <Route path="/password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/charts" element={<Charts />} />
                  <Route path="/discover" element={<Discover />} />
                  <Route path="/compare" element={<Compare />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/account/:menu" element={<Account />} />
                  <Route path="/brand/:brandId" element={<Brand />} />
                  <Route path="/channel/:slug" element={<Channel />} />
                  <Route
                    path="/account/watchlists/list/:id"
                    element={<WatchlistDetails />}
                  />
                  <Route path="/brand/category/:slug" element={<Category />} />
                  <Route path="*" element={<Dashboard />} />
                </Route>
              </Routes>
           
          </UserbackProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
}

export default withErrorBoundary(App);

import React, { ReactNode, useEffect } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

const withErrorBoundary = (Component: React.ComponentType) => {
  return (props: any) => {
    useEffect(() => {
      const handleError = (event: ErrorEvent) => {
        console.error("Error caught by Error Boundary:", event.error);
        // Automatically reload the page after catching the error
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Small delay to log the error
      };

      window.addEventListener("error", handleError);

      return () => {
        window.removeEventListener("error", handleError);
      };
    }, []);

    return <Component {...props} />;
  };
};

export default withErrorBoundary;

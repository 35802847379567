import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./slices/userSlice";
import discoverReducer from "./slices/discoverSlice";

// Persist Config
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "discover"], // Persist both user & discover state
};

// Combine Reducers
const rootReducer = persistCombineReducers(persistConfig, {
  user: userReducer,
  discover: discoverReducer,
});

// Create Store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

// Persistor
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store, persistor };

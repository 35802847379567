import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import { get, post } from "../../utility/api";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import GospelButton from "../../common/GospelButton/GospelButton";
import { useNavigate } from "react-router-dom";
import GospelLabel from "../../common/GospelLabel/GospelLabel";
import GospelInput from "../../common/GospelInput/GospelInput";
import {
  Avatar,
  Checkbox,
  ConfigProvider,
  Dropdown,
  Modal,
  Select,
  Slider,
  Space,
} from "antd";
import { Country, Language, Option } from "../../utility/common";
import {
  ChannelInfo,
  FilterItem,
  Filters,
  categoryOptions,
  dataOptions,
  shortOptions,
  timeChangeFrameOptions,
  timeFrameOptions,
} from "../../types/charts";
import { CircularProgress } from "@mui/material";
import { Cancel, Dehaze, RemoveRedEyeSharp } from "@mui/icons-material";
import type { MenuProps } from "antd";
import { WatchlistItem } from "../../types/watchlist";
import Lottie from "react-lottie";
import * as animationData from "../../assets/animations/sucess.json";

export const formatNumber = (number: number) => {
  if (number >= 1000000000) {
    return Math.floor(number / 1000000000) + "B";
  } else if (number >= 1000000) {
    return Math.floor(number / 1000000) + "M";
  } else if (number >= 1000) {
    return Math.floor(number / 1000) + "K";
  } else {
    return Math.floor(number).toString();
  }
};

// Helper: Format numbers (with two decimals, if needed)
export const formatSliderNumber = (num: number, decimalPlace = 0) => {
  if (num >= 1e9) return Math.floor(num / 1e9) + "B";
  if (num >= 1e6) return Math.floor(num / 1e6) + "M";
  if (num >= 1e3) return Math.floor(num / 1e3) + "K";
  return num?.toString();
};

// Helper: Find the index in an array that is closest to the given value.
const closestIndex = (array: number[], value: number): number => {
  let minDiff = Infinity;
  let closestIdx = 0;
  array.forEach((val, idx) => {
    const diff = Math.abs(val - value);
    if (diff < minDiff) {
      minDiff = diff;
      closestIdx = idx;
    }
  });
  return closestIdx;
};

// Build the slider mapping array (replicating your jQuery slider logic)
const buildSliderAmountMap = (): number[] => {
  const initialMap = [
    1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000,
    13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 25000, 30000, 35000,
    40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000,
    95000, 100000, 110000, 120000, 130000, 140000, 150000, 160000, 170000,
    180000, 190000, 200000, 250000, 300000, 350000, 400000, 450000, 500000,
    550000, 600000, 650000, 700000, 750000, 800000, 850000, 900000, 950000,
    1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000,
    9000000, 10000000, 11000000, 12000000, 13000000, 14000000, 15000000,
    16000000, 17000000, 18000000, 19000000, 20000000, 21000000, 22000000,
    23000000, 24000000, 25000000, 26000000, 27000000, 28000000, 29000000,
  ];
  // Append values from 30M to 75M in 5M increments
  const minArray = 30000000;
  const maxArray = 75000000;
  const increments = 5000000;
  for (let i = minArray; i < maxArray; i += increments) {
    initialMap.push(i);
  }
  initialMap.push(maxArray);
  return initialMap;
};

const sliderAmountMap = buildSliderAmountMap();

interface CustomSliderProps {
  queryMinSubs?: string;
  queryMaxSubs?: string;
  onSliderChange: (min: number, max: number) => void; // Accept function as prop
}

const CustomSlider: React.FC<CustomSliderProps> = ({
  queryMinSubs,
  queryMaxSubs,
  onSliderChange,
}) => {
  // Determine default values from props or fallback defaults.
  const defaultMinSubs =
    queryMinSubs && queryMinSubs !== "" ? parseInt(queryMinSubs) : 1000;
  const defaultMaxSubs =
    queryMaxSubs && queryMaxSubs !== "" ? parseInt(queryMaxSubs) : 75000000;

  const defaultMinIndex = closestIndex(sliderAmountMap, defaultMinSubs);
  const defaultMaxIndex = closestIndex(sliderAmountMap, defaultMaxSubs);

  // sliderIndex holds the indices (in sliderAmountMap) for the current range.
  const [sliderIndex, setSliderIndex] = useState<[number, number]>([
    defaultMinIndex,
    defaultMaxIndex,
  ]);

  // subsRange holds the actual subscriber values based on sliderIndex.
  const [subsRange, setSubsRange] = useState({
    min: sliderAmountMap[defaultMinIndex],
    max: sliderAmountMap[defaultMaxIndex],
  });

  const handleSliderChange = (value: number[]) => {
    setSliderIndex([value[0], value[1]]);
    setSubsRange({
      min: sliderAmountMap[value[0]],
      max: sliderAmountMap[value[1]],
    });
    onSliderChange(sliderAmountMap[value[0]], sliderAmountMap[value[1]]);
  };

  return (
    <div>
      <div className="flex flex-row justify-center">
        <GospelLabel
          label={`Subscribers:`}
          className="text-sm text-gray-400 mb-1"
        />

        <GospelLabel
          label={`${formatSliderNumber(subsRange.min, 2)} - ${subsRange.max >= 75000000 ? "75M+" : formatSliderNumber(subsRange.max, 2)}`}
          className="text-sm text-gospel-purple mb-1 block ml-3"
        />
      </div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimaryBorderHover: "#e9e9e9",
          },
          components: {
            Slider: {
              handleActiveOutlineColor: "#e9e9e9",
              handleColor: "#e9e9e9",
              railBg: "#adafaf",
              railHoverBg: "#adafaf",
              trackBg: "#9D63B0",
              trackHoverBg: "#9D63B0",
            },
          },
        }}
      >
        <Slider
          range
          min={0}
          max={sliderAmountMap.length - 1}
          step={1}
          value={sliderIndex}
          onChange={handleSliderChange}
          tooltip={{ formatter: null }}
          style={{ width: 300 }}
        />
      </ConfigProvider>
    </div>
  );
};

function Charts() {
  const [subscriberSliderValue, setSubscriberSliderValue] = useState<{
    min: number;
    max: number;
  }>({
    min: 1000,
    max: 75000000,
  });
  const [viewChangeSliderValue, setViewChangeSliderValue] = useState<{
    min: number;
    max: number;
  }>({
    min: 10000,
    max: 1000000000,
  });
  const [subChangeSliderValue, setSubChangeSliderValue] = useState<{
    min: number;
    max: number;
  }>({
    min: 0,
    max: 4000000,
  });
  const [videoSliderValue, setVideoSliderValue] = useState<{
    min: number;
    max: number;
  }>({
    min: 1,
    max: 15,
  });

  const [shortVideoSliderValue, setShortVideoSliderValue] = useState<{
    min: number;
    max: number;
  }>({
    min: 1,
    max: 15,
  });

  const [watchList, setWatchList] = useState<WatchlistItem[]>([]);
  const [filterList, setFilterList] = useState<FilterItem[]>([]);
  const [countryList, setCountryList] = useState<Option[]>([]);
  const [deleteFilterLoading, setDeleteFilterLoading] = useState<{
    [key: number]: boolean;
  }>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [loadChannelInfo, setLoadChannelInfo] = useState<boolean>(false);
  const [showChannelInfo, setShowChannelInfo] = useState<boolean>(false);
  const [watchlistModalOpen, setWatchlistModalOpen] = useState<boolean>(false);
  const [createReportSuccessModal, setCreateReportSuccessModal] =
    useState<boolean>(false);
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [newWatchlistName, setNewWatchlistName] = useState<string>("");
  const [watchlistNameError, setWatchlistNameError] = useState<string>("");
  const [channelInfo, setChannelInfo] = useState<any[]>([]);
  const [languageList, setLanguageList] = useState<Option[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<Filters>({
    data: "views",
    timeframe: "alltime",
    categories: "",
    country: "all",
    language: "all",
    shorts: "0",
    madeforkids: "0",
  });
  const [filters, setFilters] = useState<Filters>({
    data: "views",
    timeframe: "alltime",
    categories: "",
    country: "all",
    language: "all",
    shorts: "2",
    madeforkids: "2",
  });
  const navigate = useNavigate();

  const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <button onClick={() => setFilterModalOpen(true)}>
          Save/View Filters
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button onClick={() => saveNewFilter(true)}>
          Create a Report for This Chart
        </button>
      ),
    },
  ];

  const handleSubscriberSliderChange = (value: number[]) => {
    setSubscriberSliderValue({ min: value[0], max: value[1] });
  };

  const handleViewChangeSliderChange = (value: number[]) => {
    setViewChangeSliderValue({ min: value[0], max: value[1] });
  };

  const handleSubChangeSliderChange = (value: number[]) => {
    setSubChangeSliderValue({ min: value[0], max: value[1] });
  };

  const handleVideoSliderChange = (value: number[]) => {
    setVideoSliderValue({ min: value[0], max: value[1] });
  };

  const handleShortVideoSliderChange = (value: number[]) => {
    setShortVideoSliderValue({ min: value[0], max: value[1] });
  };

  const handleFilterChange = (key: keyof Filters, value: string | string[]) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: Array.isArray(value) ? value.join(",") : value, // Join array as comma-separated string
    }));
  };

  const toggleOption = (value: string) => {
    // Get all category values excluding 'all'
    const allCategoryValues = categoryOptions
      .map((option) => option.value)
      .filter((v) => v);
    const currentCategories = filters.categories
      ? filters.categories.split(",")
      : [];

    if (value === "all") {
      if (currentCategories.length === allCategoryValues.length) {
        // Deselect all if currently all categories are selected
        setFilters((prevFilters) => ({
          ...prevFilters,
          categories: "",
        }));
      } else {
        // Select all categories if "All" is selected
        setFilters((prevFilters) => ({
          ...prevFilters,
          categories: allCategoryValues.join(","),
        }));
      }
    } else {
      // Handle individual selection/deselection
      let newSelection: string[];

      if (currentCategories.includes(value)) {
        // Remove the value if already selected
        newSelection = currentCategories.filter((v) => v !== value);
      } else {
        // Add the value if not already selected
        newSelection = [...currentCategories, value];
      }

      // If all individual categories are selected, show all categories as selected
      if (newSelection.length === allCategoryValues.length) {
        newSelection = allCategoryValues;
      } else {
        // Remove "All" if selecting/deselecting an individual option
        newSelection = newSelection.filter((v) => v !== "all");
      }

      setFilters((prevFilters) => ({
        ...prevFilters,
        categories: newSelection.length > 0 ? newSelection.join(",") : "", // Set to empty if no selection
      }));
    }
  };

  const toggleShowChannelInfo = () => {
    setShowChannelInfo(!showChannelInfo);
  };
  const fetchDataOnFilter = async () => {
    setAppliedFilters(filters);
    setLoadChannelInfo(true);

    try {
      const allCategoryValues = categoryOptions
        .map((option) => option.value)
        .filter((v) => v !== "all");
      const currentCategories = filters.categories
        ? filters.categories.split(",").filter((v) => v !== "all")
        : [];

      // If all individual categories are selected, set to "all"
      const categoriesParam =
        currentCategories.length === allCategoryValues.length
          ? "all"
          : filters.categories || "all";

      const params = new URLSearchParams();
      if (filters.data === "viewschange") {
        params.append("minviewschange", viewChangeSliderValue.min.toString());
        params.append("maxviewschange", viewChangeSliderValue.max.toString());
      }

      if (filters.data === "subschange") {
        params.append("minsubschange", subChangeSliderValue.min.toString());
        params.append("maxsubschange", subChangeSliderValue.max.toString());
      }

      if (
        filters.data === "vpv30" ||
        filters.data === "epv30" ||
        filters.data === "cpv30" ||
        filters.data === "vpv90" ||
        filters.data === "epv90" ||
        filters.data === "cpv90"
      ) {
        params.append("minvideos", videoSliderValue.min.toString());
        params.append("maxvideos", videoSliderValue.max.toString());
      }

      if (filters.data === "shvpv90" || filters.data === "lgvpv90") {
        params.append("minvideos", shortVideoSliderValue.min.toString());
        params.append("maxvideos", shortVideoSliderValue.max.toString());
      }

      params.append("categories", categoriesParam);
      params.append("country", filters.country || "all");
      params.append("language", filters.language || "all");
      params.append("madeforkids", filters.madeforkids || "0");
      params.append("minsubs", subscriberSliderValue.min.toString());
      params.append("maxsubs", subscriberSliderValue.max.toString());
      params.append("timeframe", filters.timeframe || "alltime");
      params.append("shorts", filters.shorts || "0");

      const result = await get<ChannelInfo[]>(
        `/youtube/stats/${filters.data}?${params.toString()}`
      );
      if (result) {
        setChannelInfo(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadChannelInfo(false);
    }
  };

  const handleFilterClick = async () => {
    fetchDataOnFilter();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch country and language data simultaneously
        const [countryResult, languageResult] = await Promise.all([
          get<Country[]>("gospel/countries/list"),
          get<Language[]>("gospel/languages/list"),
        ]);

        // Transform the country data into the desired format and add "All" option
        const transformedCountryOptions = [
          { value: "all", label: "All" },
          ...countryResult.map((country) => ({
            value: country.code,
            label: country.name,
          })),
        ];

        // Transform the language data into the desired format and add "All" option
        const transformedLanguageOptions = [
          { value: "all", label: "All" },
          ...languageResult.map((language) => ({
            value: language.code,
            label: language.name,
          })),
        ];

        // Update the state with the transformed data
        setCountryList(transformedCountryOptions);
        setLanguageList(transformedLanguageOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDataOnFilter();
    fetchData();
    fetchWatchlist();
    fetchFilterList();
  }, []);

  useEffect(() => {
    if (filters.data === "viewschange" || filters.data === "subschange") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        timeframe: "last7days",
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        timeframe: "alltime",
      }));
    }
  }, [filters.data]);

  const fetchWatchlist = async () => {
    try {
      const result: any = await get("/gospel/watchlist/list");
      if (result) {
        setWatchList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFilterList = async () => {
    try {
      const result: any = await get("/gospel/filter/list");
      if (result) {
        setFilterList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteFilter = async (filterId: number) => {
    setDeleteFilterLoading((prevState) => ({
      ...prevState,
      [filterId]: true,
    }));

    try {
      const result: any = await get(`/gospel/filter/delete?id=${filterId}`);
      if (result) {
        const updatedList = filterList.filter((item) => item.id !== filterId);
        setFilterList(updatedList);
        fetchFilterList();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setDeleteFilterLoading((prevState) => ({
        ...prevState,
        [filterId]: false,
      }));
    }
  };

  const createNewWatchlist = async () => {
    try {
      setLoading(true);
      if (newWatchlistName.trim() === "") {
        setWatchlistNameError("Please enter a watchlist name.");
        return;
      }
      await get(`/gospel/watchlist/create?title=${newWatchlistName}`);
      fetchWatchlist();
      setNewWatchlistName("");
      setWatchlistNameError("");
      setLoading(false);
    } catch (error) {
      console.error("Error creating new watchlist:", error);
      setLoading(false);
    }
  };

  const createRequestUri = (
    filters: Filters,
    sliderValue: { min: number; max: number }
  ) => {
    // Check if all categories are selected
    const allCategoryValues = categoryOptions
      .map((option) => option.value)
      .filter((v) => v !== "all");
    const currentCategories = filters.categories
      ? filters.categories.split(",").filter((v) => v !== "all")
      : [];

    // If all individual categories are selected, set to "all"
    const categoriesParam =
      currentCategories.length === allCategoryValues.length
        ? "all"
        : filters.categories || "all";

    const params = new URLSearchParams({
      categories: categoriesParam,
      country: filters.country || "all",
      language: filters.language || "all",
      timeframe: filters.timeframe || "",
      minsubs: sliderValue.min.toString(),
      maxsubs: sliderValue.max.toString(),
      madeforkids: filters.madeforkids || "2",
      shorts: filters.shorts || "2",
    });

    return `/v1/youtube/stats/views/top?${params.toString()}`;
  };

  const createFilterName = (
    filters: Filters,
    sliderValue: { min: number; max: number }
  ) => {
    const allCategoryValues = categoryOptions
      .map((option) => option.value)
      .filter((v) => v !== "all");
    const currentCategories = filters.categories
      ? filters.categories.split(",").filter((v) => v !== "all")
      : [];

    // If all individual categories are selected, set to "all"
    const categoriesParam =
      currentCategories.length === allCategoryValues.length
        ? "all"
        : filters.categories || "all";

    const categoryLabel =
      categoriesParam === "all"
        ? "All Categories"
        : `${categoriesParam.split(",").length} ${
            categoriesParam.split(",").length === 1 ? "Category" : "Categories"
          } `;
    const countryLabel =
      filters.country === "all" ? "All Countries" : filters.country;
    const subsRange = `${formatNumber(sliderValue.min)} - ${formatNumber(
      sliderValue.max
    )} Subscribers`;

    return `Top Change in ${
      filters.data.charAt(0).toUpperCase() + filters.data.slice(1)
    } for ${categoryLabel} in ${countryLabel} with ${subsRange}`;
  };

  const saveNewFilter = async (isCreateReport: boolean) => {
    try {
      setLoading(true);
      const name = createFilterName(filters, subscriberSliderValue);

      const request_uri = createRequestUri(filters, subscriberSliderValue);

      const data = new URLSearchParams();
      data.append("name", name);
      data.append("request_uri", request_uri);
      data.append("hidden", "false");
      data.append("report", "false");

      const response = await post("/gospel/filter/create", data);
      if (response) {
        if (isCreateReport) {
          setCreateReportSuccessModal(true);
        }
        fetchFilterList();
      }
    } catch (error) {
      console.error("Error saving filter:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="flex items-center justify-center mt-[70px] md:mt-[70px] md:mx-8 xs:mx-2">
        {/* Adjust margin-top to match the header height */}
        <div className="text-center py-4 md:mx-auto xs:mx-2 overflow-y-auto mt-5 w-full flex items-center flex-col">
          {/* max-w-screen-md sets maximum width for content, overflow-y-auto enables scrolling */}
          <h1 className="text-2xl font-semibold md:text-2xl lg:text-2xl text-gospel-gray-300">
            YouTube Charts
          </h1>

          <div className="text-center py-8 px-4  w-full bg-white rounded-md shadow-md mt-8 ">
            <div className="flex justify-end px-4">
              <ConfigProvider
                theme={{
                  token: {
                    fontFamily: "Montserrat, sans-serif",
                    controlItemBgHover: "#9D63B0",
                    fontSize: 13,
                  },
                }}
              >
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                >
                  <Dehaze />
                </Dropdown>
              </ConfigProvider>
            </div>
            <div className="flex flex-wrap justify-center items-center gap-4">
              <div className="py-2">
                <GospelLabel
                  label={"Data:"}
                  className="text-sm text-gray-400 mb-1 block pt-1"
                />

                <GospelSelectBox
                  options={dataOptions}
                  value={filters.data}
                  onChange={(value) => handleFilterChange("data", value)}
                  containerClass="relative max-w-[450px]"
                  className="w-52 h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
              <div className="py-2">
                <GospelLabel
                  label={"Timeframe:"}
                  className="text-sm text-gray-400 mb-1 block pt-1"
                />
                <GospelSelectBox
                  options={
                    filters.data === "viewschange" ||
                    filters.data === "subschange"
                      ? timeChangeFrameOptions
                      : timeFrameOptions
                  }
                  value={filters.timeframe}
                  onChange={(value) => handleFilterChange("timeframe", value)}
                  containerClass="relative max-w-[450px]"
                  disabled={
                    !(
                      filters.data === "views" ||
                      filters.data === "viewschange" ||
                      filters.data === "subs" ||
                      filters.data === "subschange"
                    )
                  }
                  className="w-52 h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
              <div className="py-2">
                <GospelLabel
                  label={"Categories:"}
                  className="text-sm text-gray-400 mb-1 block pt-1"
                />
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        colorTextPlaceholder: "#353b3d",
                        colorTextQuaternary: "#353b3d",
                      },
                    },
                    token: {
                      fontFamily: "Montserrat, sans-serif",
                      colorPrimary: "#9D63B0",
                    },
                  }}
                >
                  <Select
                    mode="multiple"
                    style={{ width: 230, height: 40 }}
                    placeholder="Select Categories"
                    maxTagCount={1}
                    value={
                      filters.categories
                        ? filters.categories
                            .split(",")
                            .filter((v) => v !== "all")
                        : []
                    } // Convert back to array for the select component
                    onChange={(value) =>
                      handleFilterChange("categories", value)
                    }
                    options={categoryOptions}
                    optionLabelProp="label"
                    removeIcon
                    dropdownRender={() => (
                      <div className="flex flex-col h-52 overflow-y-scroll">
                        {categoryOptions.map((option) => (
                          <Space key={option.value} style={{ padding: "8px" }}>
                            <Checkbox
                              checked={filters.categories
                                .split(",")
                                .includes(option.value)}
                              onChange={() => toggleOption(option.value)}
                            />
                            {option.label}
                          </Space>
                        ))}
                      </div>
                    )}
                  />
                </ConfigProvider>
              </div>
              <div className="py-2">
                <GospelLabel
                  label={"Country:"}
                  className="text-sm text-gray-400 mb-1 block pt-1"
                />
                <GospelSelectBox
                  options={countryList}
                  value={filters.country}
                  onChange={(value) => handleFilterChange("country", value)}
                  containerClass="relative max-w-[450px]"
                  className="w-52 h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
              <div className="py-2">
                <GospelLabel
                  label={"Language:"}
                  className="text-sm text-gray-400 mb-1 block pt-1"
                />
                <GospelSelectBox
                  options={languageList}
                  value={filters.language}
                  onChange={(value) => handleFilterChange("language", value)}
                  containerClass="relative max-w-[450px]"
                  className="w-52 h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
              <div className="py-2">
                <GospelLabel
                  label={"Made for Kids:"}
                  className="text-sm text-gray-400 mb-1 block pt-1"
                />
                <GospelSelectBox
                  options={shortOptions}
                  value={filters.madeforkids}
                  onChange={(value) => handleFilterChange("madeforkids", value)}
                  containerClass="relative max-w-[450px]"
                  className="w-52 h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
              <div className="py-2">
                <GospelLabel
                  label={"Shorts:"}
                  className="text-sm text-gray-400 mb-1 block pt-1"
                />
                <GospelSelectBox
                  options={shortOptions}
                  value={filters.shorts}
                  onChange={(value) => handleFilterChange("shorts", value)}
                  containerClass="relative max-w-[450px]"
                  className="w-52 h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
            </div>
            <div className="mt-12 px-4 items-center flex flex-col justify-center">
              <div className="flex flex-row items-center gap-8 justify-center w-2/4">
                <div className="flex flex-col items-center w-2/5">
                  {/* <div className="flex flex-row justify-center">
                    <GospelLabel
                      label={`Subscribers:`}
                      className="text-sm text-gray-400 mb-1"
                    />
                    <GospelLabel
                      label={`${formatNumber(subscriberSliderValue.min)} - ${formatNumber(
                        subscriberSliderValue.max
                      )}+`}
                      className="text-sm text-gospel-purple mb-1 block ml-3"
                    />
                  </div>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimaryBorderHover: "#e9e9e9",
                      },
                      components: {
                        Slider: {
                          handleActiveOutlineColor: "#e9e9e9",
                          handleColor: "#e9e9e9",
                          railBg: "#adafaf",
                          railHoverBg: "#adafaf",
                          trackBg: "#9D63B0",
                          trackHoverBg: "#9D63B0",
                        },
                      },
                    }}
                  >
                   
                    <Slider
                      range
                      max={75000000}
                      min={1000}
                      tooltip={{ formatter: null }}
                      style={{ width: "100%" }}
                      defaultValue={[1000, 75000000]}
                      onChangeComplete={handleSubscriberSliderChange}
                    />
                  </ConfigProvider> */}

                  <CustomSlider
                    queryMinSubs="1000"
                    queryMaxSubs="75000000"
                    onSliderChange={(min, max) =>
                      setSubscriberSliderValue({ min, max })
                    } // Update state
                  />
                </div>
                {filters.data === "viewschange" && (
                  <div className="flex flex-col items-cente w-2/5">
                    <div className="flex flex-row justify-center">
                      <GospelLabel
                        label={`Change:`}
                        className="text-sm text-gray-400 mb-1"
                      />
                      <GospelLabel
                        label={`${formatNumber(viewChangeSliderValue.min)} - ${formatNumber(
                          viewChangeSliderValue.max
                        )}+`}
                        className="text-sm text-gospel-purple mb-1 block ml-3"
                      />
                    </div>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimaryBorderHover: "#e9e9e9",
                        },
                        components: {
                          Slider: {
                            handleActiveOutlineColor: "#e9e9e9",
                            handleColor: "#e9e9e9",
                            railBg: "#adafaf",
                            railHoverBg: "#adafaf",
                            trackBg: "#9D63B0",
                            trackHoverBg: "#9D63B0",
                          },
                        },
                      }}
                    >
                      <Slider
                        range
                        max={1000000000}
                        min={10000}
                        tooltip={{ formatter: null }}
                        style={{ width: "100%" }}
                        defaultValue={[10000, 1000000000]}
                        onChangeComplete={handleViewChangeSliderChange}
                      />
                    </ConfigProvider>
                  </div>
                )}

                {filters.data === "subschange" && (
                  <div className="flex flex-col items-cente w-2/5">
                    <div className="flex flex-row justify-center">
                      <GospelLabel
                        label={`Change:`}
                        className="text-sm text-gray-400 mb-1"
                      />
                      <GospelLabel
                        label={`${formatNumber(subChangeSliderValue.min)} - ${formatNumber(
                          subChangeSliderValue.max
                        )}+`}
                        className="text-sm text-gospel-purple mb-1 block ml-3"
                      />
                    </div>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimaryBorderHover: "#e9e9e9",
                        },
                        components: {
                          Slider: {
                            handleActiveOutlineColor: "#e9e9e9",
                            handleColor: "#e9e9e9",
                            railBg: "#adafaf",
                            railHoverBg: "#adafaf",
                            trackBg: "#9D63B0",
                            trackHoverBg: "#9D63B0",
                          },
                        },
                      }}
                    >
                      <Slider
                        range
                        max={4000000}
                        min={0}
                        tooltip={{ formatter: null }}
                        style={{ width: "100%" }}
                        defaultValue={[0, 4000000]}
                        onChangeComplete={handleSubChangeSliderChange}
                      />
                    </ConfigProvider>
                  </div>
                )}

                {(filters.data === "vpv30" ||
                  filters.data === "epv30" ||
                  filters.data === "cpv30" ||
                  filters.data === "vpv90" ||
                  filters.data === "epv90" ||
                  filters.data === "cpv90") && (
                  <div className="flex flex-col items-center w-2/5">
                    <div className="flex flex-row justify-center">
                      <GospelLabel
                        label={`Videos:`}
                        className="text-sm text-gray-400 mb-1"
                      />
                      <GospelLabel
                        label={`${formatNumber(videoSliderValue.min)} - ${formatNumber(
                          videoSliderValue.max
                        )}+`}
                        className="text-sm text-gospel-purple mb-1 block ml-3"
                      />
                    </div>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimaryBorderHover: "#e9e9e9",
                        },
                        components: {
                          Slider: {
                            handleActiveOutlineColor: "#e9e9e9",
                            handleColor: "#e9e9e9",
                            railBg: "#adafaf",
                            railHoverBg: "#adafaf",
                            trackBg: "#9D63B0",
                            trackHoverBg: "#9D63B0",
                          },
                        },
                      }}
                    >
                      <Slider
                        range
                        max={15}
                        min={1}
                        tooltip={{ formatter: null }}
                        style={{ width: "100%" }}
                        defaultValue={[1, 15]}
                        onChangeComplete={handleVideoSliderChange}
                      />
                    </ConfigProvider>
                  </div>
                )}

                {(filters.data === "shvpv90" || filters.data === "lgvpv90") && (
                  <div className="flex flex-col items-center w-2/5">
                    <div className="flex flex-row justify-center">
                      <GospelLabel
                        label={`Shorts Videos:`}
                        className="text-sm text-gray-400 mb-1"
                      />
                      <GospelLabel
                        label={`${formatNumber(shortVideoSliderValue.min)} - ${formatNumber(
                          shortVideoSliderValue.max
                        )}+`}
                        className="text-sm text-gospel-purple mb-1 block ml-3"
                      />
                    </div>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimaryBorderHover: "#e9e9e9",
                        },
                        components: {
                          Slider: {
                            handleActiveOutlineColor: "#e9e9e9",
                            handleColor: "#e9e9e9",
                            railBg: "#adafaf",
                            railHoverBg: "#adafaf",
                            trackBg: "#9D63B0",
                            trackHoverBg: "#9D63B0",
                          },
                        },
                      }}
                    >
                      <Slider
                        range
                        max={15}
                        min={1}
                        tooltip={{ formatter: null }}
                        style={{ width: "100%" }}
                        defaultValue={[1, 15]}
                        onChangeComplete={handleShortVideoSliderChange}
                      />
                    </ConfigProvider>
                  </div>
                )}
              </div>
              <GospelButton
                className="text-white h-[40px] w-[90px] my-6 rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple ml-3"
                label="Filter"
                onClick={() => handleFilterClick()}
              />
            </div>
          </div>

          <div className="w-full my-6">
            <div className="flex flex-col py-8">
              {loadChannelInfo ? (
                <div className="p-8 flex items-center justify-center ">
                  <CircularProgress
                    className="text-gospel-gray-300 font-bold z-10"
                    size={30}
                  />
                </div>
              ) : (
                channelInfo &&
                channelInfo.length > 0 && (
                  <div className="overflow-x-auto">
                    {/* Table Container */}
                    <div className="min-w-full divide-y divide-gray-200">
                      {/* Table Head */}
                      <div className="flex text-left text-base text-gospel-gray-400 border-b border-gray-200">
                        <div className="w-1/12 px-4 py-2"></div>
                        <div className="w-1/12 px-4 py-2">#</div>
                        <div className="w-2/12 px-4 py-2 font-medium">
                          Country
                        </div>
                        <div className="w-3/12 px-4 py-2 font-medium">
                          Channel Name
                        </div>
                        <div className="w-2/12 px-4 py-2 font-medium">
                          Category
                        </div>
                        <div className="w-3/12 px-4 py-2 font-medium">
                          {`${dataOptions.find((e) => e.value === appliedFilters.data)?.label} - ${timeFrameOptions.find((e) => e.value === appliedFilters.timeframe)?.label}`}
                        </div>
                        <div className="w-1/12 px-4 py-2"></div>
                      </div>
                      {/* Table Body */}
                      {channelInfo
                        .slice(0, showChannelInfo ? channelInfo.length : 10)
                        .map((item) => (
                          <div
                            key={item.channelId}
                            className="flex bg-white shadow-md rounded-lg mb-4  border border-gray-200 text-left py-2"
                          >
                            {/* Thumbnail */}
                            <div className="w-1/12 px-4 flex items-center">
                              <a
                                href="/"
                                className="mr-2 text-gospel-purple no-underline"
                              >
                                <img
                                  src={item.channelImage}
                                  alt="channel-logo"
                                  className="w-12 h-12 rounded-full cursor-pointer"
                                />
                              </a>
                            </div>
                            {/* Rank */}
                            <div className="w-1/12 px-4 flex items-center">
                              <p className="font-medium text-gospel-gray-300">
                                {item.rank}
                              </p>
                            </div>
                            {/* Country and Language */}
                            <div className="w-2/12 px-4 flex items-center gap-2">
                              <Avatar
                                src={
                                  <img
                                    alt={item.country}
                                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                                      item?.country.toUpperCase() || ""
                                    }.svg`}
                                    className="rounded-full"
                                  />
                                }
                              />
                              <div className="flex flex-col">
                                <p className="text-sm text-gospel-gray-300">
                                  {countryList.find(
                                    (e) => e.value === item.country
                                  )?.label || "Unknown"}
                                </p>
                                <p className="text-sm text-gospel-gray-200">
                                  {item.language}
                                </p>
                              </div>
                            </div>
                            {/* Title */}
                            <div className="w-3/12 px-4 flex  items-center text-gospel-purple text-base cursor-pointer">
                              <a href={`/channel/${item.slug}`}>{item.title}</a>
                            </div>
                            {/* Category */}
                            <div className="w-2/12 px-4 flex items-center text-gospel-gray-300 text-base">
                              {item.categoryName}
                            </div>
                            {/* Views */}
                            <div className="w-3/12 px-4 flex items-center text-gospel-gray-300 text-base">
                              {appliedFilters.data === "viewschange" ||
                              appliedFilters.data === "subschange"
                                ? `${item.change} | ${item[appliedFilters.data as keyof any]}`
                                : item[appliedFilters.data as keyof any]}
                            </div>
                            <div className="w-1/12 px-4 flex items-center">
                              <button
                                className="flex flex-row p-1 items-center gap-2 bg-gospel-gray-100 hover:bg-gospel-gray-300 rounded-full"
                                onClick={() => setWatchlistModalOpen(true)}
                              >
                                <RemoveRedEyeSharp
                                  className="text-white"
                                  fontSize="small"
                                />
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                    {channelInfo?.length > 2 && (
                      <GospelButton
                        className="bg-white px-2 self-center my-2 py-2 w-36 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                        label={showChannelInfo ? "Show Less" : "View More"}
                        onClick={toggleShowChannelInfo}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 22,
              titleColor: "#353b3d",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title="Watchlists"
          open={watchlistModalOpen}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => setWatchlistModalOpen(false)}
            />
          }
          className="text-center"
        >
          <div className="flex flex-col items-start">
            <p className=" text-gospel-gray-300 text-base mb-6">
              Select a list below
            </p>
            <div className="flex flex-col w-full gap-4 h-52 overflow-y-scroll">
              {watchList.map((item) => {
                return (
                  <div
                    className="bg-gospel-white-100 hover:bg-gospel-gray-100 text-gospel-purple hover:text-black py-3 rounded-md cursor-pointer"
                    key={item.id}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            <div className="mt-7 pt-7 w-full flex flex-col border-t border-gospel-gray-400 ">
              <GospelInput
                type="text"
                onChange={(e) => {
                  setNewWatchlistName(e.target.value);
                  setWatchlistNameError(""); // Clear error when input changes
                }}
                value={newWatchlistName}
                placeholder="Create new list"
                className="w-full h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
              />
              {watchlistNameError && (
                <div className="text-gospel-error-red mb-2 text-sm bg-gospel-red-100 px-3 mt-2 py-2 rounded">
                  {watchlistNameError}
                </div>
              )}
              <GospelButton
                onClick={createNewWatchlist}
                label={
                  loading ? (
                    <CircularProgress
                      className="text-gospel-gray-300 font-bold"
                      size={14}
                    />
                  ) : (
                    "Create"
                  )
                }
                disabled={!newWatchlistName.trim()}
                className="bg-white w-28 py-2.5 mt-2 mb-8 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
              />
            </div>
          </div>
        </Modal>

        <Modal
          title="Saved Filters"
          open={filterModalOpen}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => setFilterModalOpen(false)}
            />
          }
          className="text-center"
        >
          <div>
            <GospelButton
              className="text-white h-[40px] w-40 rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple mt-4"
              label={
                loading ? (
                  <CircularProgress
                    className="text-gospel-gray-300 font-bold"
                    size={14}
                  />
                ) : (
                  "Save Current Filter"
                )
              }
              onClick={() => saveNewFilter(false)}
            />

            <div className="w-full flex flex-col my-4">
              <div className="  text-gospel-gray-300 py-3 rounded-md cursor-pointer">
                Or select a saved filter from the list below
              </div>
              {filterList && filterList.length === 0 && (
                <p className="text-gospel-gray-400">
                  YOU HAVE NO SAVED FILTERS
                </p>
              )}
            </div>
            <div className="my-4">
              {filterList?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="bg-gospel-white-100 hover:bg-gospel-gray-100 text-gospel-purple hover:text-black p-4 rounded-md cursor-pointer flex flex-col items-start mb-3"
                  >
                    <p className="text-left">{item.name}</p>
                    <button
                      className="text-gospel-gray-400 mt-2"
                      onClick={() => deleteFilter(item.id)}
                    >
                      {deleteFilterLoading[item.id] ? (
                        <CircularProgress
                          className="text-gospel-gray-300 font-bold"
                          size={14}
                        />
                      ) : (
                        "Delete"
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
      </ConfigProvider>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 18,
              titleColor: "#353b3d",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title="Your Report is on the Way! 📬"
          open={createReportSuccessModal}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => setCreateReportSuccessModal(false)}
            />
          }
          className="text-center"
        >
          <Lottie options={defaultAnimationOptions} height={250} width={250} />
          <div className="mt-4 mb-6">
            <p className="text-gospel-purple">
              Your report is being generated and will be sent to your email
              shortly!!
            </p>
            <p className="text-gospel-purple ">
              Please check your inbox in a few minutes.
            </p>
          </div>
        </Modal>
      </ConfigProvider>
      <Footer />
    </div>
  );
}

export default Charts;

import axios, { AxiosInstance, AxiosResponse } from "axios";
import { store } from "../redux/store";
import { clearUser } from "../redux/slices/userSlice";

// Define URLs and API keys dynamically
const BASE_URL = "https://api.gospelstats.com/v1";

const API_KEY = "3Rv6mxgKrK7LB9wzhdUaV28YpKbjY4363WPUr3pg";


// Create Axios Instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "x-api-key": API_KEY, // Automatically picks the correct key
  },
});

// Add an interceptor to include Authorization header with token from Redux
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().user.user?.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response, // If response is successful, return it
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 401 || status === 409) {
        // Logout the user
        store.dispatch(clearUser());

        // Redirect user to login with error message
        window.location.href = `/login?message=${encodeURIComponent(
          data.message || "Session expired. Please log in again."
        )}`;
      }
    }

    return Promise.reject(error); // Reject error for further handling
  }
);

// General GET request function
export const get = async <T>(endpoint: string): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

// General POST request function
export const post = async <T>(
  endpoint: string,
  data: URLSearchParams
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("POST request error:", error);
    throw error;
  }
};

// General PUT request function
export const put = async <T>(endpoint: string, data: any): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.put(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("PUT request error:", error);
    throw error;
  }
};

export const putFileToS3 = async (
  url: string,
  file: File
): Promise<boolean> => {
  try {
    const response = await axios.put(url, file, {
      headers: {
        "Content-Type": "text/csv", // Set Content-Type as required
      },
    });

    // Check for successful upload
    if (response.status === 200) {
      return true;
    } else {
      throw new Error(`S3 upload failed with status: ${response.status}`);
    }
  } catch (error) {
    console.error("S3 upload error:", error);
    throw error;
  }
};

// General DELETE request function
export const del = async <T>(endpoint: string): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error("DELETE request error:", error);
    throw error;
  }
};

export const postWithData = async <T>(
  endpoint: string,
  data: any
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.post(
      endpoint,
      data,
      {
        headers: {
          "Content-Type": "application/json", // Ensure it's set to JSON
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("POST request error:", error);
    throw error;
  }
};

export default axiosInstance;

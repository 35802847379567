import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import moment from "moment";

interface FooterProps {
  isPolicyVisible?: boolean;
}
function Footer({ isPolicyVisible = true }: FooterProps) {
  return (
    <div className="text-center py-16 bg-black mt-auto">
      <div>
        <p className="font-semibold text-sm text-gospel-gray-400 opacity-50">
          {`© ${moment().format("YYYY")} Gospel Stats.`}
        </p>
      </div>
      <div className="gap-2 flex justify-center my-4">
        <a
          href="https://www.twitter.com/GospelStats/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gospel-gray-400 opacity-50 hover:opacity-100 mr-3"
        >
          <XIcon />
        </a>
        <a
          href="https://www.instagram.com/GospelStats/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gospel-gray-400 opacity-50 hover:opacity-100 mr-3"
        >
          <InstagramIcon />
        </a>
        <a
          href="https://www.facebook.com/GospelStats/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gospel-gray-400 opacity-50 hover:opacity-100"
        >
          <FacebookIcon />
        </a>
      </div>
      {isPolicyVisible && (
        <div className="flex-row flex justify-center">
          <a
            href="/privacy"
            className="text-gospel-gray-400 opacity-50 hover:opacity-100 text-sm"
          >
            Privacy Policy
          </a>
          <p className="mx-2 text-gospel-gray-400 opacity-50 text-sm">|</p>
          <a
            href="/terms"
            className="text-gospel-gray-400 opacity-50 hover:opacity-100 text-sm"
          >
            Terms of Service
          </a>
        </div>
      )}
    </div>
  );
}

export default Footer;

import React, { useCallback, useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import { get } from "../../utility/api";
import { Brand } from "../../types/dashboard";
import GospelButton from "../../common/GospelButton/GospelButton";
import { useNavigate } from "react-router-dom";
import { Cancel, KeyboardArrowDown } from "@mui/icons-material";
import ReactPlayer from "react-player";
import { ConfigProvider, Modal } from "antd";
import { UserDetails } from "../../types/account";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../redux/slices/userSlice";

const titleMap: Record<number, string> = {
  1: "Most Viewed Branded Videos",
  2: "Most Viewed Channels",
  3: "Brands with Most Sponsored Videos",
  4: "Most Subscribed Channels",
  5: "Brand Newcomers",
  6: "Coming Up Hot",
  7: "Creator Newcomers",
};

const subTitleMap: Record<number, string> = {
  1: "Views",
  2: "Views",
  3: "Videos",
  4: "Subscribers",
  5: "Last Upload Views",
  6: "Subscribers",
  7: "Last Upload Views",
};

function Dashboard() {
  const [placeholder, setPlaceholder] = useState<string>(
    "Name, username, channels, etc."
  );
  const [selectedValue, setSelectedValue] = useState<string>("channels");
  const [searchKey, setSearchKey] = useState<string>("");
  const [selectedVideoId, setSelectedVideoId] = useState<string>("");
  const [isYoutubeOpen, setIsYoutubeOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setPlaceholder(`Name, username, ${value.toLowerCase()}, etc.`);
    setSelectedValue(event.target.value);
  };

  const handlePageInfo = useCallback(
    (
      slug: string,
      brandInfoId: number | string | undefined,
      videoId: string,
      cardName: string = "",
      isChannel: boolean = false
    ) => {
      setLoading(true);
      if (cardName === "Brands with Most Sponsored Videos") {
        navigate(`/brand/${slug}`);
      } else if (brandInfoId && !isChannel) {
        setSelectedVideoId((prevVideoId) => {
          const newVideoId = videoId;
          setIsYoutubeOpen(true);
          return newVideoId;
        });
      } else {
        navigate(`/channel/${slug}`);
      }
    },
    [loading]
  );

  useEffect(() => {
    if (selectedVideoId) {
      setIsYoutubeOpen(true);
      setLoading(false);
    }
  }, [selectedVideoId]);

  const [dashboardData, setDashboardData] = useState<Brand[]>([]);

  const options = ["Channels", "Brands"].map((item) => ({
    value: item,
    label: item,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await get<Brand[]>("/gospel/brand/dashboard/list");
        // Create a new array with the additional title and subTitle properties
        const updatedData = result.map((brand) => ({
          ...brand,
          title: titleMap[brand.id] || "", // Default to empty string
          subTitle: subTitleMap[brand.id] || "",
        }));
        setDashboardData(updatedData);
        const userDetailsResponse: UserDetails = await get("/gospel/user/info");
        dispatch(setUserDetails({ userDetails: userDetailsResponse }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleSearch = () => {
    // Construct the query parameters
    const queryParams = new URLSearchParams({
      q: searchKey,
      c: selectedValue.toLowerCase(),
    }).toString();

    navigate(`/search/?${queryParams}`);
  };

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="flex items-center justify-center mt-[70px] md:mt-[70px] md:mx-28 xs:mx-2">
        {/* Adjust margin-top to match the header height */}
        <div className="text-center py-4 md:mx-auto xs:mx-2  mt-5 w-full flex items-center flex-col">
          {/* max-w-screen-md sets maximum width for content, overflow-y-auto enables scrolling */}
          <h1 className="text-2xl font-semibold md:text-2xl lg:text-2xl text-gospel-gray-300">
            Dashboard
          </h1>

          {/* Search Tools Section */}
          <div className="text-center py-8 px-4 gap-2 flex w-full bg-white rounded-md shadow-md mt-8 justify-center">
            {/* Input Field */}
            <div className="flex items-center w-full justify-center flex-col md:flex-row xl:gap-0 lg:gap-0 md:gap-0 gap-2">
              <input
                type="text"
                className="lg:rounded-r-none xl:rounded-r-none md:rounded-r-none px-2 py-2.5 placeholder-gospel-gray-300 placeholder-opacity-100 border border-gray-300 focus:border-gospel-gray-200 hover:border-gospel-gray-200 focus:outline-none lg:rounded-l-md xl:rounded-l-md md:rounded-l-md rounded-md text-gospel-gray-300 text-sm md:w-2/5 lg:w-2/5 xl:w-2/5 w-full"
                placeholder={placeholder}
                onChange={(e) => setSearchKey(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <div
                className="relative md:w-[11%] lg:w-[11%] xl:w-[11%] w-full"
                style={{ width: "11%" }}
              >
                <select
                  value={selectedValue}
                  onChange={handleSelectChange}
                  className="text-sm text-gospel-purple lg:border-l-0 xl:border-l-0 md:border-l-0 px-4 py-2.5 border border-gray-300 focus:border-gospel-gray-200 hover:border-gospel-gray-200 lg:rounded-l-none xl:rounded-l-none rounded-md md:rounded-l-none appearance-none w-full"
                >
                  <option value="" disabled>
                    {"Select"}
                  </option>
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                  <KeyboardArrowDown className="text-black" />
                </div>
              </div>

              {/* <GospelSelectBox
                options={options}
                value={selectedValue}
                onChange={handleSelectChange}
                containerClass="relative md:w-[11%] lg:w-[11%] xl:w-[11%] w-full"
                containerStyle={{ width: "11%" }}
                className="text-sm text-gospel-purple lg:border-l-0 xl:border-l-0 md:border-l-0 px-4 py-2.5 border border-gray-300 focus:border-gospel-gray-200 hover:border-gospel-gray-200 lg:rounded-l-none xl:rounded-l-none rounded-md md:rounded-l-none appearance-none w-full"
                placeholder="Select"
              /> */}

              {/* Search Button */}
              <GospelButton
                className="text-white h-[40px] w-[90px] rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple ml-3"
                label="Search"
                onClick={() => handleSearch()}
              />
            </div>
          </div>

          {/* Content section */}
          <div className="max-w-1200px w-full md:w-auto mx-auto relative my-8">
            <div className="flex flex-wrap justify-between -mx-2">
              {dashboardData?.map((brand, index) => {
                return (
                  <div key={brand.id} className="w-full md:w-1/2 px-2 mb-8">
                    <div className="relative px-4 pt-6 md:px-4 md:pt-6  shadow-md bg-white rounded-md overflow-y-scroll no-scrollbar">
                      {/* List Header */}
                      <div className="flex gap-1 flex-col">
                        <h2 className="font-semibold text-xl">{brand.title}</h2>
                        <h3 className="font-normal text-lg">
                          {brand.subTitle}
                        </h3>
                        <span className="text-sm leading-normal text-gospel-gray-400 w-full text-center block mb-4">
                          Uploaded in Last 7 Days
                        </span>
                      </div>
                      {/* List Content */}
                      {brand.list &&
                        brand.list.length > 0 &&
                        brand.list.map((listItem, listItemIndex) => (
                          <div className="mb-1" key={listItem.order}>
                            <div
                              className={`flex ${
                                listItemIndex % 2 === 0
                                  ? "bg-gospel-white-100"
                                  : "bg-transparent"
                              } items-center p-2 h-full`}
                            >
                              <span className="mr-2 text-gospel-purple no-underline">
                                <a
                                  href={
                                    listItem.brandSlug
                                      ? `/brand/${listItem.brandSlug}`
                                      : `/channel/${listItem.channelSlug}`
                                  }
                                >
                                  <img
                                    src={listItem.image}
                                    alt={"channel-logo"}
                                    className="w-12 h-12 rounded-full mr-2 cursor-pointer"
                                  />
                                </a>
                              </span>
                              <div className="pt-2 items-start flex flex-col flex-grow w-min">
                                <button
                                  className="text-gospel-purple transition-colors duration-500 ease-in-out hover:text-black cursor-pointer mb-2 block leading-5 text-wrap text-left"
                                  onClick={() =>
                                    handlePageInfo(
                                      listItem.channelSlug ||
                                        listItem.slug ||
                                        "",
                                      listItem.brandInfoId ||
                                        listItem.brandSlug,
                                      listItem.videoId || "",
                                      brand.title
                                    )
                                  }
                                >
                                  {listItem.title}
                                </button>
                                {listItem.brandInfoId && (
                                  <>
                                    <span className="block text-sm mb-1">
                                      Brand:{" "}
                                      <a
                                        href={`/brand/${listItem.brandSlug}`}
                                        className="text-gospel-purple no-underline"
                                      >
                                        {listItem.brandName}
                                      </a>
                                    </span>
                                    <span className="flex flex-row text-sm mb-2">
                                      Channel:
                                      <button className="text-gospel-purple transition-colors duration-500 ease-in-out hover:text-black cursor-pointer mb-2 block leading-5 text-wrap ml-1">
                                        <a
                                          href={`/channel/${listItem.channelSlug}`}
                                        >
                                          {listItem.channelTitle}
                                        </a>
                                      </button>
                                    </span>
                                  </>
                                )}
                              </div>
                              <div className="flex items-center">
                                <span className="font-semibold text-lg ml-auto">
                                  {(
                                    listItem.viewCount ?? listItem.stat
                                  )?.toLocaleString() ?? "0"}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 18,
              headerBg: "transparent",
              contentBg: "",
              titleColor: "#353b3d",
              boxShadow: "none",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title=""
          open={isYoutubeOpen}
          width={"fit-content"}
          height={"fit-content"}
          footer={null}
          closeIcon={
            <Cancel
              className="absolute bg-gospel-purple text-white rounded-full bottom-0"
              fontSize="large"
              onClick={() => setIsYoutubeOpen(false)}
            />
          }
          className="text-center"
        >
          <ReactPlayer
            controls
            width="750px"
            height={"450px"}
            url={`https://youtube.com/watch?v=${selectedVideoId}`}
          />
        </Modal>
      </ConfigProvider>

      <Footer />
    </div>
  );
}

export default Dashboard;

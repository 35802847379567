import { Add, ArrowBack, Cancel, Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import GospelLabel from "../../common/GospelLabel/GospelLabel";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import GospelInput from "../../common/GospelInput/GospelInput";
import {
  AutoComplete,
  Checkbox,
  ConfigProvider,
  Modal,
  Select,
  Space,
} from "antd";
import GospelButton from "../../common/GospelButton/GospelButton";
import {
  adminOptions,
  categoryOptions,
  criterias,
  reportOptions,
  socialOptions,
  timeChangeFrameOptions,
  timeFrameOptions,
  uploadsTimeFrameOptions,
} from "./ReportUtils";
import {
  Country,
  Language,
  Option,
  formatNumberWithCommas,
} from "../../utility/common";
import { get, post } from "../../utility/api";
import { shortOptions } from "../../types/charts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { debounce } from "lodash";
import { SearchBrandData } from "../../types/search";
import { CircularProgress } from "@mui/material";
import { ReportItem } from "../../types/report";
import { debug } from "util";

const parseInputValue = (value: string): number =>
  parseInt(value.replace(/,/g, ""), 10) || 0;

interface CreateReportProps {
  setShowCreateReport: (value: boolean) => void;
  reportMaxRowsAllowed: number;
  editReportData: ReportItem | null;
  setEditReportData: React.Dispatch<React.SetStateAction<ReportItem | null>>;
}

type JsonObject = {
  [key: string]: any;
};

const removeEmptyValues = (obj: JsonObject): JsonObject => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => {
        // Exclude null, undefined, empty string, and empty objects
        if (
          value === null ||
          value === undefined ||
          value === "" ||
          (typeof value === "object" &&
            !Array.isArray(value) &&
            Object.keys(value).length === 0)
        ) {
          return false;
        }
        return true;
      })
      .map(([key, value]) => [
        key,
        // Recursively clean nested objects
        typeof value === "object" && !Array.isArray(value)
          ? removeEmptyValues(value as JsonObject)
          : value,
      ])
      // Remove keys with nested empty objects
      .filter(
        ([_, value]) =>
          !(typeof value === "object" && Object.keys(value).length === 0)
      )
  );
};

const defaultFilters = {
  reportType: "",
  lifetimeViews: { min: "", max: "" },
  lifetimeSubscribers: { min: "", max: "" },
  countries: "",
  language: "",
  categories: "",
  adminCategories: "",
  viewsOverTime: { min: "", max: "", timeframe: "" },
  longsAvgDur: { min: "", max: "", timeframe: "" },
  subscribersOverTime: { min: "", max: "", timeframe: "" },
  viewsPercentageChange: { min: "", max: "", timeframe: "" },
  subscribersPercentageChange: { min: "", max: "", timeframe: "" },
  v30Day: { min: "", max: "" },
  vpv30: { min: "", max: "" },
  vpv90: { min: "", max: "" },
  shvpv30: { min: "", max: "" },
  lgvpv30: { min: "", max: "" },
  shvpv90: { min: "", max: "" },
  lgvpv90: { min: "", max: "" },
  minv30: { min: "", max: "" },
  minv90: { min: "", max: "" },
  shortsMinv90: { min: "", max: "" },
  longsMinv90: { min: "", max: "" },
  cldpv30: { min: "", max: "" },
  cldpmv30: { min: "", max: "" },
  uploads: { min: "", max: "", timeframe: "" },
  shortsUploads: { min: "", max: "", timeframe: "" },
  longsUploads: { min: "", max: "", timeframe: "" },
  shorts: "",
  madeForKids: "",
  videoMilestoneType: "",
  videoViewsMilestone: "",
  channelMilestoneType: "",
  channelViewsMilestone: "",
  mayKeywords: "",
  mustKeywords: "",
  notKeywords: "",
  yesBrands: [], // Array of brand objects: [{ id: number, name: string }]
  notBrands: [],
  mayKeywordsTemp: "",
  mustKeywordsTemp: "",
  notKeywordsTemp: "",
  yesBrandsTemp: "",
  notBrandTemp: "",
  daysAgo: "",
  outliers: "",
  results: "",
};

const CreateReport: React.FC<CreateReportProps> = ({
  setShowCreateReport,
  reportMaxRowsAllowed,
  editReportData,
  setEditReportData,
}) => {
  const editReportCriteria = JSON.parse(editReportData?.reportJson ?? "null");
  const userAdmin = useSelector((state: RootState) => state.user.user?.admin);
  const [criteria, setCriteria] = useState("");
  const [selectedReport, setSelectedReport] = useState(() => {
    if (editReportData) {
      const foundReport = reportOptions.find(
        (item) => item.source === editReportData.sourceType
      );
      return foundReport?.value ?? "channelMilestone";
    }
    return "channel";
  });
  const [reportName, setReportName] = useState<string>(
    editReportData ? editReportData.name : ""
  );
  const [previewResultCount, setPreviewResultCount] = useState<string>("");
  const [createReportSucess, setCreateReportSucess] = useState<boolean>(false);
  const [reportRow, setReportRow] = useState<string>(
    reportMaxRowsAllowed.toString()
  );
  const [social, setSocial] = useState<string>("");
  const [languageList, setLanguageList] = useState<Option[]>([]);
  const [filters, setFilters] = useState<any>({
    ...defaultFilters,
    daysAgo: editReportCriteria?.daysAgo ? editReportCriteria.daysAgo : 7,
  });
  const [reportModal, setReportModal] = useState<boolean>(false);
  const [selectedFields, setSelectedFields] = useState<
    { value: string; label: string }[]
  >([]);
  const [criteriaOptions, setCriteriaOptions] = useState(
    criterias.filter(
      (criterion) => criterion.value !== "adminCategories" || userAdmin // Include "Admin Categories" only if admin
    )
  ); // Include "Admin Categories" only if admin);

  const [brandOptions, setBrandOptions] = useState<
    { value: string; label: React.ReactNode }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false); // Loading indicatorconst [mayIncludeKeywords, setMayIncludeKeywords] = useState<string[]>([]);
  const [createReportLoading, setCreateReportLoading] =
    useState<boolean>(false);
  const [previewCountLoading, setPreviewCountLoading] =
    useState<boolean>(false);
  const [mustIncludeKeywords, setMustIncludeKeywords] = useState<string[]>([]);
  const [shouldNotIncludeKeywords, setShouldNotIncludeKeywords] = useState<
    string[]
  >([]);
  const [mayIncludeKeywords, setMayIncludeKeywords] = useState<string[]>([]);
  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const [countryList, setCountryList] = useState<Option[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string[]>(
    countryList.map((option) => option.value).filter((value) => value !== "all")
  );

  const toggleOption = (value: string) => {
    let newSelection: string[];

    if (value === "all") {
      if (selectedCountry.length === countryList.length - 1) {
        // Deselect all
        newSelection = [];
      } else {
        // Select all except "All"
        newSelection = countryList
          .map((option) => option.value)
          .filter((v) => v !== "all");
      }
    } else {
      // Handle individual selection/deselection
      newSelection = selectedCountry.includes(value)
        ? selectedCountry.filter((v) => v !== value) // Remove selected country
        : [...selectedCountry.filter((v) => v !== "all"), value]; // Remove "all" if another option is selected
    }

    // Update state
    setSelectedCountry(newSelection);

    // Update filters with comma-separated string
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      countries: newSelection.length > 0 ? newSelection.join(",") : "",
    }));
  };

  const handleAddKeyword = (
    keyword: string,
    type: "mayKeywords" | "mustKeywords" | "notKeywords"
  ) => {
    if (!keyword.trim()) return;
    if (type === "mayKeywords" && !mayIncludeKeywords.includes(keyword)) {
      setMayIncludeKeywords([...mayIncludeKeywords, keyword.trim()]);
    } else if (
      type === "mustKeywords" &&
      !mustIncludeKeywords.includes(keyword)
    ) {
      setMustIncludeKeywords([...mustIncludeKeywords, keyword.trim()]);
    } else if (
      type === "notKeywords" &&
      !shouldNotIncludeKeywords.includes(keyword)
    ) {
      setShouldNotIncludeKeywords([
        ...shouldNotIncludeKeywords,
        keyword.trim(),
      ]);
    }

    setFilters((prev: any) => {
      let updatedKeywords: any;
      switch (type) {
        case "mayKeywords":
          updatedKeywords = mayIncludeKeywords;
          break;
        case "mustKeywords":
          updatedKeywords = mustIncludeKeywords;
          break;
        case "notKeywords":
          updatedKeywords = shouldNotIncludeKeywords;
          break;

        default:
          updatedKeywords = [];
      }

      return {
        ...prev,
        [type]: [...updatedKeywords, keyword.trim()].join(","),
      };
    });
  };

  const handleRemoveKeyword = (
    keyword: string,
    type: "mayKeywords" | "mustKeywords" | "notKeywords"
  ) => {
    let updatedKeywords: any;
    if (type === "mayKeywords") {
      updatedKeywords = mayIncludeKeywords.filter((kw) => kw !== keyword);
      setMayIncludeKeywords(updatedKeywords);
    } else if (type === "mustKeywords") {
      updatedKeywords = mustIncludeKeywords.filter((kw) => kw !== keyword);
      setMustIncludeKeywords(updatedKeywords);
    } else if (type === "notKeywords") {
      updatedKeywords = shouldNotIncludeKeywords.filter((kw) => kw !== keyword);
      setShouldNotIncludeKeywords(updatedKeywords);
    }

    setFilters((prev: any) => {
      let updatedKeywords: any;

      switch (type) {
        case "mayKeywords":
          updatedKeywords = mayIncludeKeywords.filter((kw) => kw !== keyword);
          setMayIncludeKeywords(updatedKeywords);
          break;
        case "mustKeywords":
          updatedKeywords = mustIncludeKeywords.filter((kw) => kw !== keyword);
          setMustIncludeKeywords(updatedKeywords);
          break;
        case "notKeywords":
          updatedKeywords = shouldNotIncludeKeywords.filter(
            (kw) => kw !== keyword
          );
          setShouldNotIncludeKeywords(updatedKeywords);
          break;

        default:
          updatedKeywords = [];
      }

      return {
        ...prev,
        [`deepSearch${capitalize(type)}`]: updatedKeywords.join(","),
      };
    });
  };

  const handleAddBrandKeyword = (
    keyword: { id: number; name: string },
    type: "yesBrands" | "notBrands"
  ) => {
    if (!keyword.name.trim()) return;

    setFilters((prev: any) => {
      if (type === "yesBrands") {
        // Ensure yesBrands is an array of objects and avoid duplicates
        const updatedYesBrands = prev.yesBrands
          ? [...prev.yesBrands, keyword].filter(
              (v, i, arr) => arr.findIndex((b) => b.id === v.id) === i
            )
          : [keyword];

        return { ...prev, yesBrands: updatedYesBrands };
      } else if (type === "notBrands") {
        const updatedNotBrands = prev.notBrands
          ? [...prev.notBrands, keyword].filter(
              (v, i, arr) => arr.findIndex((b) => b.id === v.id) === i
            )
          : [keyword];

        return { ...prev, notBrands: updatedNotBrands };
      }
      return prev;
    });
  };

  const handleRemoveBrandKeyword = (
    keywordId: number,
    type: "yesBrands" | "notBrands"
  ) => {
    setFilters((prev: any) => {
      if (type === "yesBrands") {
        // Remove brand by ID
        const updatedYesBrands = prev.yesBrands?.filter(
          (brand: { id: number }) => brand.id !== keywordId
        );

        return { ...prev, yesBrands: updatedYesBrands };
      } else if (type === "notBrands") {
        const updatedNotBrands = prev.notBrands?.filter(
          (brand: { id: number }) => brand.id !== keywordId
        );

        return { ...prev, notBrands: updatedNotBrands };
      }
      return prev;
    });
  };

  const fetchBrands = debounce(async (searchKey: string) => {
    if (!searchKey.trim()) {
      setBrandOptions([]);
      return;
    }

    setLoading(true);

    try {
      const result = await get<SearchBrandData[]>(
        `/gospel/brand/search?search_term=${searchKey}`
      );
      if (result && result.length > 0) {
        // Deduplicate channels based on `channelId`
        const uniqueBrands = Array.from(
          new Map(result.map((brand) => [brand.id, brand])).values()
        );
        // Map to AutoComplete options with unique keys
        const fetchedOptions = uniqueBrands.map((brand) => ({
          value: brand.name, // Value for AutoComplete
          label: (
            <div className="flex flex-row items-center gap-2 my-2">
              <img
                src={brand.image}
                alt={brand.name}
                className="w-12 h-12 rounded-full"
              />

              <span className="font-montserrat text-gospel-gray-300 font-semibold">
                {brand.name}
              </span>
            </div>
          ),
          key: brand.id, // Ensure the key is unique
        }));

        setBrandOptions(fetchedOptions);
      } else {
        setBrandOptions([]);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
      setBrandOptions([]);
    } finally {
      setLoading(false);
    }
  }, 300);

  const handleSocialChange = (value: string | string[]) => {
    setSocial(Array.isArray(value) ? value.join(",") : value); // Convert array to a comma-separated string
  };

  const toggleSocialOption = (value: string) => {
    const allSocialValues = socialOptions
      .map((option) => option.value)
      .filter((v) => v !== "all"); // Exclude "all"
    const current = social ? social.split(",") : []; // Current selections as an array

    if (value === "all") {
      if (current.length === allSocialValues.length) {
        // Deselect all if all options are selected
        setSocial("");
      } else {
        // Select all if "All" is clicked
        setSocial(allSocialValues.join(","));
      }
    } else {
      let newSelection: string[];
      if (current.includes(value)) {
        // Remove the value if already selected
        newSelection = current.filter((v) => v !== value);
      } else {
        // Add the value if not already selected
        newSelection = [...current, value];
      }

      if (newSelection.length === allSocialValues.length) {
        setSocial(allSocialValues.join(","));
      } else {
        // Remove "all" if not all options are selected
        newSelection = newSelection.filter((v) => v !== "all");
        setSocial(newSelection.join(","));
      }
    }
  };

  const handleFilterChange = (
    key: string,
    value: string,
    subKey?: "min" | "max" | "timeframe"
  ) => {
    setFilters((prevFilters: any) => {
      if (subKey) {
        // For min-max fields
        return {
          ...prevFilters,
          [key]: {
            ...prevFilters[key],
            [subKey]: value === "" ? value : value.replace(/,/g, ""),
          },
        };
      }
      // For non-object fields
      return { ...prevFilters, [key]: value };
    });
  };

  const handleAddCriteria = (value: string) => {
    setReportModal(false);

    const selectedCriteria = criterias.find((option) => option.value === value);
    if (
      selectedCriteria &&
      !selectedFields.some((item) => item.value === value)
    ) {
      setSelectedFields((prev) => [
        ...prev,
        { value, label: selectedCriteria.label },
      ]);
    }
    // Remove the selected criteria from the available options
    setCriteriaOptions((prevOptions) =>
      prevOptions.filter((option) => option.value !== value)
    );
    setCriteria("");
  };

  const handleRemoveCriteria = (field: string) => {
    // Remove the field from the selectedFields array
    setSelectedFields((prev) => prev.filter((item) => item.value !== field));

    const updatedOptions = criterias.filter(
      (option) =>
        !selectedFields.some((selected) => selected.value === option.value) ||
        option.value === field
    );

    setCriteriaOptions(updatedOptions);

    // Remove the corresponding key from the filters
    setFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[field]; // Remove the filter key
      return updatedFilters;
    });
  };

  const getPreviewResultCount = async (filters: any, rows: number) => {
    try {
      // Construct JSON payload from filters
      setPreviewCountLoading(true);
      const formattedJson = removeEmptyValues({
        ...filters,
        yesBrands: filters.yesBrands.length > 0 ? filters.yesBrands : [],
        notBrands: filters.notBrands.length > 0 ? filters.notBrands : [],
      });
      const jsonPayload = JSON.stringify(formattedJson);
      const data = new URLSearchParams();
      data.append("rows", rows.toString());
      data.append("json", jsonPayload);
      const response: any = await post("/gospel/report/count", data);
      if (response) {
        setPreviewResultCount(response.count);
      }
    } catch (error) {
      console.error("Error fetching preview count:", error);
      setPreviewCountLoading(false);
    } finally {
      setPreviewCountLoading(false);
    }
  };

  const createReport = async () => {
    try {
      // Construct JSON payload from filters
      setCreateReportLoading(true);
      const formattedJson = removeEmptyValues({
        ...filters,
        yesBrands: filters.yesBrands.length > 0 ? filters.yesBrands : [],
        notBrands: filters.notBrands.length > 0 ? filters.notBrands : [],
      });
      const jsonPayload = JSON.stringify(formattedJson);
      const data = new URLSearchParams();
      data.append("rows", reportRow.toString());
      data.append("json", jsonPayload);
      data.append("name", reportName);

      const response: any = await post("/gospel/report/create", data);
      if (response) {
        setCreateReportSucess(true);
        setTimeout(() => {
          setShowCreateReport(false);
        }, 4000);
      }
    } catch (error) {
      console.error("Error fetching preview count:", error);
      setCreateReportLoading(false);
    } finally {
      setCreateReportLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch country and language data simultaneously
        const [countryResult, languageResult] = await Promise.all([
          get<Country[]>("gospel/countries/list"),
          get<Language[]>("gospel/languages/list"),
        ]);

        // Transform the country data into the desired format and add "All" option
        const transformedCountryOptions = [
          { value: "all", label: "All" },
          ...countryResult.map((country) => ({
            value: country.code,
            label: country.name,
          })),
        ];

        // Transform the language data into the desired format and add "All" option
        const transformedLanguageOptions = [
          { value: "all", label: "All" },
          ...languageResult.map((language) => ({
            value: language.code,
            label: language.name,
          })),
        ];

        // Update the state with the transformed data
        setCountryList(transformedCountryOptions);
        setLanguageList(transformedLanguageOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (editReportCriteria) {
      // Convert keywords to arrays if they exist in editReportCriteria
      const mayKeywordsArray = editReportCriteria.mayKeywords
        ? editReportCriteria.mayKeywords
            .split(",")
            .map((keyword: string) => keyword.trim())
        : [];

      const mustKeywordsArray = editReportCriteria.mustKeywords
        ? editReportCriteria.mustKeywords
            .split(",")
            .map((keyword: string) => keyword.trim())
        : [];

      const notKeywordsArray = editReportCriteria.notKeywords
        ? editReportCriteria.notKeywords
            .split(",")
            .map((keyword: string) => keyword.trim())
        : [];

      // Update state
      setMayIncludeKeywords(mayKeywordsArray);
      setMustIncludeKeywords(mustKeywordsArray);
      setShouldNotIncludeKeywords(notKeywordsArray);
    }
  }, []);

  useEffect(() => {
    if (editReportCriteria) {
      // Loop through the keys in editReportCriteria
      const selectedFieldsFromCriteria: any = [];
      const updatedFilters: any = { ...defaultFilters };

      Object.keys(editReportCriteria).forEach((key) => {
        const matchingCriteria = criterias.find(
          (criterion) => criterion.value === key
        );
        if (matchingCriteria) {
          // Add to selectedFields if matching criterion is found
          selectedFieldsFromCriteria.push({
            value: matchingCriteria.value,
            label: matchingCriteria.label,
          });

          // Update the filters with the value from editReportCriteria
          updatedFilters[key] = editReportCriteria[key];
        } else {
          // Handle milestone values separately
          if (
            key === "channelMilestoneType" ||
            key === "channelViewsMilestone"
          ) {
            updatedFilters[key] = editReportCriteria[key];
          } else if (
            key === "videoMilestoneType" ||
            key === "videoViewsMilestone"
          ) {
            updatedFilters[key] = editReportCriteria[key];
          }
        }
      });

      // Check if 'yesbrand' or 'notBrand' exists in editReportCriteria
      if (editReportCriteria?.yesBrands || editReportCriteria?.notBrands) {
        const brandCriteria = criterias.find(
          (criterion) => criterion.value === "brands"
        );

        if (brandCriteria) {
          selectedFieldsFromCriteria.push({
            value: brandCriteria.value,
            label: brandCriteria.label,
          });
        }

        // Ensure yesBrands is an array of objects
        updatedFilters["yesBrands"] = Array.isArray(
          editReportCriteria.yesBrands
        )
          ? editReportCriteria.yesBrands.map((brand: any) => ({
              id: brand.id,
              name: brand.name,
            }))
          : [];

        // Ensure notBrand is a single object or reset if null/undefined
        updatedFilters["notBrands"] = Array.isArray(
          editReportCriteria.notBrands
        )
          ? editReportCriteria.notBrands.map((brand: any) => ({
              id: brand.id,
              name: brand.name,
            }))
          : [];
      }

      if (editReportCriteria?.countries) {
        const parsedCountries = editReportCriteria.countries
          .split(",")
          .map((c: string) => c.trim());
        setSelectedCountry(parsedCountries);
      }

      // Update state
      setSelectedFields(selectedFieldsFromCriteria);
      setFilters(updatedFilters);
    }
  }, []);

  const renderField = (fieldValue: string, fieldLabel: string) => {
    switch (fieldValue) {
      case "lifetimeViews":
      case "lifetimeSubscribers":
      case "v30Day":
      case "vpv30":
      case "vpv90":
      case "shvpv30":
      case "lgvpv30":
      case "shvpv90":
      case "lgvpv90":
      case "minv30":
      case "minv90":
      case "shortsMinv90":
      case "longsMinv90":
      case "cldpv30":
      case "cldpmv30":
        return (
          <div className="my-4 flex flex-col">
            <GospelLabel
              label={`${fieldLabel}:`}
              className="text-xs text-gray-400 mb-1 block pt-1"
            />
            <div className="flex flex-row items-center gap-3">
              <div className="flex flex-col gap-1">
                <GospelInput
                  type="text"
                  value={formatNumberWithCommas(filters[fieldValue]?.min)}
                  onChange={(e) =>
                    handleFilterChange(fieldValue, e.target.value, "min")
                  }
                  placeholder="Min"
                  className="h-10 w-[203px] placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <GospelLabel
                  label={"Min"}
                  className="text-xs text-gray-400 mb-1"
                />
              </div>
              <span className="text-gray-400 self-start mt-2">-</span>
              <div className="flex flex-col gap-1">
                <GospelInput
                  type="text"
                  value={formatNumberWithCommas(filters[fieldValue]?.max)}
                  onChange={(e) =>
                    handleFilterChange(fieldValue, e.target.value, "max")
                  }
                  placeholder="Max"
                  className="h-10 w-[203px] placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <GospelLabel
                  label={"Max (leave blank for no max)"}
                  className="text-xs text-gray-400 mb-1"
                />
              </div>
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5 mb-6"
                onClick={() => handleRemoveCriteria(fieldValue)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );

      case "viewsPercentageChange":
      case "subscribersPercentageChange":
      case "viewsOverTime":
      case "subscribersOverTime":
      case "longsAvgDur":
        return (
          <div className="my-4 flex flex-col">
            <GospelLabel
              label={
                fieldValue === "longsAvgDur"
                  ? "Longs Average Duration (in seconds):"
                  : `${fieldLabel}:`
              }
              className="text-xs text-gray-400 mb-1 block pt-1"
            />
            {fieldValue === "longsAvgDur" && (
              <GospelLabel
                label={"For example, 25 minutes equals 1,500 seconds."}
                className="text-xs text-gray-400 mb-1 block py-1"
              />
            )}
            <div className="flex flex-row items-center gap-3">
              <div className="flex flex-col gap-1">
                <GospelInput
                  type="text"
                  value={formatNumberWithCommas(filters[fieldValue]?.min)}
                  onChange={(e) =>
                    handleFilterChange(fieldValue, e.target.value, "min")
                  }
                  placeholder="Min"
                  className="h-10 w-[203px] placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <GospelLabel
                  label={"Min"}
                  className="text-xs text-gray-400 mb-1"
                />
              </div>
              <span className="text-gray-400 self-start mt-2">-</span>
              <div className="flex flex-col gap-1">
                <GospelInput
                  type="text"
                  value={formatNumberWithCommas(filters[fieldValue]?.max)}
                  onChange={(e) =>
                    handleFilterChange(fieldValue, e.target.value, "max")
                  }
                  placeholder="Max"
                  className="h-10 w-[203px] placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <GospelLabel
                  label={"Max (leave blank for no max)"}
                  className="text-xs text-gray-400 mb-1"
                />
              </div>
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5 mb-6"
                onClick={() => handleRemoveCriteria(fieldValue)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
            <div className=" mt-2">
              <GospelLabel
                label={`${fieldLabel} Timeframe:`}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <GospelSelectBox
                options={
                  fieldValue === "longsAvgDur"
                    ? uploadsTimeFrameOptions
                    : timeChangeFrameOptions
                }
                value={filters[fieldValue]?.timeframe}
                onChange={(value) =>
                  handleFilterChange(fieldValue, value, "timeframe")
                }
                containerClass="relative w-1/2"
                className="h-10 w-full text-sm font-medium bg-white"
                placeholder={`Select`}
              />
            </div>
          </div>
        );

      case "uploads":
      case "shortsUploads":
      case "longsUploads":
        return (
          <div className="my-4 flex flex-col">
            <GospelLabel
              label={`${fieldLabel}:`}
              className="text-xs text-gray-400 mb-1 block pt-1"
            />
            <div className="flex flex-row items-center gap-3">
              <div className="flex flex-col gap-1">
                <GospelInput
                  type="text"
                  value={formatNumberWithCommas(filters[fieldValue]?.min)}
                  onChange={(e) =>
                    handleFilterChange(fieldValue, e.target.value, "min")
                  }
                  placeholder="Min"
                  className="h-10 w-[203px] placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <GospelLabel
                  label={"Min"}
                  className="text-xs text-gray-400 mb-1"
                />
              </div>
              <span className="text-gray-400 self-start mt-2">-</span>
              <div className="flex flex-col gap-1">
                <GospelInput
                  type="text"
                  value={formatNumberWithCommas(filters[fieldValue]?.max)}
                  onChange={(e) =>
                    handleFilterChange(fieldValue, e.target.value, "max")
                  }
                  placeholder="Max"
                  className="h-10 w-[203px] placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <GospelLabel
                  label={"Max (leave blank for no max)"}
                  className="text-xs text-gray-400 mb-1"
                />
              </div>
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5 mb-6"
                onClick={() => handleRemoveCriteria(fieldValue)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
            <div className=" mt-2">
              <GospelLabel
                label={`${
                  fieldValue === "uploads"
                    ? "Uploads"
                    : fieldValue === "shortsUploads"
                      ? "Shorts Uploads"
                      : "Longs Uploads"
                } Timeframe:`}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <GospelSelectBox
                options={uploadsTimeFrameOptions}
                value={filters[fieldValue]?.timeframe}
                onChange={(value) =>
                  handleFilterChange(fieldValue, value, "timeframe")
                }
                containerClass="relative w-1/2"
                className="h-10 w-full text-sm font-medium bg-white"
                placeholder={`Select`}
              />
            </div>
          </div>
        );

      case "language":
        return (
          <div className="my-4">
            <GospelLabel
              label={`${fieldLabel}:`}
              className="text-xs text-gray-400 mb-1 block pt-1"
            />
            <div className="flex flex-row items-center gap-3">
              <GospelSelectBox
                options={languageList}
                value={filters[fieldValue]}
                onChange={(value) => handleFilterChange(fieldValue, value)}
                containerClass="relative w-1/2"
                className="h-10 w-full text-sm font-medium bg-white"
                placeholder={`Select ${fieldLabel}`}
              />
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5"
                onClick={() => handleRemoveCriteria(fieldValue)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );

      case "countries":
        return (
          <div className="my-4">
            <GospelLabel
              label={`${fieldLabel}:`}
              className="text-xs text-gray-400 mb-1 block pt-1"
            />
            <div className="flex flex-row items-center gap-3">
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      colorTextPlaceholder: "#353b3d",
                      colorTextQuaternary: "#353b3d",
                    },
                  },
                  token: {
                    fontFamily: "Montserrat, sans-serif",
                    colorPrimary: "#9D63B0",
                  },
                }}
              >
                <Select
                  mode="multiple"
                  style={{ width: "50%", height: 40 }}
                  placeholder="Select Countries"
                  maxTagCount={1}
                  showSearch
                  value={selectedCountry}
                  optionLabelProp="label"
                  onChange={(value) => setSelectedCountry(value as string[])}
                  filterOption={(input, option) =>
                    String(option?.label)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {countryList.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}
                      label={option.label}
                    >
                      <Space style={{ padding: "8px" }}>
                        <Checkbox
                          checked={
                            option.value === "all"
                              ? selectedCountry.length ===
                                countryList.length - 1
                              : selectedCountry.includes(option.value)
                          }
                          onChange={() => toggleOption(option.value)}
                        />
                        {option.label}
                      </Space>
                    </Select.Option>
                  ))}
                </Select>
              </ConfigProvider>
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5"
                onClick={() => handleRemoveCriteria(fieldValue)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );

      case "adminCategories":
        return (
          <div className="my-4">
            <GospelLabel
              label={"Admin Categories:"}
              className="text-xs text-gray-400 mb-1 block pt-1"
            />
            <div className="flex flex-row items-center gap-3">
              <GospelSelectBox
                options={adminOptions}
                value={filters.adminCategories}
                onChange={(value) =>
                  handleFilterChange("adminCategories", value)
                }
                containerClass="relative w-1/2"
                className="h-10 w-full text-sm font-medium bg-white"
                placeholder="Select Admin Categories:"
              />
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5"
                onClick={() => handleRemoveCriteria(fieldValue)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );
      case "categories":
        return (
          <div className="my-4">
            <GospelLabel
              label={"Categories:"}
              className="text-xs text-gray-400 mb-1 block pt-1"
            />
            <div className="flex flex-row items-center gap-3">
              <GospelSelectBox
                options={categoryOptions}
                value={filters.categories}
                onChange={(value) => handleFilterChange("categories", value)}
                containerClass="relative w-1/2"
                className="h-10 w-full text-sm font-medium bg-white"
                placeholder="Select Categories:"
              />
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5"
                onClick={() => handleRemoveCriteria(fieldValue)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );
      case "madeForKids":
      case "shorts":
        return (
          <div className="my-4">
            <GospelLabel
              label={`${fieldLabel}:`}
              className="text-xs text-gray-400 mb-1 block pt-1"
            />
            <div className="flex flex-row items-center gap-3">
              <GospelSelectBox
                options={shortOptions}
                value={filters[fieldValue]}
                onChange={(value) => handleFilterChange(fieldValue, value)}
                containerClass="relative w-1/2"
                className="h-10 w-full text-sm font-medium bg-white"
                placeholder={`Select ${fieldLabel}`}
              />
              <button
                className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5"
                onClick={() => handleRemoveCriteria(fieldValue)}
              >
                <Close className="text-white block" fontSize="small" />
              </button>
            </div>
          </div>
        );

      case "brands":
        return (
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorTextPlaceholder: "#353b3d",
                  colorTextQuaternary: "#353b3d",
                },
              },
              token: {
                fontFamily: "Montserrat, sans-serif",
                colorPrimary: "#9D63B0",
              },
            }}
          >
            <div className="my-4">
              <GospelLabel
                label={`Include these brands:`}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <div className="flex flex-row items-center gap-3">
                <AutoComplete
                  style={{ width: "50%", fontFamily: "Montserrat, sans-serif" }}
                  className="custom-autocomplete block w-1/2 h-10 placeholder:text-gospel-gray-300 text-sm text-gospel-gray-300 font-medium bg-white  rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                  options={
                    loading
                      ? [
                          {
                            value: "loading",
                            label: (
                              <div className="flex items-center justify-center py-2">
                                <CircularProgress
                                  className="text-gospel-gray-300 font-bold z-10"
                                  size={20}
                                />
                              </div>
                            ),
                          },
                        ]
                      : brandOptions
                  }
                  placeholder="Enter Brand Name"
                  value={filters.yesBrandsTemp}
                  onSearch={(value) => {
                    fetchBrands(value);
                    setFilters((prev: any) => ({
                      ...prev,
                      yesBrandsTemp: value,
                    }));
                  }}
                  onSelect={(value, option: any) => {
                    handleAddBrandKeyword(
                      { id: option.key, name: option.value },
                      "yesBrands"
                    );
                    setFilters((prev: any) => ({ ...prev, yesBrandsTemp: "" }));
                    setBrandOptions([]);
                  }}
                  notFoundContent={"No Results Found."}
                  filterOption={false} // Disable default filtering
                />

                <button
                  className="bg-gospel-gray-200 hover:bg-gospel-gray-300 rounded-full p-0.5"
                  onClick={() => handleRemoveCriteria(fieldValue)}
                >
                  <Close className="text-white block" fontSize="small" />
                </button>
              </div>
              <GospelLabel
                label={
                  "Start typing a brand and you will be able to select from a list"
                }
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <div className="flex flex-wrap gap-2">
                {filters.yesBrands?.map(
                  (brand: { id: number; name: string }) => (
                    <div
                      key={brand.id}
                      className="flex items-center bg-gospel-purple text-gray-700 text-sm px-3 py-2 rounded mt-2"
                    >
                      <button
                        onClick={() =>
                          handleRemoveBrandKeyword(brand.id, "yesBrands")
                        }
                        className=" text-white focus:outline-none"
                      >
                        <Close fontSize="small"></Close>
                      </button>
                      <span className="text-white focus:outline-none ml-1">
                        {brand.name}
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="my-4">
              <GospelLabel
                label={`Exclude these brands:`}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <div className="flex flex-row items-center gap-3">
                <AutoComplete
                  style={{ width: "50%", fontFamily: "Montserrat, sans-serif" }}
                  className="custom-autocomplete block w-1/2 h-10 placeholder:text-gospel-gray-300 text-sm text-gospel-gray-300 font-medium bg-white  rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                  options={
                    loading
                      ? [
                          {
                            value: "loading",
                            label: (
                              <div className="flex items-center justify-center py-2">
                                <CircularProgress
                                  className="text-gospel-gray-300 font-bold z-10"
                                  size={20}
                                />
                              </div>
                            ),
                          },
                        ]
                      : brandOptions
                  }
                  placeholder="Enter Brand Name"
                  notFoundContent={"No Results Found."}
                  value={filters.notBrandTemp}
                  onSearch={(value) => {
                    fetchBrands(value);
                    setFilters((prev: any) => ({
                      ...prev,
                      notBrandTemp: value,
                    }));
                  }}
                  onSelect={(value, option: any) => {
                    handleAddBrandKeyword(
                      { id: option.key, name: option.value },
                      "notBrands"
                    );
                    setFilters((prev: any) => ({ ...prev, notBrandTemp: "" }));
                    setBrandOptions([]);
                  }}
                  filterOption={false}
                />
              </div>
              <GospelLabel
                label={
                  "Start typing a brand and you will be able to select from a list"
                }
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <div className="flex flex-wrap gap-2">
                {filters.notBrands?.map(
                  (brand: { id: number; name: string }) => (
                    <div
                      className="flex items-center bg-gospel-purple text-gray-700 text-sm px-3 py-2 rounded mt-2"
                      key={brand.id}
                    >
                      <button
                        onClick={() =>
                          handleRemoveBrandKeyword(brand.id, "notBrands")
                        }
                        className=" text-white focus:outline-none"
                      >
                        <Close fontSize="small"></Close>
                      </button>
                      <span className="text-white focus:outline-none ml-1">
                        {brand.name}
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          </ConfigProvider>
        );

      default:
        return null;
    }
  };

  return (
    <div className="p-5">
      <div className="border-b border-gray-200 pb-6 my-4">
        <button
          className="text-purple-600 flex items-center gap-1"
          onClick={() => setShowCreateReport(false)}
        >
          <ArrowBack htmlColor="#9d63b0"></ArrowBack>
          <span className="text-gospel-purple text-sm hover:text-black">
            Back to Reports
          </span>
        </button>
      </div>

      <div>
        <div className="relative w-full my-4">
          <GospelLabel
            label={"Report type"}
            className="text-xs text-gray-400 mb-1 block pt-1"
          ></GospelLabel>
          <GospelSelectBox
            options={reportOptions}
            value={selectedReport}
            onChange={(value: string) => {
              setFilters((prev: any) => ({
                ...prev,
                reportType: value,
              }));
              setSelectedReport(value);
              setEditReportData(null);
            }}
            containerClass="relative w-1/2"
            className="w-full max-w-[450px] h-10"
            placeholder="Select"
          />
        </div>
        <div className="relative w-full my-4">
          <GospelLabel
            label={"Report name"}
            className="text-xs text-gray-400 mb-1 block pt-1"
          ></GospelLabel>

          <GospelInput
            type="text"
            onChange={(e) => {
              setReportName(e.target.value);
            }}
            value={reportName}
            placeholder="Enter Report Name"
            className="block w-1/2 max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
          />
        </div>

        {/* Fields Based on Report Type */}
        {selectedReport === "video" && (
          <div className="my-6 flex flex-col gap-4">
            <p className="my-1 text-sm font-normal ">Search keywords:</p>
            <div className="relative w-full">
              <GospelLabel
                label={"May include"}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <GospelInput
                type="text"
                value={filters["mayKeywordsTemp"]}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    mayKeywords: e.target.value,
                    mayKeywordsTemp: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddKeyword(filters["mayKeywords"], "mayKeywords");
                    setFilters((prev: any) => ({
                      ...prev,
                      mayKeywordsTemp: "",
                    }));
                  }
                }}
                placeholder="Enter Keywords"
                className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
              />
              <GospelLabel
                label={"Separate each word/phrase with a comma or press enter"}
                className="text-xs text-gray-400 mb-1"
              />
              <div className="flex flex-wrap gap-2">
                {mayIncludeKeywords.map((keyword, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-gospel-purple text-gray-700 text-sm px-3 py-2 rounded mt-2"
                  >
                    <button
                      onClick={() => {
                        handleRemoveKeyword(keyword, "mayKeywords");
                        setFilters((prev: any) => ({
                          ...prev,
                          mayKeywords: "",
                        })); // Clear inpu
                      }}
                      className=" text-white focus:outline-none"
                    >
                      <Close fontSize="small"></Close>
                    </button>
                    <span className="text-white focus:outline-none ml-1">
                      {keyword}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative w-full">
              <GospelLabel
                label={"Must include"}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <GospelInput
                type="text"
                value={filters["mustKeywordsTemp"]}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    mustKeywords: e.target.value,
                    mustKeywordsTemp: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddKeyword(filters["mustKeywords"], "mustKeywords");
                    setFilters((prev: any) => ({
                      ...prev,
                      mustKeywordsTemp: "",
                    }));
                  }
                }}
                placeholder="Enter Keywords"
                className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
              />
              <GospelLabel
                label={"Separate each word/phrase with a comma or press enter"}
                className="text-xs text-gray-400 mb-1"
              />
              <div className="flex flex-wrap gap-2">
                {mustIncludeKeywords.map((keyword, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-gospel-purple text-gray-700 text-sm px-3 py-2 rounded mt-2"
                  >
                    <button
                      onClick={() => {
                        handleRemoveKeyword(keyword, "mustKeywords");
                        setFilters((prev: any) => ({
                          ...prev,
                          mustKeywords: "",
                        })); // Clear input
                      }}
                      className=" text-white focus:outline-none"
                    >
                      <Close fontSize="small"></Close>
                    </button>
                    <span className="text-white focus:outline-none ml-1">
                      {keyword}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative w-full">
              <GospelLabel
                label={"Should not include"}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <GospelInput
                type="text"
                value={filters["notKeywordsTemp"]}
                onChange={(e) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    notKeywords: e.target.value,
                    notKeywordsTemp: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddKeyword(filters["notKeywords"], "notKeywords");
                    setFilters((prev: any) => ({
                      ...prev,
                      notKeywordsTemp: "",
                    }));
                  }
                }}
                placeholder="Enter Keywords"
                className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
              />
              <GospelLabel
                label={"Separate each word/phrase with a comma or press enter"}
                className="text-xs text-gray-400 mb-1"
              />
              <div className="flex flex-wrap gap-2">
                {shouldNotIncludeKeywords.map((keyword, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-gospel-purple text-gray-700 text-sm px-3 py-2 rounded mt-2"
                  >
                    <button
                      onClick={() => {
                        handleRemoveKeyword(keyword, "notKeywords");
                        setFilters((prev: any) => ({
                          ...prev,
                          notKeywords: "",
                        })); // Clear input
                      }}
                      className=" text-white focus:outline-none"
                    >
                      <Close fontSize="small"></Close>
                    </button>
                    <span className="text-white focus:outline-none ml-1">
                      {keyword}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative w-full">
              <GospelLabel
                label={"Days ago"}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <GospelInput
                type="number"
                value={editReportCriteria?.daysAgo || filters["daysAgo"]}
                onChange={(e) => handleFilterChange("daysAgo", e.target.value)}
                placeholder=""
                className="block w-36 max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
              />
            </div>
          </div>
        )}

        {selectedReport === "channelMilestone" && (
          <div className="my-6 flex flex-col gap-4">
            <div className="relative w-full">
              <GospelLabel
                label={"Type of milestone"}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <GospelSelectBox
                options={[
                  { value: "views", label: "Views" },
                  { value: "subscribers", label: "Subscribers" },
                ]}
                value={filters["channelMilestoneType"]}
                onChange={(value) =>
                  handleFilterChange("channelMilestoneType", value)
                }
                containerClass="w-full max-w-[450px]"
                className="w-full h-10"
                placeholder="Select Milestone Type"
              />
            </div>
            <div className="relative w-full">
              <GospelLabel
                label={"Views milestone amount"}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <GospelInput
                type="text"
                value={formatNumberWithCommas(filters["channelViewsMilestone"])}
                onChange={(e) =>
                  handleFilterChange("channelViewsMilestone", e.target.value)
                }
                placeholder="Enter Amount"
                className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
              />
            </div>
          </div>
        )}

        {selectedReport === "videoMilestone" && (
          <div className="my-6 flex flex-col gap-4">
            <div className="relative w-full">
              <GospelLabel
                label={"Type of milestone"}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <GospelSelectBox
                options={[{ value: "views", label: "Views" }]}
                value={filters["videoMilestoneType"]}
                onChange={(value) =>
                  handleFilterChange("videoMilestoneType", value)
                }
                containerClass="w-full max-w-[450px]"
                className="w-full h-10"
                placeholder="Select Milestone Type"
              />
            </div>
            <div className="relative w-full">
              <GospelLabel
                label={"Views milestone amount"}
                className="text-xs text-gray-400 mb-1 block pt-1"
              />
              <GospelInput
                type="text"
                value={formatNumberWithCommas(filters["videoViewsMilestone"])}
                onChange={(e) =>
                  handleFilterChange("videoViewsMilestone", e.target.value)
                }
                placeholder="Enter Amount"
                className="block w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
              />
            </div>
          </div>
        )}

        <p className="my-4 text-sm font-normal ">
          Generate a report on channels with the following criteria:
        </p>

        {/* Dynamic field */}

        <div>
          {selectedFields.map((field) => (
            <div key={field.value}>{renderField(field.value, field.label)}</div>
          ))}
        </div>

        {criteriaOptions.length > 0 && (
          <span
            onClick={() => {
              setReportModal(true);
            }}
          >
            <GospelButton
              className="text-white rounded-full p-1 transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple"
              label={<Add fontSize={"medium"}></Add>}
              onClick={() => {}}
            />
            <span className="text-gospel-purple text-sm hover:text-black ml-2 cursor-pointer">
              Add Criteria
            </span>
          </span>
        )}
        <div className="my-4">
          <GospelLabel
            label={"How many rows do you want returned in this report?"}
            className="text-xs text-gray-400 mb-1 block pt-1"
          ></GospelLabel>

          <GospelInput
            type="number"
            onChange={(e) => {
              setReportRow(e.target.value);
            }}
            value={reportRow}
            placeholder=""
            className="block w-1/2 max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
          />
          <p className="text-xs text-gray-400">{`Your max per report is ${reportMaxRowsAllowed}`}</p>
        </div>

        {selectedReport === "video" && (
          <div className="my-4">
            <GospelLabel
              label={"Results:"}
              className="text-sm text-gray-400 mb-1 block pt-1"
            />
            <GospelSelectBox
              options={[
                { value: "channels", label: "Only Channel Data" },
                { value: "video", label: "Only Video Data" },
                { value: "both", label: "Both Channel and Video Data" },
              ]}
              value={editReportCriteria?.results ?? filters["results"]}
              onChange={(value) => handleFilterChange("results", value)}
              className="w-1/2 max-w-[450px] h-10"
              placeholder="Select"
            />
          </div>
        )}
        <div className="my-4">
          <GospelLabel
            label={"Socials:"}
            className="text-sm text-gray-400 mb-1 block pt-1"
          />
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorTextPlaceholder: "#353b3d",
                  colorTextQuaternary: "#353b3d",
                },
              },
              token: {
                fontFamily: "Montserrat, sans-serif",
                colorPrimary: "#9D63B0",
              },
            }}
          >
            <Select
              mode="multiple"
              className="w-1/2 max-w-[450px]"
              style={{ height: 40 }}
              placeholder="Select"
              maxTagCount={1}
              value={social ? social.split(",") : []}
              onChange={(value) => handleSocialChange(value)}
              options={socialOptions}
              optionLabelProp="label"
              dropdownRender={() => (
                <div className="flex flex-col h-52 overflow-y-scroll">
                  <Space key="all" style={{ padding: "8px" }}>
                    <Checkbox
                      checked={
                        social.split(",").length === socialOptions.length - 1
                      }
                      onChange={() => toggleSocialOption("all")}
                    >
                      All
                    </Checkbox>
                  </Space>
                  {socialOptions
                    .filter((option) => option.value !== "all")
                    .map((option) => (
                      <Space key={option.value} style={{ padding: "8px" }}>
                        <Checkbox
                          checked={social.split(",").includes(option.value)}
                          onChange={() => toggleSocialOption(option.value)}
                        >
                          {option.label}
                        </Checkbox>
                      </Space>
                    ))}
                </div>
              )}
            />
          </ConfigProvider>
        </div>
        {selectedReport === "video" && (
          <div className="my-4">
            <GospelLabel
              label={"Only include outliers in this report?"}
              className="text-sm text-gray-400 mb-1 block pt-1"
            />
            <GospelSelectBox
              options={[
                { value: "false", label: "No" },
                { value: "true", label: "Yes" },
              ]}
              value={editReportCriteria?.outliers ?? filters["outliers"]}
              onChange={(value) => handleFilterChange("outliers", value)}
              className="w-1/2 max-w-[450px] h-10"
              placeholder="Select"
            />
          </div>
        )}
        {previewResultCount && (
          <span className="text-base">{`Result Count: ${previewResultCount}`}</span>
        )}

        {createReportSucess && (
          <div className="flex text-gospel-green p-5 text-center w-full bg-[rgba(63,189,57,0.15)] my-5 mb-2 text-sm leading-[1.65]">{`Your Report is now Running. You'll get an email notification when it's finished. We're taking you back to your main Reports page now.`}</div>
        )}
        {selectedFields.length > 0 && (
          <div className="flex flex-row gap-2 my-4">
            <GospelButton
              className="text-white h-[40px] w-[120px] rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple "
              label={
                createReportLoading ? (
                  <CircularProgress
                    className="text-gospel-gray-300 font-bold"
                    size={14}
                  />
                ) : (
                  "Run Report"
                )
              }
              onClick={() => createReport()}
            />
            <GospelButton
              onClick={() =>
                getPreviewResultCount(filters, parseInt(reportRow, 10))
              }
              label={
                previewCountLoading ? (
                  <CircularProgress
                    className="text-gospel-gray-300 font-bold"
                    size={14}
                  />
                ) : (
                  "Preview Result Count"
                )
              }
              className="bg-white h-[40px] w-[180px]  rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
            />
          </div>
        )}
      </div>

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 22,
              titleColor: "#353b3d",
              fontFamily: "Montserrat, sans-serif",
            },
          },
        }}
      >
        <Modal
          title=""
          open={reportModal}
          footer={null}
          closeIcon={
            <Cancel
              htmlColor="#000"
              fontSize="medium"
              onClick={() => {
                setReportModal(false);
                setCriteria("");
              }}
            />
          }
          className="text-center"
        >
          <div className="flex flex-col items-center my-8">
            <p className=" text-gospel-gray-300 text-base">Select Criteria:</p>
            <GospelSelectBox
              options={criteriaOptions}
              value={criteria || "-------------"} // Ensure default placeholder
              onChange={handleAddCriteria}
              containerClass="relative w-full"
              className="w-full h-10 my-4"
              placeholder="-------------"
            />
            <GospelButton
              onClick={() => {
                setReportModal(false);
                setCriteria("");
              }}
              label={"Cancel"}
              className="bg-white w-24 py-2 mt-2  rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
            />
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  );
};

export default CreateReport;
